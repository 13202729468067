import React from 'react';
import styles from './CustomizedSingleRadioButton.module.css';

const CustomizedSingleRadioButton = ({ label, name, value, selectedValue, onChange }) => {
  return (
    <label className={styles.radioLabel}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={() => onChange(value)}
        className={styles.radioInput}
      />
      <span className={styles.radioCustom}></span>
      {label}
    </label>
  );
};
export default CustomizedSingleRadioButton;