import React, { useEffect, useState } from 'react'

import styles from "./DairyEventForms.module.css"
import CustomizedSingleRadioButton from '../UI/CustomizedSingleRadioButton';
import { ImCancelCircle } from "react-icons/im";

import CustomFileUpload from '../UI/CustomFileUpload/CustomFileUpload';

const AddWeightTrackerForm = ({formData, setFormData,errors,setErrors}) => {
    const [reminderNotification, setReminderNotification] = useState('');
    const [timeOfTheDay, setTimeOfTheDay] = useState('')
    const [fileName, setFileName] = useState(''); 
    const [reminderOptions, setReminderOptions] = useState({
        daily: "no",
        dayByDay: "no",
        weekly: "no",
        monthly: "no",
        yearly: "no",
        halfYearly: "no", 
    });
    
    const handleReminderChange = (option) => {
        console.log(option,"option")
        setReminderOptions((prevState) => {
        const updatedState = Object.keys(prevState).reduce((acc, key) => {
            acc[key] = key === option ? "yes" : "no";
            return acc;
        }, {});
        return updatedState;
        });
    };

  const handleReminderNotificationChange = (value) => {
    console.log('Reminder Notification selected:', value);
    setReminderNotification(value);
  };

  const handleTimeOfTheDayChange = (time) => {
      setTimeOfTheDay(time);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", 
      }));
    };
  
    useEffect(()=>{
        const selectedReminderOption = Object.keys(reminderOptions).find(
          (key) => reminderOptions[key] === "yes"
        );
        setFormData((prev)=>({...prev,"remindNotification":reminderNotification,"remindOption":selectedReminderOption,"timeofDay":timeOfTheDay,"choosenFile":fileName}))
      },[reminderNotification,reminderOptions,timeOfTheDay,fileName])
  
  

  
  return (
    <>
      <div className={styles.SelectFieldContainer}>
        <label>Enter Age of Your Pet</label>
        <input type="text" name="petAge" value={formData.petAge} onChange={handleInputChange} />
        {errors.petAge && <span className={styles.errorText}>{errors.petAge}</span>}
      </div>
      <div className={styles.SelectFieldContainer}>
        <label>Enter Weight of Your Pet</label>
        <input type="text" name="petWeight" value={formData.weight} onChange={handleInputChange} />
        {errors.petWeight && <span className={styles.errorText}>{errors.petWeight}</span>}
      </div>
      
      <div className={styles.SelectFieldContainer}>
        <label>Time of the Day</label>
        <div className={styles.TimeOfTheDayContainer}>
          {["Morning", "Afternoon", "Night"].map((time) => (
            <button
              key={time}
              onClick={(e) => {
                e.preventDefault();
                handleTimeOfTheDayChange(time);
              }}
              style={{
                border: timeOfTheDay === time ? "1px solid #1FBFC2" : "1px solid #D9D9D9BF",
              }}
            >
              {time}
            </button>
          ))}
        </div>
        {errors.timeofDay && <span className={styles.errorText}>{errors.timeofDay}</span>}
      </div>

          
      <div className={styles.SelectFieldContainer}>
        <label>Description</label>
        <textarea
          name="description"
          rows={4}
          onChange={handleInputChange}
        ></textarea>
        {errors.description && <span className={styles.errorText}>{errors.description}</span>}
      </div>
      <div className={styles.DateDurationMainContainer} style={{marginTop:"0px"}}>
        <div className={styles.DateContainer}>
          <label>Date</label>
          <input type="date" name="startDate" value={formData.startDate} onChange={handleInputChange}/>
          {errors.startDate && <span className={styles.errorText}>{errors.startDate}</span>}
        </div>
        <div className={styles.DatesContainer}>
          <label style={{fontWeight:"500"}}>Image</label>
          <CustomFileUpload fileName={fileName} setFileName={setFileName}/>
          {errors.choosenFile && <span className={styles.errorText}>{errors.choosenFile}</span>}
        </div>
      </div>
      <div className={styles.DateDurationMainContainer}>
        <div className={styles.DateContainer}>
          <label>Reminder Notification</label>
          <div className={styles.checkboxMainContainer}>
            {["yes", "no"].map((value) => (
              <div className={styles.checkboxContainer} key={value}>
                <CustomizedSingleRadioButton
                  label={value === "yes" ? "Yes" : "No"}
                  name="reminderNotification"
                  value={value}
                  selectedValue={reminderNotification}
                  onChange={handleReminderNotificationChange}
                />
              </div>
            ))}
            {errors.remindNotification && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindNotification}</span>}
          </div>
        </div>
      </div>

      {reminderNotification === "yes" && <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Date</label>
              <input type="date" name="toRemindDate" value={formData.toRemindDate} onChange={handleInputChange}/>
              {errors.toRemindDate && <span className={styles.errorText}>{errors.toRemindDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Duration</label>
              <input type="time" name="toRemindTime" value={formData.toRemindTime} onChange={handleInputChange}/>
              {errors.toRemindTime && <span className={styles.errorText}>{errors.toRemindTime}</span>}
            </div>
          </div>}


      <div className={styles.DateDurationMainContainer}>
        <div className={styles.MdReminderContainer}>
          <label>Remind Me Every</label>
          <div className={styles.checkboxMainContainer}>
            {[
              { label: "Daily", key: "daily" },
              { label: "Day By Day", key: "dayByDay" },
              { label: "Weekly", key: "weekly" },
              { label: "Monthly", key: "monthly" },
              { label: "Yearly", key: "yearly" },
              { label: "Half Yearly", key: "halfYearly" },
            ].map((option) => (
              <div className={styles.checkboxContainer} key={option.key}>
                <CustomizedSingleRadioButton
                  label={option.label}
                  name="reminderOptions"
                  value={option.key}
                  selectedValue={Object.keys(reminderOptions).find(
                    (key) => reminderOptions[key] === "yes"
                  )}
                  onChange={() => handleReminderChange(option.key)}
                />
              </div>
            ))}
            {errors.remindOption && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindOption}</span>}
          </div>
        </div>
      </div>
    </>
  )
}


export default AddWeightTrackerForm