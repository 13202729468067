import React, { useState, useEffect, useRef } from "react";
import "../../SocialMedia.css";
import { BACKEND_URL } from "../../../../Utilities/Constant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hoverSound from "./WhatsApp Audio 2024-12-14 at 17.14.53_a895e4fa.mp3";
import { WebApimanager } from "../../../../WebApiManager";

const HoverProfile = ({ user }) => {
  const [isHovered, setIsHovered] = useState(false);
  const audioRef = useRef(new Audio(hoverSound));
  let stopSoundTimeout = useRef(null);
  const webApi = new WebApimanager();
  const [userData, setUserData] = useState([]);

  const playHoverSound = () => {
    const audio = audioRef.current;
    audio.currentTime = 0;
    audio.play();
  };

  const stopHoverSound = () => {
    const audio = audioRef.current;
    stopSoundTimeout.current = setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 5000);
  };

  useEffect(() => {
    return () => {
      if (stopSoundTimeout.current) {
        clearTimeout(stopSoundTimeout.current);
      }
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, []);

  useEffect(() => {
    console.log("User object:", user);
    const fetchSuggestionData = async () => {
      try {
        const response = await webApi.get(`socialMedia/all`);
        const suggestions = response.data.data || [];
        console.log(suggestions, "Fetched user data");

        suggestions.forEach((item, index) => {
          console.log(`Suggestion ${index}:`, item);
        });

        console.log(user._id, "Prop User ID");

        const matchedUser = suggestions.find(
          (item) => item.userId?._id === user?._id
        );

        if (matchedUser) {
          console.log("Matched User:", matchedUser);
          setUserData(matchedUser);
        } else {
          console.log("No matched user found.");
        }
      } catch (error) {
        console.error("Server error:", error);
        toast.error("Failed to fetch suggestions. Please try again.");
      }
    };

    fetchSuggestionData();
  }, [user]);

  return (
    <div
      className="hover-profile-container"
      onMouseEnter={() => {
        setIsHovered(true);
        playHoverSound();
        if (stopSoundTimeout.current) {
          clearTimeout(stopSoundTimeout.current);
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        stopHoverSound();
      }}
    >
      <img
        src={
          user?.profile
            ? `${BACKEND_URL}${user.profile}`
            : "/default-profile.png"
        }
        alt={`${user?.name || "User"}'s Profile`}
        className="socialmedia-profile-image"
      />

      {isHovered && (
        <div className="socialmedia-hover-card">
          <img
            src={
              user?.profile
                ? `${BACKEND_URL}${user.profile}`
                : "/default-profile.png"
            }
            alt={`${user?.name || "User"}'s Profile`}
            className="hover-card-image"
          />
          <div className="hover-card-info">
            <h4>{user?.name || "Anonymous"}</h4>
            <div className="social-media-hover-posts">
              <span className="socialmedia-account-details">{`Posts ${
                userData?.posts ? userData.posts.length : "No Posts"
              }`}</span>
              <span
                style={{
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                className="socialmedia-account-details"
              >
                {`Followers ${userData?.followers?.length || 0}`}
              </span>

              <span className="socialmedia-account-details">{`Following ${
                userData?.following?.length || 0
              }`}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HoverProfile;
