import React, { useEffect, useState } from 'react'

import styles from "./DairyEventForms.module.css"
import CustomizedSingleRadioButton from '../UI/CustomizedSingleRadioButton';
import { ImCancelCircle } from "react-icons/im";
import { BsPlusCircleFill ,BsDashCircleFill} from "react-icons/bs";

const AddGrowthStageForm = ({ formData,handleMonthlyGrowthChange, setFormData, errors, setErrors }) => {
    const [reminderNotification, setReminderNotification] = useState('');
    const [reminderMe, setReminderMe] = useState('');
    

  const handleReminderNotificationChange = (value) => {
    console.log('Reminder Notification selected:', value);
    setReminderNotification(value);
  };
  const handleReminderMeChange = (value) => {
    console.log('Reminder Notification selected:', value);
    setReminderMe(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", 
    }));
  };

  

  

  const handleAddMonth = () => {
    const newMonth = { date: "", age: "", weight: "", size: "" };
    const updatedGrowth = [...formData.monthlyGrowth, newMonth];
    setFormData((prev) => ({ ...prev, monthlyGrowth: updatedGrowth }));
  };

  const handleRemoveMonth = (index) => {
    const updatedGrowth = formData.monthlyGrowth.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, monthlyGrowth: updatedGrowth }));
  };

 
  useEffect(()=>{
        
    setFormData((prev)=>({...prev,"remindNotification":reminderNotification,"remindOption":reminderMe}))
  },[reminderNotification,reminderMe])

  

  return (
    <>
      <div className={styles.MonthlyGrowthMainContainer}>
      {formData.monthlyGrowth.map((month, index) => (
        <div className={styles.MonthlyGrowthContainer} key={index}>
          <label>
            Month {index + 1}{" "}
            {index === 0 ? (
              <BsPlusCircleFill
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  fontSize: "20px",
                  color: "#1FBFC2",
                  cursor: "pointer",
                }}
                onClick={handleAddMonth}
              />
            ) : null}
            {index > 0 && (
              <BsDashCircleFill
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  fontSize: "20px",
                  color: "#FF4D4F",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveMonth(index)}
              />
            )}
          </label>

          <div
            className={styles.DateDurationMainContainer}
            style={{ marginTop: "0px" }}
          >
            <div className={styles.DateContainer}>
              <input
                type="date"
                placeholder="Enter Date"
                value={month.date}
                onChange={(e) => handleMonthlyGrowthChange(index, "date", e.target.value)}
            />
            {errors[`monthlyGrowth.${index}.date`] && <span className={styles.errorText}>{errors[`monthlyGrowth.${index}.date`]}</span>}
            </div>
            <div className={styles.DateContainer}>
              <input
                type="text"
                placeholder="Enter Age"
                value={month.age}
                onChange={(e) => {
                  const val = e.target.value
                  if(!isNaN(val) && val<99){
                  handleMonthlyGrowthChange(index, "age", e.target.value)}}}
            />
            {errors[`monthlyGrowth.${index}.age`] && <span className={styles.errorText}>{errors[`monthlyGrowth.${index}.age`]}</span>}
            </div>
          </div>
          <div
            className={styles.DateDurationMainContainer}
            style={{ marginTop: "0px" }}
          >
            <div className={styles.DateContainer}>
              <input
                type="text"
                placeholder="Enter Weight"
                value={month.weight}
                onChange={(e) => {
                  const val = e.target.value
                  if(!isNaN(val) && val<99){
                    handleMonthlyGrowthChange(index, "weight", e.target.value)}}}
            />
            {errors[`monthlyGrowth.${index}.weight`] && <span className={styles.errorText}>{errors[`monthlyGrowth.${index}.weight`]}</span>}
            </div>
            <div className={styles.DateContainer}>
              <input
                type="text"
                placeholder="Enter Size"
                value={month.size}
                onChange={(e) => {
                  const val = e.target.value
                  if(!isNaN(val) && val<99){handleMonthlyGrowthChange(index, "size", e.target.value)}}}
            />
            {errors[`monthlyGrowth.${index}.size`] && <span className={styles.errorText}>{errors[`monthlyGrowth.${index}.size`]}</span>}
            </div>
          </div>
        </div>
      ))}
      </div>
          

          
      <div className={styles.SelectFieldContainer} style={{marginTop:"30px"}}>
            <label>Description</label>
            <textarea name="description" id="" rows={4} value={formData.description} onChange={handleInputChange}></textarea>
            {errors.description && <span style={{position:"absolute",bottom:"0px",color:"red",fontSize:"14px",}}>{errors.description}</span>}
      </div>
      <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Reminder Notification</label>
              <div className={styles.checkboxMainContainer}>
              {["yes", "no"].map((value) => (
              <div className={styles.checkboxContainer} key={value}>
                <CustomizedSingleRadioButton
                  label={value === "yes" ? "Yes" : "No"}
                  name="reminderNotification"
                  value={value}
                  selectedValue={reminderNotification}
                  onChange={handleReminderNotificationChange}
                />
              </div>
            ))}
            {errors.remindNotification && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindNotification}</span>}
              </div>
            </div>

            
          </div>
          {reminderNotification === "yes" && <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Date</label>
              <input type="date" name="toRemindDate" value={formData.toRemindDate} onChange={handleInputChange}/>
              {errors.toRemindDate && <span className={styles.errorText}>{errors.toRemindDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Duration</label>
              <input type="time" name="toRemindTime" value={formData.toRemindTime} onChange={handleInputChange}/>
              {errors.toRemindTime && <span className={styles.errorText}>{errors.toRemindTime}</span>}
            </div>
          </div>}
          <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Remind Me</label>
              <div className={styles.checkboxMainContainer}>
                <div className={styles.checkboxContainer}>
                  <CustomizedSingleRadioButton
                    label="Monthly"
                    name="reminderMe"
                    value="monthly"
                    selectedValue={reminderMe}
                    onChange={handleReminderMeChange}
                  />
                </div>
                <div className={styles.checkboxContainer}>
                  <CustomizedSingleRadioButton
                    label="Never"
                    name="reminderMe"
                    value="never"
                    selectedValue={reminderMe}
                    onChange={handleReminderMeChange}
                  />
                </div>
                {errors.remindOption && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindOption}</span>}
              </div>
            </div>

            
          </div>
    </>
  )
}



export default AddGrowthStageForm