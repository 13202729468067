import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import styles from "./PetDiary.module.css";
import { useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { IoIosStar } from "react-icons/io";
import { petMenu,faq } from "../../Tinder/constants";
import DogAndCat from "../../../assets/images/DogAndCat.png"
import VaccinePd from "../../../assets/images/VaccinePd.png"
import PhonePd from "../../../assets/images/PhonePd.png"
import BookMarkPd from "../../../assets/images/BookMarkPd.png"


const PetDiary = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate("/pet-diary-calendar");
  };

  const images = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "Dogs",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "Cats",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "Others",
    },
  ];

  const customers = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "abc",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "def",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "ghi",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "jkl",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "mno",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "pqr",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "stu",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "vwx",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "yz",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "123",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "456",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "Others",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbaea14dabf7b4170849bc67d16e3bc93",
      name: "Dogs",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F605fd212365f4316bdca9f31fce11483",
      name: "Cats",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fbeea814aed4f4e72a0bb4c56fab461c7",
      name: "Others",
    },
  ];

  const onClickFaqFunction = () =>{

  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % customers.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [customers.length]);

  const getVisibleCards = () => {
    const total = customers.length;
    return [
      customers[(currentIndex + total - 1) % total], // Previous
      customers[currentIndex],                      // Current (Middle)
      customers[(currentIndex + 1) % total],        // Next
    ];
  };

  const visibleCards = getVisibleCards();

  return (
    <>
      <div>
        <Header />
      </div>

      <div className={styles.petdaiarybackgroundcontainer}>
        <div className={styles.petdaiarycontent}>
          <span className={styles.petdaiaryheading}>Diary</span>
          <span className={styles.petdaiarysubheading}>
            Lorem ipsum dolor sit amet
          </span>
          <button
            className={styles.petdaiaryctabutton}
            onClick={handleGetStartedClick}
          >
            GET STARTED
          </button>
        </div>
      </div>

      {/* <div>
        <div>
          <div className={styles.petdaiaryimgegridcontainer}>
            {images.map((image, index) => (
              <div className={styles.petdaiaryimageitem} key={index}>
                <img src={image.src} alt={image.name} />
                <p className={styles.petdaiaryimagename}>{image.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div> */}

       <section className={styles.PetMenuDiv}>
          <div className={styles.petMenuDiv2}>
            {images.length ? images.map((pet, index) => (
              <button key={index}  className={styles.petMenuButton}>
                <img src={pet.src} alt={pet.name} className={styles.petImage} />
                {pet.name}
              </button>
            )):""}
          </div>
      </section> 
      <section className={styles.GrMidSectionContainer}>
        <div className={styles.PetPicMainContainer}>
          {/* <div className={styles.CatImgContainer}>
          <img src={GroomingCatImg} className={styles.CatImg} alt="Cat" />
          </div>
          <div className={styles.DogImgContainer}>
            <img src={GroomingDogImg} className={styles.DogImg} alt="Dog" />
          </div> */}
          <img src={DogAndCat}  alt="DogAndCat" />
        </div>
        <div className={styles.GrWorkContainer}>
            <h1>About Us</h1>
            <p className={styles.WorkText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae nunc et tellus gravida finibus. Sed nec urna non metus hendrerit maximus ac eget arcu. Donec vitae augue ante. Vestibulum vitae posuere tortor. Fusce lacus sapien, accumsan non consectetur non, dignissim id risus. In eget nisi id massa suscipit sodales eu non lacus.
            <br/>
            Nunc leo purus, hendrerit ut eros sit amet, venenatis condimentum est. Quisque diam ante, dapibus eget lorem eget, dignissim vehicula diam. Quisque aliquet interdum ipsum in imperdiet. Pellentesque ante ligula, tincidunt sit amet mi sed, elementum gravida nisi. Maecenas placerat ante mi, eu rutrum nunc ultrices vitae. Duis ut ipsum consectetur, ultricies quam eu, aliquam libero. Integer vehicula vestibulum sapien vitae efficitur.
            </p>
            {/* <div className={styles.WorkDetails}>
              <h4>Equipment</h4>
              <GoPlus />

            </div>
            <div className={styles.WorkDetails}>
              <h4>Key Features</h4>
              <GoPlus />

            </div>
            <div className={styles.WorkDetails}>
              <h4>Additional Details</h4>
              <GoPlus />

            </div> */}
        </div>
      </section>
      <section className={styles.GrMoreDetails}>
        <h2 className={styles.GrZaanvarText}>Features</h2>
        <div className={styles.GrMainCardscontainer}>
            <div className={styles.GrCardsContainer}>
              <img style={{marginTop:"20px"}} src={PhonePd} alt="addPet" />
              <h5 style={{marginTop:"14px"}}>Pet Note</h5>
              <p style={{color:"#727272"}}>Read verified reviews by pet <br/>
                  owners like you and choose a <br/>
                  sitter who’s a great.</p>
            </div>
            <div className={styles.GrCardsContainer}>
              <img src={VaccinePd}  style={{marginTop:"20px"}} alt="Date&time" />
              <h5 style={{marginTop:"14px"}}>Record Vaccination</h5>
              <p style={{color:"#727272"}}>Read verified reviews by pet <br/>
                  owners like you and choose a <br/>
                  sitter who’s a great.</p>
            </div>
            <div className={styles.GrCardsContainer}>
              <img src={BookMarkPd} style={{marginTop:"20px"}} alt="book" />
              <h5 style={{marginTop:"14px"}}>Monitor Weight</h5>
              <p style={{color:"#727272"}}>Read verified reviews by pet <br/>
                  owners like you and choose a <br/>
                  sitter who’s a great.</p>
            </div>
        </div>
      </section>
      <section className={styles.GrCustomerDetailsMainContainer}>
        <h3 className={styles.GrZaanvarText} style={{marginBottom:"80px"}}>Happy Customers</h3>
        <div className={styles.GrCustomerContainer}>
        {visibleCards.map((customer,index)=>(
          <div key={index} style ={{width: index === 1 ? "350px" : "300px", // Middle card is larger
              height: index === 1 ? "350px" : "280px",
              transition: "all 0.5s ease",marginTop:index === 1?"-10px":"25px"}} className={styles.GrAboutCustomers}>
            <img src={customer.src} style={{marginTop:"-80px",borderRadius:"50%"}} alt="" />
            <h3 style={{marginTop:"10px"}}>{customer.name}</h3>
            <p>Parent of Fluffy</p>
            <p> Trust me when I say this; the service was top-notch, and the groomers were extremely friendly.</p>
            <p  style={{display:"flex"}}><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/><IoIosStar style={{color:"yellow",fontSize:"18px"}}/></p>
          </div>
        ))}
          
          
        </div>

      </section>
      <section className={styles.hero2}>
          <img src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F85691eee07cb49d59a0c7fd710020d44" alt="Hero background" className={styles.heroBackground2} />
          <div className={styles.heroDiv2}>
              <h1>More About Diary</h1>
              <p>Zaanvar offers convenient and cost-effective pet grooming services right at your doorstep in Hyderabad. We provide complete pet care and our packages include a bath with shampoo and conditioner, full body hair trimming, nail cutting, ear & eye cleaning, tick and flea treatment, and much more. We have specialized teams of dog and cat groomers experienced in handling different breeds. Our groomers ensure your cat and dogs will experience tranquility and luxury in its own environment.</p>
              <p>Our use of premium products and specialized tools underscores our dedication to your pet's well-being. We understand that each breed is unique, which is why we offer tailored "dog grooming" and "cat grooming" packages for every type, we have breed specific experts for all breeds like Labrador Retrievers, Golden Retrievers, Beagles, shihtzu and German Shepherds, as well as Persian cats. Our specialized "shih tzu grooming" ensures your pet receives the best care possible. Elevate your pet's grooming experience by booking a session with Zaanvar today - because your pet deserves nothing but the best, right in the comfort of your own home!.</p>
              <p>At Zaanvar, we prioritize comprehensive "pet care" to ensure your pets are happy, healthy, and well-groomed</p>
          </div>
      </section>
      <section className={styles.faq}>
        <h1>Frequently Asked Questions About Zaanvar</h1>
        {faq.map((faqItem) => {
          return (
            <div className={styles.faqDiv} onClick = {() => onClickFaqFunction(faqItem.id)}>
              <p className={styles.faqDivQuestion}>{faqItem.question}</p>
              {faqItem.visible && <div className={styles.answersDiv}>
                {faqItem.answer.map(answer => {
                  return (
                    <div>
                      <p className={styles.answerTitle}>{answer.title}</p>
                      <p className={styles.answerText}>{answer.text}</p>
                    </div>
                  )
                })}
              </div>}
            </div>
          )
        })}
      </section>
      
    </>
  );
};

export default PetDiary;
