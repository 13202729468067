import React, { useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";
import styles from "./ChatPopup.module.css";
import { CrossIconBlack } from "../../../../assets/SVG";
import { ChatMenu } from "../../constants";
import { WebApimanager } from "../../../../WebApiManager";
import { BACKEND_URL, SOCKET_SERVER_URL } from "../../../../Utilities/Constant";
import { getRecoil } from "recoil-nexus";
import { useRecoilState } from "recoil";
import { jwtToken, userInfo } from "../../../../Atoms";
import ParticipantChatScreen from "./ParticipantChatScreen";

const ChatPopup = ({
  isChatPopupOpen,
  setIsChatPopupOpen,
  currentUserId,
  selectedPet,
  sendingOwnerDetials2,
}) => {
  const webApi = new WebApimanager();
  const popupRef = useRef(null);
  const [isChatscreenOpen, setIsChatscreenOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(ChatMenu[0]); // Default initialization to the first chat item in ChatMenu
  const [messages, setMessages] = useState(selectedChat.messages || []);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [currentUser] = useRecoilState(userInfo);
  const [conversationId, setConversationId] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const [senderPetId, setSenderPetId] = useState(null);

  const userId = currentUser._id;
  let jwttoken = getRecoil(jwtToken);

  // Initialize socket connection
  useEffect(() => {
    if (!userId) return;

    const newSocket = io(SOCKET_SERVER_URL, { query: { userId } });
    setSocket(newSocket);

    // Listen for online users
    newSocket.on("getOnlineUsers", (users) => {
      setOnlineUsers(users);
    });

    // Listen for received messages
    newSocket.on("receiveMessage", (receivedMessage) => {
      console.log("Received message:", receivedMessage);

      // Update conversations with new message
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv._id === receivedMessage.conversationId
            ? {
                ...conv,
                lastMessage: receivedMessage, // Update last message
                unreadCount: conv.unreadCount ? conv.unreadCount + 1 : 1, // Increment unread count
              }
            : conv
        )
      );

      // If selected chat matches, update messages
      if (selectedChat && selectedChat._id === receivedMessage.conversationId) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [receivedMessage.conversationId]: [
            ...(prevMessages[receivedMessage.conversationId] || []),
            receivedMessage,
          ],
        }));
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, [userId, selectedChat]);

  // Fetch conversations on component mount
  useEffect(() => {
    if (!userId) return;

    const fetchConversations = async () => {
      try {
        const response = await fetch(
          `${SOCKET_SERVER_URL}api/message/conversations`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwttoken,
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch conversations");

        const data = await response.json();
        setConversations(data.conversations || []); // Set conversations if available
      } catch (error) {
        console.error("Error fetching conversations:", error.message);
      }
    };

    fetchConversations();
  }, [userId, jwttoken]);

  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    const payload = {
      sender: userId,
      receiver: receiverId,
      content: newMessage,
      conversationId,
      senderPetId: selectedPet._id,
      receiverPetId: senderPetId,
    };

    try {
      const response = await fetch(`${SOCKET_SERVER_URL}api/message/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwttoken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error("Failed to send message");

      const sentMessage = await response.json();

      // Update all chat messages immediately
      setMessages((prev) => ({
        ...prev,
        [conversationId]: [...(prev[conversationId] || []), sentMessage],
      }));

      // Emit message to socket
      socket.emit("sendMessage", sentMessage);

      // Clear input field
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error.message);
    }
  };

  const handleCheckReceiver = (item) => {
    const filterReceiver = item?.participants.filter(
      (rec) => rec._id !== userId
    );
    setReceiverId(filterReceiver[0]._id);
    setSenderPetId(item.lastMessage.senderPetId._id); // Set senderPetId based on last message
  };

  const handleConversationClick = (item) => {
    setSelectedChat(item); // Update selected chat
    setConversationId(item.lastMessage.conversationId);
    setIsChatscreenOpen(true);
    setIsChatPopupOpen(false); // Close popup when chat is selected
    handleCheckReceiver(item); // Set receiver and senderPetId
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsChatPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsChatPopupOpen]);

  const handleChatScreenClose = () => {
    setIsChatscreenOpen(false);
    setIsChatPopupOpen(true);
  };

  return (
    <>
      {isChatPopupOpen && (
        <div className={styles.Background}>
          <div className={styles.popupContainer} ref={popupRef}>
            {/* Header Section */}
            <div className={styles.header}>
              <p style={{ margin: "0px", marginLeft: "25px" }}>
                Chat For {selectedPet.petName}
              </p>
              <div
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={() => setIsChatPopupOpen(false)}
              >
                <CrossIconBlack />
              </div>
            </div>

            {/* Main Content */}
            <div className={styles.mainContainer}>
              {Array.isArray(conversations) &&
                conversations.map((item, index) => (
                  <div
                    className={styles.chat}
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleConversationClick(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {item.participants
                        .filter((participant) => participant._id !== userId) // Exclude current user
                        .map((participant, pIndex) => (
                          <div
                            key={pIndex}
                            style={{ display: "flex", gap: "15px" }}
                          >
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#ccc",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              {participant.name.charAt(0)}{" "}
                              {/* Initial of participant */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p className={styles.name}>
                                {participant.name} |{" "}
                                {item.lastMessage.receiverPetId.petName}
                              </p>
                              <p className={styles.lastMessageContent}>
                                {item.lastMessage.content}{" "}
                                {/* Display last message content */}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        position: "relative",
                        top: "-10px",
                      }}
                    >
                      <span
                        className={styles.time}
                        style={{ color: "#999", marginBottom: "10px" }}
                      >
                        {new Date(
                          item.timestamp || item.createdAt
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>

                      {item.unreadCount > 0 && (
                        <span
                          style={{
                            fontSize: "8px",
                            color: "white",
                            backgroundColor: "green",
                            padding: "6px 4px",
                            borderRadius: "50%",
                            height: "10px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "fit-content",
                          }}
                        >
                          {item.unreadCount}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <ParticipantChatScreen
        chat={selectedChat}
        onClose={handleChatScreenClose}
        conversationId={conversationId}
        receiverId={receiverId}
        socket={socket}
        senderPetId={senderPetId}
        selectedPet={selectedPet}
        isChatscreenOpen={isChatscreenOpen}
        sendingOwnerDetials2={sendingOwnerDetials2}
        sendMessage={sendMessage}
      />
    </>
  );
};

export default ChatPopup;
