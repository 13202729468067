import React, { useState } from "react";
import style from "./ui.module.css"
const CustomTextareaComponent = ({ question, placeHolder, rows = 1, width = '100%', value, onInputChange,customStyles,fontsize,customStylestext,customStylesedit }) => {
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (onInputChange) {
            onInputChange(newValue);
        }
    };

    return (
        <div style={{ flex: `1 1 ${width}`, marginBottom: '15px' }}>
            <p style={{
                color:customStyles?.color||customStylesedit?.color,
                padding:customStyles?.padding,
                            }} className={style.CTACPara}>{question}</p>
            <textarea
                placeholder={placeHolder}
                rows={rows}
                style={{
                    width: '100%',
                    padding: '15px 25px',
                    fontSize: '13px',
                    outline: 'none', 
                    boxSizing: 'border-box', 
                    border:customStylestext?.border,
                    color:customStylestext?.color,
                    backgroundColor:customStylestext?.backgroundColor,
                    boxShadow: "none", 
                }}
                value={value} 
                onChange={handleChange} 
                onFocus={(e) => e.target.style.borderColor = '#D9D9D9'} 
                onBlur={(e) => e.target.style.borderColor = '#D9D9D9'} 
            />
        </div>
    );
};

export default CustomTextareaComponent;
