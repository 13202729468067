import React, { useEffect, useState } from "react";
import styles from "./Tinder.module.css";
import { petInfoForMatches } from "./constants";
import PetProfileCard from "./PetProfileCard";
import { BackIcon, Chaticon, SettingsIcon } from "../../assets/SVG";
import { useNavigate, useLocation } from "react-router";
import FiltersPopup from "./Popups/FiltersPopup/FiltersPopup";
import PetInfoPopup from "./Popups/PetInfoPopup/PetInfoPopup";
import { WebApimanager } from "../../WebApiManager";
import closeIcon from "../../assets/images/closeIcon.png";
import axios from "axios"; // or use fetch if you prefer
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SOCKET_SERVER_URL } from "../../Utilities/Constant";
import { useRecoilState } from "recoil";
import { io } from "socket.io-client";
import { getRecoil } from "recoil-nexus";
import { jwtToken, userInfo } from "../../Atoms";
import Footer from "../Footer/Footer";
import ChatPopup from "./Popups/ChatPopup/ChatPopup";
import TinderSingleChatPage from "./Popups/ChatPopup/TnderSngleChatPage";

const TinderMatches = () => {
  const navigate = useNavigate();
  const webApi = new WebApimanager();
  const location = useLocation();
  const selectedPet = location.state?.selectedPet || null;
  const oldPetId = location.state?.oldpetid || null;
  const [isFiltersPopupOpen, setIsFiltersPopupOpen] = useState(false);
  const [ispetInfoPopupOpen, setIspetInfoPopupOpen] = useState(false);
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);
  const [isChatPopupOpen2, setIsChatPopupOpen2] = useState(false);
  const [sendingOwnerDetials, setsendingOwnerDetials] = useState([]);
  const [sendingOwnerDetials2, setsendingOwnerDetials2] = useState([]);
  console.log(sendingOwnerDetials2, "lolo");

  const [tempPetInfo, setTempPetInfo] = useState([]);
  const [petData, setPetData] = useState([]);
  const [selectedPetInfo, setSelectedPetInfo] = useState(null);
  const [pureBreedOnly, setPureBreedOnly] = useState(false);
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [socket, setSocket] = useState(null);
  const [conversationsSingle, setConversationsSingle] = useState([]);

  const [currentUser] = useRecoilState(userInfo);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  console.log(selectedPet, "checking singlechat m");
  let jwttoken = getRecoil(jwtToken);

  const userId = currentUser._id;

  const [filters, setFilters] = useState({
    distance: 1370,
    size: "Select Size",
    pureBreedOnly: false,
    pedigreeCertifiedOnly: false,
    dnaCertifiedOnly: false,
    selectedBreeds: [], // Initialize as an empty array
  });
  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };
  const getCoordinates = async (location) => {
    const apiKey = "8d5c70e15467446fb13fb239745b7aea";
    const url = `https://api.opencagedata.com/geocode/v1/json?q=${location}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      console.log(`Geocoding response for ${location}:`, response.data);
      if (
        response.data &&
        response.data.results &&
        response.data.results.length > 0
      ) {
        const { lat, lng } = response.data.results[0].geometry;
        return { lat, lng };
      } else {
        console.error(`No coordinates found for location: ${location}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const R = 3958.8;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  useEffect(() => {
    if (selectedPet && userLocation.latitude && userLocation.longitude) {
      webApi
        .get(
          `petProfile/visiblePets?petId=${selectedPet._id}&type=lover&petGender=${selectedPet.petGender}&petType=${selectedPet.petType}`
        )
        .then(async (response) => {
          const pets = response.data.data.petProfiles;

          const filteredPets = await Promise.all(
            pets.map(async (pet) => {
              const petLocation = pet.location;
              console.log(
                `Fetching coordinates for Pet Location: ${petLocation}`
              );
              const petCoordinates = await getCoordinates(petLocation);
              if (!petCoordinates) {
                console.log(
                  `Skipping pet ${pet._id} due to missing coordinates`
                );
                return null;
              }
              const petLat = petCoordinates.lat;
              const petLon = petCoordinates.lng;
              console.log(
                `User Location: ${userLocation.latitude}, ${userLocation.longitude}`
              );
              console.log(`Pet Location Coordinates: ${petLat}, ${petLon}`);
              const distance = haversineDistance(
                userLocation.latitude,
                userLocation.longitude,
                petLat,
                petLon
              );
              console.log(`Pet ID: ${pet._id}, Distance: ${distance} miles`);
              const isWithinDistance = distance <= filters.distance;
              const matchesSize =
                filters.size === "Select Size" || pet.size === filters.size;
              const matchesPureBreed = !filters.pureBreedOnly || pet.pureBreed;
              const matchesPedigree =
                !filters.pedigreeCertifiedOnly || pet.pedigreeCertified;
              const matchesDNA = !filters.dnaCertifiedOnly || pet.dnaCertified;
              const matchesBreed =
                filters.selectedBreeds.length === 0 ||
                filters.selectedBreeds.includes(pet.breed);
              return isWithinDistance &&
                matchesSize &&
                matchesPureBreed &&
                matchesPedigree &&
                matchesDNA &&
                matchesBreed
                ? pet
                : null;
            })
          );
          const validPets = filteredPets.filter((pet) => pet !== null);
          setTempPetInfo(validPets);
        })
        .catch((error) => {
          console.error("Error fetching pet data:", error);
        });
    }
  }, [selectedPet, filters, userLocation]);

  const fetchLocationDetails = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();

      if (data && data.address) {
        const { suburb, city_district, city, state } = data.address;
        const detailedLocation =
          suburb || city_district || city || state || "Unknown Location";

        setUserLocation({ latitude, longitude });
      } else {
        toast.error("Could not fetch detailed location.");
      }
    } catch (error) {
      toast.error("Failed to fetch location details.");
    }
  };

  useEffect(() => {
    const handleCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchLocationDetails(latitude, longitude);
          },
          (error) => {
            console.error("Error fetching location:", error);
            toast.error("Failed to fetch location. Please try again.");
          }
        );
      } else {
        toast.error("Geolocation is not supported by this browser.");
      }
    };

    handleCurrentLocation();
  }, []);
  const handleCardClick = (selectedPetData) => {
    setSelectedPetInfo(selectedPetData);
  };

  const handleRemoveFilter = (filterToRemove) => {
    const pureBreedOnly = false;
    setFilters((prevFilters) => {
      if (prevFilters.selectedBreeds.includes(filterToRemove)) {
        console.log("Removing breed filter:", filterToRemove);
        return {
          ...prevFilters,
          selectedBreeds: prevFilters.selectedBreeds.filter(
            (breed) => breed !== filterToRemove
          ),
        };
      }

      switch (filterToRemove) {
        case `Within ${prevFilters.distance} miles`:
          console.log("Resetting distance filter");
          return { ...prevFilters, distance: 1370 };
        case "Pure breed":
          console.log("Resetting pureBreedOnly filter");
          return { ...prevFilters, pureBreedOnly: false };
        case "Pedigree Certificate":
          console.log("Resetting pedigreeCertifiedOnly filter");
          return { ...prevFilters, pedigreeCertifiedOnly: false };
        case "DNA Certified":
          console.log("Resetting dnaCertifiedOnly filter");
          return { ...prevFilters, dnaCertifiedOnly: false };
        default:
          if (prevFilters.size === filterToRemove) {
            console.log("Resetting size filter");
            return { ...prevFilters, size: "Select Size" };
          }
          return prevFilters;
      }
    });
  };
  const [showAllFilters, setShowAllFilters] = useState(false);
  const visibleFilters = [
    `Within ${filters.distance} miles`,
    filters.pureBreedOnly ? "Pure breed" : null,
    filters.pedigreeCertifiedOnly ? "Pedigree Certificate" : null,
    filters.dnaCertifiedOnly ? "DNA Certified" : null,
    filters.size !== "Select Size" ? filters.size : null,
    ...filters.selectedBreeds.map((breed) => `${breed}`),
  ].filter(Boolean);
  console.log(sendingOwnerDetials.name, "checking petowner detials222");

  const onClose = () => {
    setIsChatPopupOpen2(false); // Close the popup
  };
  console.log(selectedPet, "selectedPet");

  useEffect(() => {
    if (!userId) return;

    // Initialize Socket.IO
    const newSocket = io(SOCKET_SERVER_URL, {
      query: { userId },
    });

    setSocket(newSocket);

    newSocket.on("getOnlineUsers", (users) => {
      setOnlineUsers(users);
    });

    // Fetch Conversations
    const fetchConversations = async () => {
      try {
        const response = await fetch(
          `${SOCKET_SERVER_URL}api/message/conversations`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwttoken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setConversations(data);

        console.log(data, "conversiton");
        data.forEach((conversation) => {
          console.log(
            conversation.lastMessage.conversationId,
            "conversiton_id"
          );
        });
      } catch (error) {
        console.error("Error fetching conversations:", error.message);
      }
    };

    fetchConversations();

    return () => {
      newSocket.disconnect();
    };
  }, [userId, jwttoken]);
  return (
    <>
      <ToastContainer />
      <section className={styles.TinderHomePage1}>
        <div className={styles.matchesHeaderDiv}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => navigate("/tinder/home")}
                style={{ cursor: "pointer" }}
              >
                <BackIcon />
              </div>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  margin: "0px",
                  marginLeft: "10px",
                }}
              >
                Matches for {selectedPet.petName}
              </p>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsFiltersPopupOpen(true)}
            >
              <SettingsIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              margin: "20px 0px",
              width: "100%",
            }}
          >
            {visibleFilters
              .slice(0, showAllFilters ? visibleFilters.length : 4)
              .map((item, index) => (
                <div
                  className={styles.pillsDiv}
                  key={index}
                  style={{ display: "flex" }}
                >
                  <p style={{ display: "flex" }}>
                    {item}
                    <span
                      style={{ cursor: "pointer", margin: "5px 0 0 10px" }}
                      onClick={() => handleRemoveFilter(item)}
                    >
                      <img src={closeIcon} alt="close icon" />
                    </span>
                  </p>
                </div>
              ))}
            {visibleFilters.length > 4 && (
              <div
                className={styles.pillsDiv}
                style={{ cursor: "pointer", color: "#007bff" }}
                onClick={() => setShowAllFilters(!showAllFilters)}
              >
                {showAllFilters ? "Show Less" : "More"}
              </div>
            )}
          </div>
        </div>
        <div className={styles.profileCardDiv1}>
          {tempPetInfo.map((petData) => {
            {
              console.log("checkingpetdata", petData);
            }
            return (
              <PetProfileCard
                petData={petData}
                key={petData.id}
                type="match"
                setIspetInfoPopupOpen={setIspetInfoPopupOpen}
                setIsChatPopupOpen2={setIsChatPopupOpen2}
                setsendingOwnerDetials={setsendingOwnerDetials}
                setsendingOwnerDetials2={setsendingOwnerDetials2}
                oldPetId={oldPetId}
                onCardClick={handleCardClick}
              />
            );
          })}
        </div>
        <div
          className={styles.chatButton}
          onClick={() => setIsChatPopupOpen((prev) => !prev)}
        >
          <Chaticon />
        </div>
        <FiltersPopup
          isFiltersPopupOpen={isFiltersPopupOpen}
          setIsFiltersPopupOpen={setIsFiltersPopupOpen}
          updateFilters={updateFilters}
          sendingPetType={selectedPet.petType}
          handleRemoveFilter2={handleRemoveFilter}
          setPureBreedOnly={setPureBreedOnly}
          pureBreedOnly={pureBreedOnly}
        />
        <PetInfoPopup
          ispetInfoPopupOpen={ispetInfoPopupOpen}
          setIspetInfoPopupOpen={setIspetInfoPopupOpen}
          selectedPetInfo={selectedPetInfo}
        />
        <ChatPopup
          isChatPopupOpen={isChatPopupOpen}
          setIsChatPopupOpen={setIsChatPopupOpen}
          selectedPet={selectedPet}
          sendingOwnerDetials2={sendingOwnerDetials2}
        />
        <TinderSingleChatPage
          isChatPopupOpen2={isChatPopupOpen2}
          setIsChatPopupOpen2={setIsChatPopupOpen2}
          // sendingOwnerDetials2={setsendingOwnerDetials}
          sendingOwnerDetials={sendingOwnerDetials}
          sendingOwnerDetials2={selectedPet}
          sendingOwnerDetials3={sendingOwnerDetials2}
          onClose={onClose}
        />
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};

export default TinderMatches;
