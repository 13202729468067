import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import like from "../../../assets/Social_Media_Icons/like.svg";
import comment from "../../../assets/Social_Media_Icons/comment.svg";
import share from "../../../assets/Social_Media_Icons/share.svg";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { Atom } from "react-loading-indicators";

const ParticularUserQandA = ({ userId }) => {
  const webApi = new WebApimanager();
  const [questionAndAnswer, setQuestionAndAnswer] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const response = await webApi.get(
            `socialMedia/postsByUserId/${userId}?type=Text%20Poll`
          );
          if (response?.data?.posts) {
            setQuestionAndAnswer(response.data.posts);

            console.log(response, "respon poollll");

            // Set initial selected options
            const initialSelection = {};
            response.data.posts.forEach((post) => {
              const selectedOption = post.option.find((opt) =>
                opt.selectedUsers?.includes(userId)
              );
              if (selectedOption) {
                initialSelection[post._id] = selectedOption._id;
              }
            });
            setSelectedOptions(initialSelection);
          } else {
            console.error("Invalid response structure", response);
          }
        } else {
          console.warn("User ID is not available");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const formatInstagramTime = (timeStamp) => {
    const currentTime = new Date();
    const postTime = new Date(timeStamp);
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else {
      return `${days}d ago`;
    }
  };

  const addOption = async (postId, optionId) => {
    try {
      await webApi.put(
        `socialMedia/updateSelectedUser?postId=${postId}&optionId=${optionId}`
      );
      setSelectedOptions((prev) => ({
        ...prev,
        [postId]: optionId,
      }));
      alert("Your vote has been recorded!");
    } catch (error) {
      console.error("Error submitting vote:", error);
      alert("Failed to submit your vote. Please try again.");
    }
  };

  return (
    <div>
      {loading ? (
        <Atom
          color="#fc8d05"
          size="medium"
          text="Loading Q&A..."
          textColor="#fff"
        />
      ) : questionAndAnswer.length > 0 ? (
        questionAndAnswer.map((QandA) => (
          <div
            key={QandA._id}
            className="socilamedia-QandA-header"
            style={{ gap: "20px" }}
          >
            <div className="socialmedia-image-text">
              <img
                src={
                  QandA.userId?.profile
                    ? `${BACKEND_URL}${QandA.userId.profile}`
                    : "https://via.placeholder.com/150"
                }
                alt="Profile"
                className="socialmedia-profile-image-QandA"
              />
              <div className="socialmedia-user-info">
                <div className="socialmedia-user-name">
                  {QandA.userId?.name || "Unknown User"}
                </div>
                <div className="socialmedia-user-time">
                  {formatInstagramTime(QandA.timeStamp)}
                </div>
              </div>
            </div>
            <div className="socialmedia-user-info-dec">
              <span>{QandA.question || "No question provided."}</span>
            </div>
            <div className="poll-container">
              {QandA.option && QandA.option.length > 0 ? (
                QandA.option.map((opt) => {
                  const totalVotes = QandA.option.reduce(
                    (acc, curr) => acc + (curr.selectedUsers?.length || 0),
                    0
                  );
                  const percentage =
                    totalVotes > 0
                      ? Math.round(
                          ((opt.selectedUsers?.length || 0) / totalVotes) * 100
                        )
                      : 0;

                  const isActive = opt.selectedUsers?.some(
                    (user) => user.user === userId
                  );
                  return (
                    <div className="poll-option" key={opt._id}>
                      <div
                        onClick={() => addOption(QandA._id, opt._id)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          marginBottom: "10px",
                          // backgroundColor: isActive ? "#e6f7ff" : "transparent",
                          // padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "10px",
                            fontSize: "20px",
                            color: isActive ? "#1FBFC2" : "#333",
                          }}
                        >
                          {isActive ? "✔️" : "🔘"}
                        </span>
                        <label
                          style={{
                            flex: 1,
                            // fontWeight: isActive ? "bold" : "normal",
                          }}
                        >
                          {opt.option}
                        </label>
                        <span>{`${percentage}%`}</span>
                      </div>
                      <div
                        style={{
                          height: "10px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "5px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: `${percentage}%`,
                            height: "100%",
                            backgroundColor: "#1FBFC2",
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No options available for this poll.</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="no-data-message">
          <img
            src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F23c38233cf66491c9332b58fc0cced5c"
            alt="No Data Available"
            style={{
              width: "200px",
              height: "auto",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
          <p className="text-center">
            No Q&A data available. Please check back later.
          </p>
        </div>
      )}
    </div>
  );
};

export default ParticularUserQandA;
