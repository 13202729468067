import React, { useEffect, useState } from 'react'

import styles from "./DairyEventForms.module.css"
import CustomizedSingleRadioButton from '../UI/CustomizedSingleRadioButton';
import { ImCancelCircle } from "react-icons/im";

const AddFleaAndTickTreatmentForm = ({formData, setFormData,errors,setErrors}) => {
    const [reminderNotification, setReminderNotification] = useState('');
    const [reminderMe, setReminderMe] = useState('');

  const handleReminderNotificationChange = (value) => {
    console.log('Reminder Notification selected:', value);
    setReminderNotification(value);
  };
  const handleReminderMeChange = (value) => {
    console.log('Reminder Notification selected:', value);
    setReminderMe(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", 
    }));
  };
  
  useEffect(()=>{
      
      setFormData((prev)=>({...prev,"remindNotification":reminderNotification,"remindOption":reminderMe}))
    },[reminderNotification,reminderMe])

 
  return (
    <>
      <div className={styles.SelectFieldContainer}>
        <label>Enter Flea & Tick Treatment Tilte</label>
        <input type="text" name="fleaAndTickTreatment" value={formData.fleaAndTickTreatment} onChange={handleInputChange}/>
        {errors.fleaAndTickTreatment && <span className={styles.errorText}>{errors.fleaAndTickTreatment}</span>}
      </div>
          
          

          <div className={styles.DateDurationMainContainer} style={{marginTop:"0px"}}>
            <div className={styles.DateContainer}>
              <label>Treatment Date</label>
              <input type="date" name="treatmentDate" value={formData.treatmentDate} onChange={handleInputChange}/>
              {errors.treatmentDate && <span className={styles.errorText}>{errors.treatmentDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Treatment Time</label>
              <input type="time" name="treatmentTime" value={formData.treatmentTime} onChange={handleInputChange}/>
              {errors.treatmentTime && <span className={styles.errorText}>{errors.treatmentTime}</span>}
            </div>
          </div>

          
          <div className={styles.SelectFieldContainer} style={{marginTop:"30px"}}>
            <label>Description</label>
            <textarea name="description" id="" rows={4} value={formData.description} onChange={handleInputChange}></textarea>
            {errors.description && <span style={{position:"absolute",bottom:"0px",color:"red",fontSize:"14px",}}>{errors.description}</span>}
          </div>
          
          <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Reminder Notification</label>
              <div className={styles.checkboxMainContainer}>
              {["yes", "no"].map((value) => (
              <div className={styles.checkboxContainer} key={value}>
                <CustomizedSingleRadioButton
                  label={value === "yes" ? "Yes" : "No"}
                  name="reminderNotification"
                  value={value}
                  selectedValue={reminderNotification}
                  onChange={handleReminderNotificationChange}
                />
              </div>
            ))}
            {errors.remindNotification && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindNotification}</span>}
              </div>
            </div>

            
          </div>
          {reminderNotification === "yes" && <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Date</label>
              <input type="date" name="toRemindDate" value={formData.toRemindDate} onChange={handleInputChange}/>
              {errors.toRemindDate && <span className={styles.errorText}>{errors.toRemindDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Duration</label>
              <input type="time" name="toRemindTime" value={formData.toRemindTime} onChange={handleInputChange}/>
              {errors.toRemindTime && <span className={styles.errorText}>{errors.toRemindTime}</span>}
            </div>
          </div>}
          <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Remind Me</label>
              <div className={styles.checkboxMainContainer}>
                <div className={styles.checkboxContainer}>
                  <CustomizedSingleRadioButton
                    label="Monthly"
                    name="reminderMe"
                    value="monthly"
                    selectedValue={reminderMe}
                    onChange={handleReminderMeChange}
                  />
                </div>
                <div className={styles.checkboxContainer}>
                  <CustomizedSingleRadioButton
                    label="Never"
                    name="reminderMe"
                    value="never"
                    selectedValue={reminderMe}
                    onChange={handleReminderMeChange}
                  />
                </div>
                {errors.remindOption && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindOption}</span>}
              </div>
            </div>

            
          </div>
    </>
  )
}



export default AddFleaAndTickTreatmentForm