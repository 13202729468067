import React, { useState } from "react";
import "./MyPost.css";
import Popup from "./Popup";
import backimg from "../../../assets/images/back.png";
import lineimg from "../../../assets/images/line.png";
import LostAndFoundCards from "../LostAndFoundHomePage/LostAndFoundCards";
import Footer from "../../Footer/Footer";
import dogImgR from "../../../assets/images/download-removebg-preview 4.png";
import dogImgL from "../../../assets/images/download-removebg-preview 3 (1).png";
import { useNavigate } from "react-router";
import LostPopup from "./LostPopup";
import FoundPopup from "./FoundPopup";
import msgimg from "../../../assets/images/msgicon-1.png";
import ChatPage from "../LostAndFoundHomePage/ChatPage";
import MyPostPetDetails from "./MyPostPetDetails";
import ChangeStatus from "./ChangeStatus";
import ChangeStatusPage2 from "./ChangeStatusPage2";
import EditPetDetails from "./EditPetDetails";
import MypostCards from "./MyPostCards";

const MyPost = () => {
  const navigator = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isNewPopupOpen, setIsNewPopupOpen] = useState(false);
  const [isFoundPopupOpen, setIsFoundPopupOpen] = useState(false);
  const [isChatPageOpen, setIsChatPageOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null); 
  const [selectedPet2, setSelectedPet2] = useState(null);
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false); 
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [isNewPopupOpen2, setIsNewPopupOpen2] = useState(false);
  const [isChangeStatusPage2Open, setIsChangeStatusPage2Open] = useState(false);
  const [statusValue, setStatusValue] = useState("");

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);
  const closePopup2 = () => setIsPopupOpen2(false);

  const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);
  const openChangeStatus = () => {
    setIsChangeStatusOpen(true);
  };
  const closeChangeStatus = () => {
    setIsChangeStatusOpen(false);
  };

  const openEditpopup = () => {};

  const openNewPopup = () => {
    setIsPopupOpen(false);
    setIsNewPopupOpen(true);
  };
  const openNewPopup2 = () => {
    setIsPopupOpen2(false);
    setIsNewPopupOpen2(true);
  };

  const closeNewPopup = () => setIsNewPopupOpen(false);

  const openFoundPopup = () => {
    setIsPopupOpen(false);
    setIsFoundPopupOpen(true);
  };

  const closeFoundPopup = () => setIsFoundPopupOpen(false);

  const backToHome = () => {
    navigator("/lost-and-found/HomePage");
  };

  const handleCardClick = (pet) => {
    setSelectedPet(pet);
    setSelectedPet2(pet);
    setIsPopupOpen(false);
    setIsChatPopupOpen(false);
    setIsNewPopupOpen(false);
  };

  const filters = {
    petType: "Select",
    breedType: "Select",
    petColor: "Select",
    selectedRadioOption: "",
    myPostOnly: "true",
  };

  const [petStatus, setPetStatus] = useState("");

  const handleChangeStatus = (petlostorfound) => {
    setPetStatus(petlostorfound);
  };

  const handleStatusChange = (value) => {
    setStatusValue(value);
    setIsChangeStatusOpen(false);
    setIsChangeStatusPage2Open(true);
  };
  const handleCloseChangestatus = () => {
    setIsChangeStatusPage2Open(false);
  };

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const EditPetDetailspage = () => {
    setSelectedPet(null);
    setIsEditPopupOpen(true);
  };
  const backToHome2 = () => {
    setSelectedPet(selectedPet2);
    setIsChangeStatusOpen(false);
    setIsEditPopupOpen(false);
  };

  return (
    <>
      <div style={{ backgroundColor: "#F6F9FE" }}>
        <section
          style={{ width: "100%", overflowX: "hidden", overflow: "hidden" }}
        >
          <div style={{ width: "100%" }} className="my-post-header">
            <div style={{ display: "flex", marginLeft: "100px" }}>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={backToHome}
              >
                <img
                  src={backimg}
                  style={{ width: "5px", height: "13px" }}
                  alt="Back"
                />
                <img
                  src={lineimg}
                  style={{
                    width: "15px",
                    height: "2px",
                    marginTop: "5.5px",
                    marginLeft: "-5px",
                  }}
                  alt="Line"
                />
              </div>
              <div className="my-post-header-p">My Post</div>
            </div>
          </div>
        </section>
        <section>
          <div>
            <div className="my-post-body">
              <div id="lost-and-found-post">My Post</div>
              <div style={{ width: "100%" }}>
                <MypostCards
                  filters={filters}
                  onCardClick={handleCardClick}
                  onChatClick={() => setIsChatPopupOpen(true)}
                />
              </div>
              <div
                className="my-post-body-content"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <img src={dogImgR} alt="Dog Right" />
                </div>
                <div>
                  <p id="lost-and-found-post-youPet">
                    If Your Pet Is Lost Or Found
                  </p>
                  <button className="lost-and-post-button" onClick={openPopup}>
                    CLICK HERE FOR APPLICATION
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <img src={dogImgL} alt="Dog Left" />
                </div>
              </div>
            </div>
            <div
              className="lost-and-post-mypost-msg"
              onClick={() => setIsChatPageOpen(true)}
            >
              <img src={msgimg} alt="Messages" />
            </div>
          </div>
          <Footer />
          <Popup
            isOpen={isPopupOpen}
            onClose={closePopup}
            onSelectOption={(option) => {
              if (option === "Lost") {
                openNewPopup();
              } else if (option === "Found") {
                openFoundPopup();
              }
            }}
          />
          <LostPopup isOpen={isNewPopupOpen} onClose={closeNewPopup} />
          <FoundPopup isOpen={isFoundPopupOpen} onClose={closeFoundPopup} />
          {isChatPageOpen && (
            <ChatPage
              isChatPopupOpen={isChatPageOpen}
              setIsChatPopupOpen={setIsChatPageOpen}
              onClose={() => setIsChatPageOpen(false)}
            />
          )}
          <MyPostPetDetails
            pet={selectedPet}
            isOpen={!!selectedPet}
            onClose={() => setSelectedPet(null)}
            onChangeStatus={() => {
              setSelectedPet(null);
              openChangeStatus(handleChangeStatus);
            }}
            onSelect={EditPetDetailspage}
          />
          {isEditPopupOpen && (
            <EditPetDetails pet={selectedPet2} back={backToHome2} />
          )}
          {isChangeStatusOpen && (
            <ChangeStatus
              onClose={closeChangeStatus}
              pet={selectedPet2}
              onStatusChange={handleStatusChange}
              back={backToHome2}
            />
          )}
          {isChangeStatusPage2Open && (
            <>
              {console.log("statusValue:", statusValue)}
              <ChangeStatusPage2
                statusValue={statusValue}
                pet={selectedPet2}
                onClose={handleCloseChangestatus}
              />
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default MyPost;
