import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import DoctorAppointment from "../../../../assets/images/veterinarian-check-ing-puppy-s-health.jpg";
import PetGromming from "../../../../assets/images/veterinarian-check-ing-puppy-s-health.jpg";
import DayCare from "../../../../assets/images/veterinarian-check-ing-puppy-s-health.jpg";
import Mating from "../../../../assets/images/veterinarian-check-ing-puppy-s-health.jpg";
import PetPedia from "../../../../assets/images/veterinarian-check-ing-puppy-s-health.jpg";
import LocationforPets from "../../../../assets/images/veterinarian-check-ing-puppy-s-health.jpg";
import {
  Card,
  CardBody,
} from "@chakra-ui/react";
import CustomerNavbar from "../CustomerNavbar";
import "./Services.css"; // External CSS for styling

const Services = () => {
  return (
    <div className="CustomerDashboard">
      <div className="CustomerNavbar">
        <Card width="100%">
          <CardBody>
            <CustomerNavbar />
          </CardBody>
        </Card>
      </div>
      <div className="card-container">
        <div className="card-row">
          <Link to="/doctorAppointment" className="card-link">
            <Card className="service-card-page">
              <img src={DoctorAppointment} className="card-image" alt="Doctor Appointment" />
              <CardBody className="card-body">
                Doctor Appointment
              </CardBody>
            </Card>
          </Link>
          <Link to="/petGromming" className="card-link">
            <Card className="service-card-page">
              <img src={PetGromming} className="card-image" alt="Pet Gromming" />
              <CardBody className="card-body">
                Pet Gromming
              </CardBody>
            </Card>
          </Link>
          <Link to="/dayCare" className="card-link">
            <Card className="service-card-page">
              <img src={DayCare} className="card-image" alt="Day Care" />
              <CardBody className="card-body">
                Day Care
              </CardBody>
            </Card>
          </Link>
        </div>
        <div className="card-row">
          <Link to="/petMating" className="card-link">
            <Card className="service-card-page">
              <img src={Mating} className="card-image" alt="Mating" />
              <CardBody className="card-body">
                Mating
              </CardBody>
            </Card>
          </Link>
          <Link to="/petPedia" className="card-link">
            <Card className="service-card-page">
              <img src={PetPedia} className="card-image" alt="Pet Pedia" />
              <CardBody className="card-body">
                Pet Pedia
              </CardBody>
            </Card>
          </Link>
          <Link to="/locationsForPets" className="card-link">
            <Card className="service-card-page">
              <img src={LocationforPets} className="card-image" alt="Location for Pets" />
              <CardBody className="card-body">
                Location for Pets
              </CardBody>
            </Card>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
