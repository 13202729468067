//After optimazation URL Bunny dont net url
const WordpresBACKEND_URL = "https://zaanvarcaching.b-cdn.net/api/";
// backend url befor optimization
const BACKEND_URL = "https://dev-api.zaanvar.com/api/";
// Local url
// const BACKEND_URL = "http://localhost:5000/api/";

// BACKEND_URL for Socket.IO connection
const SOCKET_SERVER_URL = "https://dev-api.zaanvar.com/";

export { BACKEND_URL, SOCKET_SERVER_URL,WordpresBACKEND_URL };
export const formatDate = (dateString) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};
