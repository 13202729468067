import React from "react";
import styles from './ui.module.css';

const DropDownv1 = ({ question, options, width = '45%', backgroundColor = 'rgba(243, 243, 243, 0.50)', value, onChange, CustomInputElementAddpet, error ,Customdropdown,customFlex}) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.value);
        }
    };
    const dynamicStyle = {
        flex: customFlex || (!Customdropdown && width === '45%' ? `1 1 ${width}` : ''),
        width: Customdropdown ? Customdropdown : width
    };

    return (
        <div
            className={styles.DropDownV1Div}
            style={dynamicStyle}        >
            <p style={{ color: CustomInputElementAddpet?.color }}>{question}</p>
            <select
                style={{ backgroundColor: backgroundColor, marginTop: "14px", padding: ".5rem .75rem" }}
                value={value} 
                onChange={handleChange}
            >
                {options?.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            {/* Show error message if value is 'select' or empty */}
            {(value === "select" || value === "") && error && (
    <span style={{ color: "red", fontSize: "0.875rem",justifyContent:"center",alignItems:"center",textAlign:"center",marginTop:"5px",marginLeft:"50px"}}> 
        {error}
    </span>
)}
        </div>
    );
};

export default DropDownv1;
