import React, { useEffect, useState } from 'react'
import styles from "./DairyEventForms.module.css"
import CustomizedSingleRadioButton from '../UI/CustomizedSingleRadioButton'
import { ImCancelCircle } from "react-icons/im";
import DairyEventForms from './DairyEventForms';
import MedicationForm from './MedicationForm';
import { WebApimanager } from '../../WebApiManager';
import VaccinationForms from "./VaccinationForms"
import AddFleaAndTickTreatmentForm from './AddFleaAndTickTreatmentForm';
import ExpenseForm from './ExpenseForm';
import AddDocumentForm from './AddDocumentForm';
import AddWeightTrackerForm from './AddWeightTrackerForm';
import AddDietaryNeedsForm from './AddDietaryNeedsForm';
import AddEventsForm from './AddEventsForm';
import AddOthersForm from './AddOthersForm';
import VetAppointmentForm from './VetAppointmentForm';
import GroomingForm from './GroomingForm';
import AddGrowthStageForm from './AddGrowthStageForm';

function formatDateToYYYYMMDD(dateObject) {
  if (!(dateObject instanceof Date) || isNaN(dateObject.getTime())) {
    throw new Error("Invalid Date Object");
  }

  return `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}`;
}
const MainForm = ({setShowCreateForm,selectedDate=""}) => {
  let webApi = new WebApimanager();


  const [formData, setFormData] = useState({
    dairyType:"",
    selectedPet:"",
    dailyEssentialsType:"",
    description:"",
    startDate:selectedDate?formatDateToYYYYMMDD(new Date(selectedDate)):"",
    startTime:"",
    toRemindDate:"",
    toRemindTime:"",
    remindNotification:"",
    peePoo:"",timeofDay:"",remindOption:"",
    dosageUnit:"",
    beforeAfter:"",
    vaccination:"",
    fleaAndTickTreatment:"",
    treatmentDate:selectedDate?formatDateToYYYYMMDD(new Date(selectedDate)):"",
    treatmentTime:"",
    expenseCategory:"",
    expenseAmount:"",
    choosenFile:"",
    docName:"",
    petAge:"",
    petWeight:"",
    supplements:"",
    fiber:"",
    vitAndMin:"",
    fats:"",
    carbohydrate:"",
    protienIntake:"",
    allergies:"",
    foodPreference:"",
    eventTitle:"",
    monthlyGrowth:[{ date: "", age: "", weight: "", size: "" }],
  })
  
  const [errors, setErrors] = useState({})
  const [allPetData,setAllPetData] = useState([])
  const [petObject, setPetObject] = useState({})
  
  const handlePopupClose = () =>{
    setShowCreateForm(false)
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMonthlyGrowthChange = (index, field, value) => {
    const updatedMonthlyGrowth = [...formData.monthlyGrowth];
    updatedMonthlyGrowth[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      monthlyGrowth: updatedMonthlyGrowth,
    }));
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors.monthlyGrowth && updatedErrors.monthlyGrowth[index]) {
        updatedErrors.monthlyGrowth[index][field] = ""; 
      }
      return updatedErrors;
    });
  };

  const fetchAllPetData = async() =>{
    try {
      const response = await webApi.get("petDiary/allPets")
      if(response.status === 200){
        setAllPetData(response.data.data)
      }
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    fetchAllPetData()
  },[])

  useEffect(()=>{
    const selectedPetObject = allPetData.find((petData)=>petData.petId._id === formData.selectedPet)
    console.log(selectedPetObject,"selectedPetObject")
    setPetObject(selectedPetObject)
  },[formData.selectedPet])
  useEffect(()=>{console.log(formData,"formData")},[formData])

  
  const validateForm = () => {
    let newErrors = {};
  
    if (!formData.dairyType) newErrors.dairyType = "Diary Type is required.";
    if (!formData.selectedPet) newErrors.selectedPet = "Pet selection is required.";
    
    switch (formData.dairyType) {
      case "Daily Essentials":
        if (!formData.dailyEssentialsType) newErrors.dailyEssentialsType = "Daily Essentials type is required.";
        if (!formData.startDate) newErrors.startDate = "Start Date is required.";
        if (!formData.startTime) newErrors.startTime = "Start Time is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (!formData.peePoo) newErrors.peePoo = "PeePoo Check is required.";
        if (!formData.remindOption) newErrors.remindOption = "Every Day Reminder  is required.";
        if (!formData.timeofDay) newErrors.timeofDay = "Choose time of the day.";
        break;
  
      case "Medication":
        if (!formData.timeofDay) newErrors.timeofDay = "Time of the day is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (!formData.dosageUnit) newErrors.dosageUnit = "dosageUnit is required.";
        if (!formData.beforeAfter) newErrors.beforeAfter = "beforeAfter is required.";
        if (!formData.startDate) newErrors.startDate = "Start Date is required.";
        if (!formData.startTime) newErrors.startTime = "Start Time is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
        break;
  
      case "Vaccination":
        if(!formData.vaccination) newErrors.vaccination ="Vaccination is required"
        if (!formData.timeofDay) newErrors.timeofDay = "Time of the day is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (!formData.startDate) newErrors.startDate = "Start Date is required.";
        if (!formData.startTime) newErrors.startTime = "Start Time is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
        break;
  
        case "Vet Appointment":
          if (!formData.timeofDay) newErrors.timeofDay = "Time of the day is required.";
          if (!formData.description) newErrors.description = "Description is required.";
          if (!formData.startDate) newErrors.startDate = "Start Date is required.";
          if (!formData.startTime) newErrors.startTime = "Start Time is required.";
          if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
          if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
          break;
      

      case "Flea & Tick Treatment":
        if (!formData.fleaAndTickTreatment) newErrors.fleaAndTickTreatment = "flea And Tick Treatment is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (!formData.treatmentDate) newErrors.treatmentDate = "Treatment Date  is required.";
        if (!formData.treatmentTime) newErrors.treatmentTime = "Treatment Time is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
        break;
      
        case "Documents":
        if (!formData.docName) newErrors.docName = "Document Name is required.";
        if (!formData.timeofDay) newErrors.timeofDay = "Time of the day is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        
        if (!formData.startDate) newErrors.startDate = "Start Date is required.";
        if (!formData.choosenFile ) newErrors.choosenFile = "choosen File is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
        break;

        case "Weight Tracker":
        if (!formData.petAge) newErrors.petAge = "Pet Age is required.";
        if (!formData.petWeight) newErrors.petWeight = "Pet Weight is required.";
        if (!formData.timeofDay) newErrors.timeofDay = "Time of the day is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        
        if (!formData.startDate) newErrors.startDate = "Start Date is required.";
        if (!formData.choosenFile ) newErrors.choosenFile = "choosen File is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
        break;

        case "Dietary Needs":
        if (!formData.foodPreference) newErrors.foodPreference = "Food Preference is required.";
        if (!formData.allergies) newErrors.allergies = "Allergies is required.";
        if (!formData.description) newErrors.description = "Description is required.";
        if (!formData.protienIntake) newErrors.protienIntake = "Protien Intake is required.";
        if (!formData.carbohydrate) newErrors.carbohydrate = "Carbohydrate is required.";
        if (!formData.fats) newErrors.fats = "Fats is required.";
        if (!formData.vitAndMin) newErrors.vitAndMin = "Vitamin And Minerals is required.";
        if (!formData.fiber) newErrors.fiber = "Fiber is required.";
        if (!formData.supplements) newErrors.supplements = "Supplements is required.";
        if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
        if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
        if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
        break;
        
        case "Pet Events":
          if (!formData.eventTitle) newErrors.eventTitle ="Event Tilte is required.";
          if (!formData.description) newErrors.description = "Description is required.";
          if (!formData.startDate) newErrors.startDate = "Event Date  is required.";
          if (!formData.startTime) newErrors.startTime = "Event Time is required.";
          if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
          if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
          break;

        case "Other":
          if (!formData.docName) newErrors.docName = "Title is required.";
          if (!formData.timeofDay) newErrors.timeofDay = "Time of the day is required.";
          if (!formData.description) newErrors.description = "Description is required.";
          
          if (!formData.startDate) newErrors.startDate = "Start Date is required.";
          if (!formData.choosenFile ) newErrors.choosenFile = "choosen File is required.";
          if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
          if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
          break;
        case "Pet Growthing":
          if (!formData.description) newErrors.description = "Description is required.";
          formData.monthlyGrowth.forEach((month, index) => {
            if (!month.date) newErrors[`monthlyGrowth.${index}.date`] = "Date is required.";
            if (!month.age) newErrors[`monthlyGrowth.${index}.age`] = "Age is required.";
            if (!month.weight) newErrors[`monthlyGrowth.${index}.weight`] = "Weight is required.";
            if (!month.size) newErrors[`monthlyGrowth.${index}.size`] = "Size is required.";
          });
          
          
          if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
          if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
          break;

          case "Grooming":
          if (!formData.description) newErrors.description = "Description is required.";
          
          if (!formData.startDate) newErrors.startDate = "Start Date is required.";
          if (!formData.startTime) newErrors.startTime = "Start Time is required.";
          if (!formData.remindNotification) newErrors.remindNotification = "Reminder Notification is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindDate) newErrors.toRemindDate = "Reminder Date is required.";
          if (formData.remindNotification==="yes" && !formData.toRemindTime) newErrors.toRemindTime = "Reminder Time is required.";
          if (!formData.remindOption) newErrors.remindOption = "Reminder is required.";
          break;
  
      default:
        break;
    }
  
    
    console.log(newErrors, "Errors");
    return newErrors;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
        const payload = {
            petId: formData.selectedPet,
            ...(formData.dairyType === "Daily Essentials" && {
              dailyEssentials: [
                {
                  DairyType: formData.dairyType,
                  typeOfDailyEssentials: formData.dailyEssentialsType,
                  timeOfTheDay: formData.timeofDay, 
                  description: formData.description,
                  date: formData.startDate,
                  duration: formData.startTime, 
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  peeAndPoopCheck: formData.peePoo === "pee"?"Pee":"Poo", 
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly"
                },
              ],
            }),
            ...(formData.dairyType === "Medication" && {
              medication: [
                {
                  DairyType: formData.dairyType,
                  timeOfTheDay: formData.timeofDay,
                  description: formData.description,
                  date:formData.startDate,
                  duration: formData.startTime,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  dosageUnit: formData.dosageUnit,
                  selectBeforeAfter: formData.beforeAfter
                },
              ],
            }),
            ...(formData.dairyType === "Vet Appointment" && {
              vetAppointment: [
                {
                  DairyType: formData.dairyType,
                  timeOfTheDay: formData.timeofDay,
                  description: formData.description,
                  date:formData.startDate,
                  duration: formData.startTime,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  
                },
              ],
            }),
            ...(formData.dairyType === "Vaccination" && {
              vaccination: [
                {
                  DairyType: formData.dairyType,
                  typeOfVaccination:formData.vaccination,
                  timeOfTheDay: formData.timeofDay,
                  description: formData.description,
                  date:formData.startDate,
                  duration: formData.startTime,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  
                },
              ],
            }),
            ...(formData.dairyType === "Flea & Tick Treatment" && {
              fleaAndTickTreatment:[
                {
                  DairyType: formData.dairyType,
                  enterFleaAndTickTreatmentTitle: formData.fleaAndTickTreatment,
                  description: formData.description,
                  treatmentDate: formData.treatmentDate,
                  treatmentTime: formData.treatmentTime,
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  
                  remindMeEveryDay: formData.remindOption === "monthly"?"Monthly":"Never",
                }
              ]
            }),
            ...(formData.dairyType === "Documents" && {
              documents: [
                {
                  DairyType: formData.dairyType,
                  nameOfTheDocument:formData.docName,
                  timeOfTheDay: formData.timeofDay,
                  description: formData.description,
                  date:formData.startDate,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  
                },
              ],
            }),
             ...(formData.dairyType === "Weight Tracker" && {
              weightTracker: [
                {
                  DairyType: formData.dairyType,
                  ageOfThePet:formData.petAge,
                  weightOfThePet:formData.petWeight,
                  timeOfTheDay: formData.timeofDay,
                  description: formData.description,
                  date:formData.startDate,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  
                },
              ],
            }),
            ...(formData.dairyType === "Dietary Needs" && {
              dietaryNeeds: [
                {
                  DairyType: formData.dairyType,
                  foodPreference: formData.foodPreference,
                  allergies: formData.allergies,
                  proteinIntake:formData.protienIntake,
                  carbohydrates: formData.carbohydrate,
                  fats: formData.fats,
                  vitaminsAndMinerals: formData.vitAndMin,
                  fiber: formData.fiber,
                  supplements: formData.supplements,
                  description: formData.description,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly",
                  
                },
              ],
            }),
            ...(formData.dairyType === "Pet Events" && {
              petEvents:[
                {
                  DairyType: formData.dairyType,
                  eventTitle: formData.eventTitle,
                  description: formData.description,
                  eventDate:formData.startDate,
                  eventTime: formData.startTime,
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  
                  remindMeEveryDay: formData.remindOption === "monthly"?"Monthly":"Never",
                }
              ]
            }),
            ...(formData.dairyType === "Other" && {
              other: [
                {
                  DairyType: formData.dairyType,
                  title:formData.docName,
                  timeOfTheDay: formData.timeofDay,
                  description: formData.description,
                  date:formData.startDate,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "daily"?"Daily":formData.remindOption === "dayByDay"?"Day By Day":formData.remindOption === 'weekly'?"Weekly":formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  
                },
              ],
            }),
            ...(formData.dairyType === "Grooming" && {
              grooming: [
                {
                  DairyType: formData.dairyType,
                  description: formData.description,
                  date:formData.startDate,
                  duration: formData.startTime,
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === formData.remindOption === "monthly"?"Monthly":formData.remindOption === "yearly"?"Yearly":"Half Yearly", 
                  
                },
              ],
            }),
            ...(formData.dairyType === "Pet Growthing" && {
              petGrowthing: [
                {
                  DairyType: formData.dairyType,
                  month:formData.monthlyGrowth,
                  description: formData.description,
                  
                  reminderNotification: formData.remindNotification === "yes"?"Yes":"No",
                  reminderNotification: formData.remindNotification=== "yes"?"Yes":"No" ,
                  ...(formData.remindNotification === "yes" && {
                    reminderDate: formData.toRemindDate,
                  reminderTime: formData.toRemindTime,
                  }),
                  remindMeEveryDay: formData.remindOption === "monthly"?"Monthly":"Never", 
                  
                },
              ],
            }),
          };
        const response = await webApi.post("petDiary",payload)
        if (response.status === 200){
            console.log(payload,"payload")
        }
        if(Object.keys(petObject).length && formData.choosenFile){
          const imageUpload = new FormData();
          imageUpload.append("image", formData.choosenFile);
          for(const pair of imageUpload){
            console.log(pair,"pair")
          }
          let Url=""
          if(formData.dairyType === "Documents"){
          Url=`petDiary/updateImage?petDiaryId=${petObject._id}&objectId=${petObject.documents[petObject.documents.length-1]._id}&type=documents`
          }
          else if(formData.dairyType === "Weight Tracker"){
          Url=`petDiary/updateImage?petDiaryId=${petObject._id}&objectId=${petObject.weightTracker[petObject.weightTracker.length-1]._id}&type=weightTracker`
          
          } else if(formData.dairyType === "Other"){
            Url=`petDiary/updateImage?petDiaryId=${petObject._id}&objectId=${petObject.other[petObject.other.length-1]._id}&type=other`
          }
          const imageResponse = await webApi.imagePut(Url,imageUpload)
          if(imageResponse.status === 200){
            for(const pair of imageUpload){
              console.log(pair,"pair")
            }
          }
        }
    } catch (error) {
        
    }
  };
  

  
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupForm}>
        <h2 className={styles.formTitle}>
          {!formData.dairyType || formData.dairyType === "Daily Essentials"?"Add Dairy Event":`Add ${formData.dairyType}`}
          <ImCancelCircle
            style={{ position: "absolute", right: "10px", top: "20px", fontSize: "20px", cursor: "pointer" }}
            onClick={handlePopupClose}
          />
        </h2>
        <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.SelectFieldContainer}>
            <label>Select Diary Type</label>
              <select name="dairyType" id="" value={formData.dairyType} onChange={handleInputChange}>
                <option value="">Select Dairy type</option>
                <option value="Daily Essentials">Daily Essentials</option>
                <option value="Medication">Medication</option>
                <option value="Vaccination">Vaccination</option>
                <option value="Flea & Tick Treatment">Flea & Tick Treatment</option>
                <option value="Vet Appointment">Vet Appointment</option>
                <option value="Expenses">Expenses</option>
                <option value="Grooming">Grooming</option>
                <option value="Documents">Documents</option>
                <option value="Weight Tracker">Weight Tracker</option>
                <option value="Pet Growthing">Pet Growthing</option>
                <option value="Pet Events">Pet Events</option>
                <option value="Dietary Needs">Dietary Needs</option>
                <option value="Other">Other</option>
              </select>
            {errors.dairyType && <span className={styles.errorText}>{errors.dairyType}</span>}
          </div>
          <div className={styles.SelectFieldContainer}>
            <label>Select Pet Type</label>
            <select name="selectedPet" value={formData.selectedPet} onChange={handleInputChange}>
              <option value="">Select Pet</option>
              {allPetData.length && allPetData.map((petData)=>(<option value={petData.petId._id}>{petData.petId._id}</option>))}

            </select>
            {errors.selectedPet && <span className={styles.errorText}>{errors.selectedPet}</span>}
          </div>
          {(!formData.dairyType || formData.dairyType === "Daily Essentials") && (
            <DairyEventForms
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Medication") && (
            <MedicationForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Vaccination") && (
            <VaccinationForms
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Flea & Tick Treatment") && (
            <AddFleaAndTickTreatmentForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Expenses") && (
            <ExpenseForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Documents") && (
            <AddDocumentForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Weight Tracker") && (
            <AddWeightTrackerForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Dietary Needs") && (
            <AddDietaryNeedsForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Pet Events") && (
            <AddEventsForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Other") && (
            <AddOthersForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          {( formData.dairyType === "Vet Appointment") && (
            <VetAppointmentForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
           {( formData.dairyType === "Grooming") && (
            <GroomingForm
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
           {( formData.dairyType === "Pet Growthing") && (
            <AddGrowthStageForm
              formData={formData}
              handleMonthlyGrowthChange={handleMonthlyGrowthChange}
              setFormData={setFormData}
              setErrors={setErrors}
              errors={errors}
            />
          )}
          <div className={styles.BtnContainer}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MainForm