import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import notificationSound from "./HoverProfile/WhatsApp Audio 2024-12-14 at 17.14.53_a895e4fa.mp3";

const NotificationSocialMedia = () => {
  // Default to empty array if notifications are undefined
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [imageUrl, setImageUrl] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const fetchSocialMediaNotificationData = async () => {
    try {
      const response = await webApi.get(
        "notification/all?department=Social%20Media"
      );
      const notifications = response.data?.data?.notifications || [];
      setNotificationData(notifications);
      const unread = notifications.filter((notif) => !notif.isRead).length;
      setUnreadCount(unread);
    } catch (error) {
      console.log("server error", error);
    }
  };

  useEffect(() => {
    fetchSocialMediaNotificationData();
  }, []);

  const formatInstagramTime = (timeStamp) => {
    const currentTime = new Date();
    const postTime = new Date(timeStamp);
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) {
      return "Just now";
    } else if (minutes < 60) {
      return `${minutes}m ago`;
    } else if (hours < 24) {
      return `${hours}h ago`;
    } else {
      return `${days}d ago`;
    }
  };

  const userId = currentUserInfo._id;

  const addFollower = async (userId) => {
    if (!userId) {
      alert("User not found!");
      return;
    }

    try {
      console.log("userId", userId);

      await webApi.put(`socialMedia/addFollower/${userId}`);
      // fetchSocialMediaData();
    } catch (error) {
      console.error("Error following user:", error);
      alert("Failed to follow user. Please try again.");
    }
  };

  const removeFollower = async (userId) => {
    if (!userId) {
      alert("User not found!");
      return;
    }

    try {
      await webApi.delete(`socialMedia/unFollow/${userId}`);
      // fetchSocialMediaData();
    } catch (error) {
      console.error("Error unfollowing user:", error);
      alert("Failed to unfollow user. Please try again.");
    }
  };

  return (
    <div>
      <div className="socialmedia-notification-header">
        <div>
          <span className="notify-like-title">Likes</span>
          {notificationData.length > 0 ? (
            notificationData
              .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
              .map((notification, index) => (
                <div
                  key={index}
                  className="notify-likes-header"
                  onMouseEnter={() => {
                    if (!notification.isRead) {
                      // markAsRead(notification._id);
                    }
                  }}
                >
                  <div>
                    <img
                      src={`${BACKEND_URL}${
                        notification.sentBy?.profile ||
                        "default-profile-pic.jpg"
                      }`}
                      alt="icon"
                      className="notyfy-like-image"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                  <div className="notify-like-header">
                    <strong className="notify-like-text">
                      {notification.sentBy?.name || "Unknown User"}{" "}
                    </strong>
                    <span className="notify-like-sub-text">
                      {notification.message} (
                      {formatInstagramTime(notification.createdDate)})
                    </span>

                    {notification.status === "Follow Request" ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          style={{
                            padding: "5px 15px",
                            fontSize: "14px",
                            borderRadius: "20px",
                            border: "1px solid #0095f6",
                            backgroundColor: "#0095f6",
                            color: "#ffffff",
                            cursor: "pointer",
                          }}
                          onClick={() => addFollower(notification.userId._id)}
                        >
                          Accept
                        </button>
                        <button
                          style={{
                            padding: "5px 15px",
                            fontSize: "14px",
                            borderRadius: "20px",
                            border: "1px solid #efefef",
                            backgroundColor: "#ffffff",
                            color: "#333333",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            removeFollower(notification.userId._id)
                          }
                        >
                          Reject
                        </button>
                      </div>
                    ) : notification.status === "Like" ||
                      notification.status === "Comment" ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            borderRadius: "20px",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className="fa fa-heart"
                            style={{
                              color:
                                notification.status === "Like" ? "red" : "gray",
                            }}
                          ></i>

                          {imageUrl && (
                            <img
                              src={imageUrl}
                              alt="social media post"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          )}
                        </button>
                      </div>
                    ) : (
                      <button
                        style={{
                          padding: "5px 15px",
                          fontSize: "14px",
                          borderRadius: "20px",
                          border:
                            notification.status === "Follow"
                              ? "1px solid #0095f6"
                              : "none",
                          backgroundColor:
                            notification.status === "Follow"
                              ? "transparent"
                              : "#efefef",
                          color:
                            notification.status === "Follow"
                              ? "#0095f6"
                              : "#ffffff",
                          cursor: "pointer",
                        }}
                      >
                        {notification.status}
                      </button>
                    )}
                  </div>
                </div>
              ))
          ) : (
            <p>No notifications available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationSocialMedia;
