import React, { useEffect, useState } from "react";
import LeftImage from "../../assets/images/right.png";
import RightImage from "../../assets/images/left.png";
import "./hero.css";
import styles from "./Hero.module.css";
// import AboutUs from "../About/AboutUs";
// import OurServices from '../OurServices/Services'
import Services from "../OurServices/Services";
import BookingSteps from "../BookingSteps/BookingSteps";
import Testimonials from "../Testimonials/Testimonials";
import Community from "../Community/Community";
import ServiceFeatures from "../Header/ServiceFeatures";
import { Link } from "react-router-dom";
import { WebApimanager } from "../../WebApiManager";

import axios from "axios";
import NewServices from "../OurServices/NewServices";
import Categories from "../Blog/Categories/Categories";

const BASE_URL = "https://dev-wp-api.zaanvar.com/wp-json/wp/v2";

const Hero = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  const webApi = new WebApimanager();
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await webApi.getwordpressData(`blogs`);
        const blog = Array.isArray(response?.data) ? response.data : [];

        setBlogData(blog);
      } catch (error) {
        console.error("Server error:", error);
      }
    };

    fetchBlogData();
  }, []);

  return (
    <>
      {/* <section className={styles.hero}>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90b3a81e35eb677cb823dca56a972f6b821ac6db2570e8fe4ab08d65fd11f58?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" alt="Hero background" className={styles.heroImage} />
      </section> */}
      <section className={styles.hero}>
        <div className={styles.heroContent}></div>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c90b3a81e35eb677cb823dca56a972f6b821ac6db2570e8fe4ab08d65fd11f58?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
          alt="Happy pets"
          className={styles.heroImage}
        />
      </section>

      <div
        className="container-fluid p-5"
        style={{ backgroundColor: "#F6F9FE" }}
      >
        <div className="">
          <div className="mb-4">
            <span className={styles.ourblogname}>Our Blogs</span>
          </div>
          <div className={`row gy-4 ${styles["homeblogrow"]}`}>
            {blogData.slice(0, 6).map((post) => (
              <div
                className={`col-lg-3 col-md-4 col-sm-12 ${styles["home-blog-cards"]}`}
                key={post.id}
              >
                <div
                  className="h-100 bg-white"
                  style={{
                    boxShadow: "0px 0px 10px 0px #0000000D",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <Link
                    to={`/${post.slug}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={post.featuredImage}
                      alt={post.title}
                      className="card-img-top"
                      style={{ height: "200px", objectFit: "cover" }}
                    />
                    <div
                      style={{
                        padding: "15px",
                      }}
                    >
                      <h5>{post.title.split("").slice(0, 15).join("")}</h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <p className="mb-0 me-3 border-end pe-3">
                          {post.categories[0] || "Uncategorized"}
                        </p>
                        <p className="mb-0">
                          {new Date(post.date).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <NewServices />

      {/* <Services /> */}

      {/* Acout us  */}
      <div
        className="container-fluid p-5"
        style={{ backgroundColor: "#F6F9FE" }}
      >
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="image-container">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e867030cb55573913ed76c38716629e45525c09002b151896920846882ec4608?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                  alt="Founder"
                  className="image-fluid image-back"
                />
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/75aa2133a28f79bdcc3b7ac24e7053c7b75ecf4f75f14ed54f0a5a378c84d2db?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                  alt="Dog"
                  className="image-fluid image-front"
                />
              </div>
            </div>
            <div className="col-md-6 home-about-container">
              <span className="home-about-name">About Us</span>
              <div className="home-about-dec">
                <p>
                  Zaanvar is a chain of pet clinics that provide top-notch
                  quality pet care services in India to solve pet health
                  problems that arise due to appalling management of pets and
                  the wrong treatment given by unqualified and inexperienced
                  persons.
                </p>
                <p>
                  We are one of the most trusted veterinary services providers
                  in India with a motto to make these services accessible not
                  only in urban but rural areas as well and to make it easier
                  and comfortable for pet parents to take care of their pets. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AboutUs/> */}

      {/* <BookingSteps/> */}
      {/* <Testimonials/> */}
      {/* <Community /> */}
    </>
  );
};

export default Hero;
