import React, { useState, useEffect,useRef } from "react";
import popupimg1 from "../../../assets/images/popup-img.png";
import popupimg2 from "../../../assets/images/popup-img2.png";
import popupimg3 from "../../../assets/images/gallary.png";
import close from "../../../assets/images/clsoe-popup.png";
import { WebApimanager } from "../../../WebApiManager";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import "./MyPost.css";

const LostPopup = ({ isOpen, onClose }) => {
  const webApi = new WebApimanager();
  const [errorMessage, setErrorMessage] = useState("");

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

 
  const [formData, setFormData] = useState({
    typeOfPet: "",
    breedName: "",
    petGender: "",
    foundDate: "",
    foundLocation: "",
    email: "",
    mobileNumber: "",
    emergencyMobileNumber: "",
    petName: "",
    age: "",
    identification: "",
    petImage: null,
    color:"",
    currentPetAddress:"",
  });
  const [breedOptions, setBreedOptions] = useState([]);

  const getDogTypes = async () => {
    try {
      const dogResponse = await webApi.get("dogAndCat/dogTypes");
      if (dogResponse.status === 200) {
        const breedNames = dogResponse.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const getcatTypes = async () => {
    try {
      const dogResponse = await webApi.get("dogAndCat/catTypes");

      if (dogResponse.status === 200) {
        const breedNames = dogResponse.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  

  const handleChange = async (e) => {
    const { name, value, type, files } = e.target;
      

    if (type === "file") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [name]: value };
        if (name === "typeOfPet") {
          if (value === "Dog") {
            const dogTypesList = getDogTypes().catch((error) =>
             
              console.error("Error fetching dog breeds:", error)
            
            );
          } else if (value === "Cat") {
            const catTypesList = getcatTypes();
          } else {
            setBreedOptions([]); 
          }
        }

        return updatedFormData;
      });
    }

  };


 
  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "age") {
      // Reject letters and enforce numeric only
      if (/[^0-9]/.test(value)) {
        e.preventDefault(); // Prevent typing non-numeric characters
      }
    }
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, petImage: e.target.files[0] });
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      age,
      petName,
      typeOfPet,
      breedName,
      foundDate,
      foundLocation,
      email,
      mobileNumber,
      emergencyMobileNumber,
      identification,
      petGender,
      color,
      currentPetAddress,
    } = formData;


    const payload = {
      apetAge: age,
      petName: petName,
      petType: typeOfPet,
      color: color,
      breed: breedName,
      foundDate: foundDate,
      foundLocation: foundLocation,
      email: email,
      mobileNumber: mobileNumber,
      emergencyMobileNumber: emergencyMobileNumber,
   
      petGender:petGender,
      identification: identification,
      currentPetAddress:currentPetAddress,
     
    };
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    const payloadimg={
      petImage:formData.petImage
    }

    try {
      const response = await webApi.post("lostAndFound/found", payload);
      const petId = response.data.data.foundPet._id;
    
      if (formData.petImage) {
       
        const imageUploadResponse = await webApi.imagePut(
          `lostAndFound/updatePetImageInFound/${petId}`,
          payloadimg
        );
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }

      setFormData({
        typeOfPet: "",
        breedName: "",
        gender: "",
        foundDate: "",
        foundLocation: "",
        email: "",
        mobileNumber: "",
        emergencyMobileNumber: "",
        petName: "",
        age: "",
        identification: "",
        petImage: null,
        color:"",
        currentPetAddress:"",
      });
      window.location.reload();
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };



  const resetFormData = () => {
    setFormData({
      typeOfPet: "",
      breedName: "",
      petGender: "",
      foundDate: "",
      foundLocation: "",
      email: "",
      mobileNumber: "",
      emergencyMobileNumber: "",
      petName: "",
      age: "",
      identification: "",
      petImage: null,
      color:"",
      currentPetAddress:"",
    });
  };

  const handleClose = () => {
    resetFormData(); 
    onClose();       
  };

  if (!isOpen) return null;
  
     
  return (
    <>
      <ToastContainer />

    <section>
      <div className="lost-and-found-Lost-popup-overlay">
        <div className="i">
          <div className="lost-and-found-popup-rightimg">
            <img src={popupimg1} alt="close" />
          </div>
          <div className="lost-and-found-popup-middle-text">
            Find a Report
            <div style={{ marginTop: "5px" }}>Found Pets</div>
          </div>
          <div className="lost-and-found-popup-leftimg">
            {" "}
            <img src={popupimg2} alt="close" />{" "}
          </div>
        </div>

        <div className="lost-and-found-Lost-popup-content">
          <div className="lost-and-found-found-popup-hearder">
            <div className="lost-and-found-popup-select-lost">
              {" "}
              <button
                className="lost-and-found-post-popup-close"
                onClick={handleClose}
              >
                <img src={close} alt="Close" />
              </button>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            style={{
              paddingBottom: "40px",
              paddingRight: "30px",
              paddingTop: "50px",
              paddingLeft: "50px",
            }}
          >
            <table
              style={{
                overflowY: "scroll",
                width: "100%",
                tableLayout: "fixed",
                paddingTop: "50px",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{ width: "50%", textAlign: "left",position:"relative" }}
                    className="lost-and-found-td"
                  >
                    Type of Pet <span style={{ position: "absolute", // Make the span absolutely positioned
      top: "20",            // Position it at the top
      left: "80px", color:"red"}}>*</span>&nbsp;   :
                  </td>

                  <td
                    style={{ width: "50%", textAlign: "left",position:"relative" }}
                    className="lost-and-found-td"
                  >
                    Breed Name<span style={{ position: "absolute", // Make the span absolutely positioned
      top: "20",            // Position it at the top
      left: "90px", color:"red"}}>* </span>&nbsp;&nbsp;   :
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingRight: "20px",
                      paddingTop: "0px",
                      paddingBottom: "6px",
                    }}
                  >
                    <select
                      name="typeOfPet"
                      value={formData.typeOfPet}
                      required
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                        padding:"0px 0px 0px 10px" 
                      }}
                      className="a"
                    >
                      <option value="" style={{ backgroundColor: "#F3F3F380",borderRadius:"0px"}}>Select Type</option>
                      <option value="Dog">Dog</option>
                      <option value="Cat">Cat</option>
                      <option value="other">Other</option>
                    </select>
                  </td>
                  <td
                    style={{
                      paddingRight: "20px",
                      paddingBottom: "6px",
                    }}
                  >
                    {formData.typeOfPet === "Dog" ||
                    formData.typeOfPet === "Cat" ? (
                      <select
                        name="breedName"
                        value={formData.breedName}
                        onChange={handleChange}
                        required
                        style={{
                          height: "30px",
                          backgroundColor: "#F3F3F380",
                          border: "1px solid #D9D9D9",
                          padding:"0px 0px 0px 10px" 

                        }}
                      >
                        <option value="">Select Breed</option>
                        {breedOptions.map((breed) => (
                          <option key={breed} value={breed}>
                            {breed}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        name="breedName"
                        value={formData.breedName}
                        onChange={handleChange}
                        placeholder="Enter Breed Name"
                        style={{
                          height: "30px",
                          backgroundColor: "#F3F3F380",
                          border: "1px solid #D9D9D9",
                        }}
                        required
                      />
                    )}
                  </td>
                </tr>

                <tr>
                  <td className="lost-and-found-td" style={{    paddingBottom: "8px",position:"relative"}}>Gender <span style={{ position: "absolute", // Make the span absolutely positioned
      top: "20",            // Position it at the top
      left: "55.5px", color:"red"}}>*</span>&nbsp; :</td>

                  <td className="lost-and-found-td"  style={{    paddingBottom: "8px",position:"relative"}}>Found Date <span style={{ position: "absolute", // Make the span absolutely positioned
      top: "20",            // Position it at the top
      left: "83px", color:"red"}}>*</span>&nbsp; :</td>
                </tr>
                <tr>
                  <td
                    style={{
                      paddingRight: "20px",
                      paddingTop: "8px",
                      paddingBottom: "20px",
                    }}
                  >
                    <select
                      name="petGender"
                      value={formData.petGender}
                      onChange={handleChange}
                      required
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                        padding:"0px 0px 0px 10px" 

                      }}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "12px",}}>
                    <input
                      type="date"
                      name="foundDate"
                      value={formData.foundDate}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative" }}>
                    Found Location <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "109px", color:"red"}}>*</span>&nbsp;  :
                  </td>

                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative" }}>
                    Email <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "42px", color:"red",top: "-2px"}}>*</span>&nbsp;  :
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="foundLocation"
                      value={formData.foundLocation}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>

                <tr>
                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative"}}>
                    Mobile Number <span style={{ position: "absolute", // Make the span absolutely positioned
      top: "20",            // Position it at the top
      left: "110px", color:"red"}}>*</span>&nbsp;  :
                  </td>

                  <td className="lost-and-found-td" style={{paddingBottom: "12px",position:"relative" }}>
                    Emergency Mobile Number:
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={(e)=>{
                        const val= e.target.value
                        if(!isNaN(val) && val.length>=0 && val.length<=10){
                            handleChange(e)
                        }
                      }}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="emergencyMobileNumber"
                      value={formData.emergencyMobileNumber}
                      onChange={(e)=>{
                        const val= e.target.value
                        if(!isNaN(val) && val.length>=0 && val.length<=10){
                            handleChange(e)
                        }
                      }}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
        
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative" }}>
                    Name of the Pet <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "115px", color:"red"}}>*</span>&nbsp;  :
                  </td>

                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative" }}>
                    Age <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "31px", color:"red"}}>*</span>&nbsp;  :
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="petName"
                      value={formData.petName}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                  <input
              type="text" 
              name="age"
              value={formData.age}
              onChange={(e)=>{
                const val= e.target.value
                if(!isNaN(val) && val.length>=0 && val.length<=3){
                    handleChange(e)
                }
              }}
              onInput={handleInput} 
              maxLength={3}
              style={{
                height: "30px",
                backgroundColor: "#F3F3F380",
                border: "1px solid #D9D9D9",
              }}
              
              required
            />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative" }}>
                    Color <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "40px", color:"red"}}>*</span>&nbsp;  :
                  </td>

                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative" }}>
                    Current Pet Address <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "143px", color:"red"}}>*</span>&nbsp;  :
                  </td>
                </tr>
                <tr>
                <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="color"
                      value={formData.color}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                  <td style={{ paddingRight: "20px", paddingBottom: "20px" }}>
                    <input
                      type="text"
                      name="currentPetAddress"
                      value={formData.currentPetAddress}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{paddingBottom: "12px",position:"relative" }}>
                    Identification <span style={{ position: "absolute", // Make the span absolutely positioned
      left: "95px", color:"red"}}>*</span>&nbsp;  :
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="3"
                    style={{ paddingRight: "19px", paddingBottom: "20px" }}
                  >
                    <input
                      type="text"
                      name="identification"
                      value={formData.identification}
                      onChange={handleChange}
                      style={{
                        height: "30px",
                        backgroundColor: "#F3F3F380",
                        border: "1px solid #D9D9D9",
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="lost-and-found-td" style={{ paddingBottom: "12px",position:"relative"}}>
                    Upload Picture of the Pet <span style={{ position: "absolute",left: "175px", color:"red"}}>*</span>&nbsp;  :
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ display: "flex" }}>
                      <input
                        type="file"
                        name="petImage"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{
                          width: "385px",
                          height: "80px",
                          backgroundColor: "#F3F3F3",
                          border: "1px solid #D9D9D9",
                          position: "relative",
                          backgroundImage: `url(${popupimg3})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          cursor: "pointer",
                        }}
                        id="fileInput"
                        required
                      />
                    </div>
                  </td>
                  <td>
            {/* Display the selected file's name */}
            {formData.petImage ? (
              <div style={{display:"flex"}}>
                <p style={{ display:"flex",
                          fontStyle: "normal",
                         
                          color: "#727271",
                          fontSize: "12px",
                          lineHeight: "normal",
                          fontWeight: "400",
                          justifyContent:"center",
                          textAlign:"center",
                          alignItems:"center",
                          }}>Selected File: {formData.petImage.name}</p>
                {formData.petImage.type.startsWith("image/") && (
                  <img
                    src={URL.createObjectURL(formData.petImage)}
                    alt="Selected"
                    style={{ width: "100px", height: "90px", marginLeft:"10px" }}
                  />
                )}
              </div>
            ) : (
              <p style={{color:"red",fontSize: "12px",
                lineHeight: "normal",
                fontWeight: "400",}}>No file selected</p>
            )}
          </td>
                </tr>
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="lost-and-found-popup-button2"
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </section>
    </>
  );
};

export default LostPopup;
