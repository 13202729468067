import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NewServices.css";

// Import images
import walker from "../../assets/services/Walker.png";
import adopt from "../../assets/services/Adopt.png";
import bloodbank from "../../assets/services/Blood bank.png";
import Breeds from "../../assets/services/Breeds.png";
import clnic from "../../assets/services/clinic.png";
import cremation from "../../assets/services/Cremation.png";
import daycare from "../../assets/services/Daycare.png";
import ereport from "../../assets/services/E-Report.png";
import events from "../../assets/services/Events.png";
import grooming from "../../assets/services/Grooming.png";
import insurance from "../../assets/services/Insurance.png";
import location from "../../assets/services/Location.png";
import lostandfound from "../../assets/services/Lost & Found.png";
import matting from "../../assets/services/Matting.png";
import nutrition from "../../assets/services/Nutrition.png";
import ride from "../../assets/services/Ride.png";
import tailgram from "../../assets/services/Tailgram.png";
import tailtalk from "../../assets/services/Tailtalk.png";
import tinder from "../../assets/services/Tinder.png";
import training from "../../assets/services/Training.png";

// Array of services with images
const services = [
  { image: walker, title: "Walker" },
  { image: adopt, title: "Adopt" },
  { image: bloodbank, title: "Blood Bank" },
  { image: Breeds, title: "Breeds" },
  { image: clnic, title: "Clinic" },
  { image: cremation, title: "Cremation" },
  { image: daycare, title: "Daycare" },
  { image: ereport, title: "E-Report" },
  { image: events, title: "Events" },
  { image: grooming, title: "Grooming" },
  { image: insurance, title: "Insurance" },
  { image: location, title: "Location" },
  { image: lostandfound, title: "Lost & Found" },
  { image: matting, title: "Matting" },
  { image: nutrition, title: "Nutrition" },
  { image: ride, title: "Ride" },
  { image: tailgram, title: "Tailgram" },
  { image: tailtalk, title: "Tailtalk" },
  { image: tinder, title: "Tinder" },
  { image: training, title: "Training" },
];

const NewServices = () => {
  return (
    <div
      className="container-fluid p-5"
      style={{ backgroundColor: "#F6F9FE", marginTop: "-50px" }}
    >
      <span className="Our-service-title">Upcoming Services</span>
      <div className="row mt-3">
        {services.map((service, index) => (
          <div key={index} className="col-3 col-md-3 col-lg-2 mb-4">
            <div>
              <div>
                <img
                  src={service.image}
                  alt={service.title}
                  className="service-card-img img-fluid custom-img"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewServices;
