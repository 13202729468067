import { ProfileIcon } from "../../assets/SVG"



export const petMenu = [
    {
      name: "Dogs",
      image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F8bf6fc78b9564227bbd04cfb20fe7a38",
      onClick: "",
    },
    {
      name: "Cats",
      image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F7b0af6d296624f64bedc4cfaf54e1573",
      onClick: "",
    },
    // {
    //   name: "Rabbit",
    //   image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fbf3fb9d04b6c4fa9a4c87fe75fa2f161",
    //   onClick: "",
    // },
    {
      name: "Others",
      image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fd478f65a4b6d43aab66fb3d17a9c590a",
      onClick: "",
    },
]

export const infoMenu = [
    {
        title: "Wide Selection of Breeds",
        text: "Zaanvar offers a diverse range of breeds, ensuring that you can find the appropriate match for your pet.",
    },
    {
        title: "Comprehensive Pet Directory",
        text: "Zaanvar provides a comprehensive database of breeders and pet owners, enabling you to quickly find the suitable match for your pet.",
    },
    {
        title: "User-Friendly Interface",
        text: "Zaanvar is designed with a user-friendly interface, facilitating easy navigation and efficient access to relevant information.",
    },
    {
        title: "Advanced Search Functionality",
        text: "Zaanvar offers advanced search functionality, allowing you to search for pets based on specific criteria such as breed, location, age, and more.",
    },
    {
        title: "Detailed Pet Profiles",
        text: "Zaanvar provides detailed profiles for each pet, including information such as photos, health records, personality traits, and more, to assist you in making informed decisions when buying, adopting, or mating your pet.",
    },
    {
        title: "Personalized Recommendations",
        text: "Zaanvar offers customized recommendations based on each pet’s profile, making it easier for you to find the perfect match.",
    },
]

export const infoMenu2 = [
    {
        title: "Add Your Pet",
        text: "Read verified reviews by pet owners like you and choose a sitter who’s a great.",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F345e40ca4509490aaf55018ac1c11e7f",
    },
    {
        title: "Select a Match",
        text: "Read verified reviews by pet owners like you and choose a sitter who’s a great.",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fa290c190efca43fbb8d8c3c5d0d67626",
    },
    {
        title: "Communicate",
        text: "Read verified reviews by pet owners like you and choose a sitter who’s a great.",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F6928d372c1454c96adc239bf3170db48",
    },
]


export const faq = [
    {
        question: "What is the recommended frequency of grooming for my pet?",
        answer: [
            {
                
                text: "The required frequency of grooming varies depending on pet type and breed, for cats bathing is not required on a regular basis however hair brushing for cats with furry coats needs to be done 2-3 times a week. A professional dog grooming is required once in 2 to 4 weeks based on breed, coat length, and texture. Brushing is required daily for long-haired dogs, 2-3 times a week for medium-haired dogs, and once a week for short-haired dogs. Regular professional grooming helps regulate body oils and clean deeply all the difficult-to-reach areas like inside the ears, between paw fingers, underbelly, etc.",
            },
            // {
            //     title: "Pets for Adoption:",
            //     text: "Pet owners can advertise their pets for adoption and communicate with potential adopters to facilitate the process.",
            // },
            // {
            //     title: "Pets for Sale:",
            //     text: "Pet breeders and owners can list their pets for sale, allowing prospective buyers to connect with them.",
            // },
        ],
        visible: true,
        id: 1,
    },
    {
        question: "How much time does a pet groomer take for at-home pet grooming in Hyderabad?",
        answer: [
            {
                title: "Pet Mate Finder:",
                text: "Zaanvar has a feature that enables users to find compatible pets for mating based on breed, size, and activity level.",
            },
            {
                title: "Pets for Adoption:",
                text: "Pet owners can advertise their pets for adoption and communicate with potential adopters to facilitate the process.",
            },
            {
                title: "Pets for Sale:",
                text: "Pet breeders and owners can list their pets for sale, allowing prospective buyers to connect with them.",
            },
        ],
        visible: false,
        id: 2,
    },
    {
        question: "What is Zaanvar Found & Loss Success Rate?",
        answer: [
            {
                title: "Pet Mate Finder:",
                text: "Zaanvar has a feature that enables users to find compatible pets for mating based on breed, size, and activity level.",
            },
            {
                title: "Pets for Adoption:",
                text: "Pet owners can advertise their pets for adoption and communicate with potential adopters to facilitate the process.",
            },
            {
                title: "Pets for Sale:",
                text: "Pet breeders and owners can list their pets for sale, allowing prospective buyers to connect with them.",
            },
        ],
        visible: false,
        id: 3,
    },
]

export const petInfo = [
    {
        id: 0,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F90dafa812ae74ec698ca8e2770f8b139"
    },
    {
        id: 1,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F369db48837094838864bb3c0dfd70e66"
    },
]

export const petInfoForMatches = [
    {
        id: 0,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F90dafa812ae74ec698ca8e2770f8b139"
    },
    {
        id: 1,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F369db48837094838864bb3c0dfd70e66"
    },
    {
        id: 2,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F90dafa812ae74ec698ca8e2770f8b139"
    },
    {
        id: 3,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F369db48837094838864bb3c0dfd70e66"
    },
    {
        id: 4,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F369db48837094838864bb3c0dfd70e66"
    },
    {
        id: 5,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fdd86d64460a84da09ba486277cbfa983"
    },
    {
        id: 6,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F369db48837094838864bb3c0dfd70e66"
    },
    {
        id: 7,
        name: "VICTORIA",
        breed: "Rottweiler, 2 Years 8 Months, Female",
        location: "Ameerpet, Hyderabad", 
        profileCompletion: 30,
        matches: "69",
        image: "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fdd86d64460a84da09ba486277cbfa983"
    },
]

export const NC = [
    {
        heading: 'Boost Your Pet’s Profile!',
        text: `Boost your pet's profile by obtaining a "verified" tag through the profile verification process.`,
        status: 'completed',
    },
    {
        heading: 'Enable “Pedigree Certified” Tag',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitursed efficitur sem, ac iaculis eros.',
        status: 'completed',
    },
    {
        heading: 'Enable “DNA Tested” Tag',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitursed efficitur sem, ac iaculis eros.',
        status: 'completed',
    },
    {
        heading: 'Pet profile Information',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitursed efficitur sem, ac iaculis eros.',
        status: 'pending',
    },
    {
        heading: 'Detailed profile Description',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitursed efficitur sem, ac iaculis eros.',
        status: 'pending',
    },
    {
        heading: 'Add more photos of your pet',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitursed efficitur sem, ac iaculis eros.',
        status: 'pending',
    },
    {
        heading: 'Add more videos of your pet',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitursed efficitur sem, ac iaculis eros.',
        status: 'pending',
    },
]

export const ChatMenu = [
    {
        name: 'Ranjith',
        message: 'hii how are you',
        lastSeen: '10:00am',
        image: <ProfileIcon />,
        messages: [
            {
                type: 'recieved',
                content: 'hii how are you',
                time: '10:00am',
                status: 'seen'
            },
            {
                type: 'sent',
                content: 'hii',
                time: '10:00am',
                status: 'seen'
            },
        ]
    },
    {
        name: 'Rahul',
        message: 'Hi....',
        lastSeen: 'Yesterday',
        image: <ProfileIcon />,
        messages: [
            {
                type: 'recieved',
                content: 'hii how are you',
                time: '10:00am',
                status: 'seen'
            },
            {
                type: 'sent',
                content: 'hii',
                time: '10:00am',
                status: 'seen'
            },
        ]
    },
    {
        name: 'Rahul',
        message: 'hi....',
        lastSeen: '1 week ago',
        image: <ProfileIcon />,
        messages: [
            {
                type: 'recieved',
                content: 'hii',
                time: '10:00am',
                status: 'seen'
            },
            {
                type: 'sent',
                content: 'hii how are you',
                time: '10:00am',
                status: 'seen'
            },
        ]
    },
]