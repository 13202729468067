import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom/dist";
import Home from "./Components/Home";
// import AboutUs from "./Components/About";
import ContactUs from "./Components/Contact";
import Navbar from "./Components/Navbar/Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import CustomerSignupin from "./Components/CustomerSignupin";
import CustomerDashboard from "./Pages/Customer/CustomerDashboard";
import DoctorAppointment from "./Pages/Customer/Components/Services/DoctorAppointment";
import PetGromming from "./Pages/Customer/Components/Services/PetGromming";
import Mating from "./Pages/Customer/Components/Services/Mating";
import DayCareService from "./Pages/Customer/Components/Services/DayCareService";
import PetPedia from "./Pages/Customer/Components/Services/PetPedia";
import LocationsForPets from "./Pages/Customer/LocationsForPets";
import MyPets from "./Pages/Customer/MyPets";
import Services from "./Pages/Customer/Components/Services/Services";
import UserProfilePage from "./Pages/Customer/UserProfilePage";
import ServiceProviderSignupin from "./Components/ServiceProviderSignupin";
import GroomingServiceDashboard from "./Pages/GroomingServices/GroomingServiceDashboard";
import TotalAppointmentsSP from "./Pages/GroomingServices/TotalAppointmentsSP";
import InvoiceSearch from "./Pages/GroomingServices/InvoiceSearch";
import TakeLeave from "./Pages/GroomingServices/TakeLeave";
import DoctorSignupin from "./Components/DoctorSignupin";
import DoctorDashboard from "./Pages/Doctor/DoctorDashboard";
import MedicineInventory from "./Pages/Doctor/MedicineInventory";
import Hero from "./Components/Hero/Hero";
import AboutUs from './Components/About/AboutUs'; 
import BookaVet from "./Pages/Services/BookaVet";
import Tinder from "./Pages/Tinder/Tinder";
import Login from "./Components/Login";
import Forgotpassword from "./Components/ForgotPassword/Forgotpassword";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import AppRoutes from "./Components/Navigation/AppRoutes";



const App = () => {
  return (
    <>
      {/* <ChakraProvider> */}
      <RecoilRoot>
        <BrowserRouter>
        <RecoilNexus />
        <AppRoutes />
        </BrowserRouter>
        </RecoilRoot>
      {/* </ChakraProvider> */}
    </>
  );
};

export default App;
