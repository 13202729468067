import React from "react";
import "./about.css";
import Header from "../../Components/Header/Header.jsx";
import Footer from "../Footer/Footer.js";
import { Helmet } from "react-helmet-async";

import about from "../../assets/About/image.png";
const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Zaanvar - Leading Pet Care Services in India</title>
        <meta
          name="description"
          content="Learn about Zaanvar’s innovative pet care services, including blood banks, matchmaking, and lost & found, transforming the lives of pets and their owners."
        />
        <meta name="keywords" content="about us, company, details" />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content="About Us | Zaanvar - Leading Pet Care Services in India"
        />
        <meta
          property="og:description"
          content="Learn about Zaanvar’s innovative pet care services, including blood banks, matchmaking, and lost & found, transforming the lives of pets and their owners."
        />
        <meta property="og:url" content={window.location} />
        <meta
          property="og:image"
          content="https://cdn.builder.io/api/v1/image/assets/TEMP/36cfcad6b36251f6cfd91438fce91cf0e6ab5c0d9f5bedc73498f634519828cd?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        />
      </Helmet>
      <Header />
      <div className="aboutus-contaner-fluid">
        <div className="imageTextContainer">
          <p className="about-text">About us</p>
        </div>

        <div
          style={{
            margin: "0 auto",
            paddingTop: "15px",
            paddingBottom: "20px",
          }}
        >
          <span
            style={{
              textAlign: "center",
              display: "block",
              fontSize: "40px",
              fontWeight: "500",
            }}
          >
            Mission
          </span>
          <span
            style={{
              textAlign: "center",
              display: "block",
              fontSize: "20px",
              fontWeight: "400",
              width: "80%",
              color: "#727271",
              margin: "0 auto",
            }}
          >
            To create a unified platform that connects pet parents, service
            providers, and businesses, ensuring pets receive the best care,
            services, and products throughout their lives.
          </span>
        </div>
        <div
          style={{
            margin: "0 auto",
            paddingTop: "15px",
            paddingBottom: "30px",
          }}
        >
          <span
            style={{
              textAlign: "center",
              display: "block",
              fontSize: "40px",
              fontWeight: "500",
            }}
          >
            Vision
          </span>
          <span
            style={{
              textAlign: "center",
              display: "block",
              fontSize: "20px",
              fontWeight: "400",
              width: "80%",
              color: "#727271",
              margin: "0 auto",
            }}
          >
            To build a world where every pet is cherished, every pet parent is
            empowered, and every family experiences the joy and love that pets
            bring.
          </span>
        </div>

        <div className="container my-5 pb-5">
          <div className="row">
            <div className="col-md-6">
              <div className="image-container mt-5">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e867030cb55573913ed76c38716629e45525c09002b151896920846882ec4608?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                  alt="Founder"
                  className="image-fluid image-back"
                />
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/75aa2133a28f79bdcc3b7ac24e7053c7b75ecf4f75f14ed54f0a5a378c84d2db?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                  alt="Dog"
                  className="image-fluid image-front"
                />
              </div>
            </div>
            <div className="col-md-6 home-about-container">
              <span className="home-about-name">Our Story</span>
              <div className="home-about-dec">
                <p>
                  Zaanvar was born from a simple yet powerful idea: to create a
                  platform that brings together pet owners and the services
                  their pets need. It all started when Ranjith, our co-founder,
                  fell in love with a Shih Tzu named Simba. The bond they shared
                  ignited Ranjith's passion for animals and his desire to make a
                  difference in the world of pet care.
                </p>
                <p>
                  Recognizing the challenges pet parents face in finding
                  trustworthy services, Ranjith set out to build Zaanvar – a
                  platform that connects pet owners with top-quality services,
                  products, and resources. With a focus on convenience, trust,
                  and love for pets, Zaanvar was created to simplify the pet
                  care experience and ensure that every pet has access to the
                  best care
                </p>
                <p>
                  Driven by the vision of making the world a better place for
                  pets and their families, Zaanvar is committed to providing
                  everything your pet needs to live a happy, healthy, and
                  fulfilling life. From grooming and healthcare to nutrition and
                  accessories, we’re here to support you and your furry
                  companions every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <section
          style={{
            backgroundColor: "#F6F9FE",
            padding: "25px 0",
          }}
        >
          <div className="founder-section">
            <div className="founder-text-container">
              <span className="ourFounder-title">Our Story</span>
              <span className="ourFounder-sub-dec">
                Zaanvar was born from a simple yet powerful idea: to create a
                platform that brings together pet owners and the services their
                pets need. It all started when Ranjith, our co-founder, fell in
                love with a Shih Tzu named Simba. The bond they shared ignited
                Ranjith's passion for animals and his desire to make a
                difference in the world of pet care.
                <span style={{ display: "block", marginBottom: "10px" }}></span>
                Recognizing the challenges pet parents face in finding
                trustworthy services, Ranjith set out to build Zaanvar – a
                platform that connects pet owners with top-quality services,
                products, and resources. With a focus on convenience, trust, and
                love for pets, Zaanvar was created to simplify the pet care
                experience and ensure that every pet has access to the best care
                possible.
                <span style={{ display: "block", marginBottom: "10px" }}></span>
                Driven by the vision of making the world a better place for pets
                and their families, Zaanvar is committed to providing everything
                your pet needs to live a happy, healthy, and fulfilling life.
                From grooming and healthcare to nutrition and accessories, we’re
                here to support you and your furry companions every step of the
                way.
              </span>
            </div>

            <div className="images-container" style={{ marginLeft: "55px" }}>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e867030cb55573913ed76c38716629e45525c09002b151896920846882ec4608?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                alt="Founder"
                className="image founder-image"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/75aa2133a28f79bdcc3b7ac24e7053c7b75ecf4f75f14ed54f0a5a378c84d2db?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                alt="Dog"
                className="image dog-image"
              />
            </div>
          </div>
        </section> */}

        <div className="container my-5 pb-5">
          <div className="row">
            <div className="col-md-6">
              <span className="home-about-name">Our Founder</span>

              <div className="home-about-dec">
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "black",
                  }}
                >
                  Ranjith Soma
                </span>
                <p>
                  Ranjith Soma, the co-founder of Zaanvar, is a passionate pet
                  lover whose journey into the pet care industry began with his
                  beloved Shih Tzu, Simba. Inspired by the deep bond he shared
                  with Simba, Ranjith envisioned a platform that would make pet
                  care more accessible and efficient for pet parents everywhere.
                </p>
                <p>
                  Recognizing the challenges pet parents face in finding
                  trustworthy services, Ranjith set out to build Zaanvar – a
                  platform that connects pet owners with top-quality services,
                  products, and resources. With a focus on convenience, trust,
                  and love for pets, Zaanvar was created to simplify the pet
                  care experience and ensure that every pet has access to the
                  best care
                </p>

                <span className="founder-by-text">
                  With gratitude and determination,
                  <br />
                  <strong>Ranjith Founder & CEO</strong>
                </span>
              </div>
            </div>
            <div className="col-md-6 home-about-container">
              <div className="image-container mt-5">
                <img
                  src={about}
                  alt="Founder"
                  className="image-fluid image-back"
                />
                {/* <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/75aa2133a28f79bdcc3b7ac24e7053c7b75ecf4f75f14ed54f0a5a378c84d2db?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
                  alt="Dog"
                  className="image-fluid image-front"
                /> */}
              </div>
            </div>
          </div>
        </div>

        {/* <section className="founder-section">
          <div className="images-container">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e867030cb55573913ed76c38716629e45525c09002b151896920846882ec4608?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
              alt="Founder"
              className="image founder-image"
            />
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/75aa2133a28f79bdcc3b7ac24e7053c7b75ecf4f75f14ed54f0a5a378c84d2db?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
              alt="Dog"
              className="image dog-image"
            />
          </div>
          <div className="founder-text-container">
            <span className="ourFounder-title">Our Founder</span>
            <span className="ourFounder-sub-dec">
              <span
                style={{ fontWeight: "500", fontSize: "20px", color: "black" }}
              >
                Ranjith Soma
              </span>
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              Ranjith Soma, the co-founder of Zaanvar, is a passionate pet lover
              whose journey into the pet care industry began with his beloved
              Shih Tzu, Simba. Inspired by the deep bond he shared with Simba,
              Ranjith envisioned a platform that would make pet care more
              accessible and efficient for pet parents everywhere.
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              With a background in technology and a heart full of compassion for
              animals, Ranjith set out to create Zaanvar, a one-stop solution
              for all things pet-related. His mission is to ensure that every
              pet receives the love, care, and attention they deserve while
              making it easier for pet owners to access quality services and
              products.
            </span>
            <span className="founder-by-text">
              With gratitude and determination,
              <br />
              <strong>Ranjith Founder & CEO</strong>
            </span>
          </div>
        </section> */}

        {/* Rahul repala  */}

        <div className="container my-5 pb-5">
          <div className="row">
            <div className="col-md-6">
              <div className="image-container mt-5">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fd0305b61847e4adbbbe0eaedd687cad6"
                  alt="Founder"
                  className="image-fluid image-back"
                />
                {/* <img
                  src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fd0305b61847e4adbbbe0eaedd687cad6"
                  alt="Dog"
                  className="image-fluid image-front"
                /> */}
              </div>
            </div>
            <div
              className="col-md-6 home-about-container"
              style={{ color: "#727271" }}
            >
              <span
                style={{ fontWeight: "500", fontSize: "20px", color: "black" }}
              >
                Repala Rahul
              </span>
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              <p>
                Rahul Repala is the CTO of Zaanvar, a company dedicated to
                transforming the pet care industry through innovative
                technology. With a passion for empowering individuals, Rahul
                focuses on creating opportunities that enable personal and
                professional growth. With a background in technology and a heart
                full of compassion for animals, Ranjith set out to create
                Zaanvar, a one-stop solution for all things pet-related. His
                mission is to ensure that every pet receives the love, care, and
                attention they deserve while making it easier for pet owners to
                access quality services and products.
              </p>
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              <p>
                His experience in software development and leadership drives
                Zaanvar’s mission to connect pet businesses and owners through
                tailored, user-friendly solutions. Rahul's commitment to
                fostering a supportive work environment and advancing technology
                has been instrumental in shaping Zaanvar's journey in the pet
                tech space.
              </p>
              <span className="founder-by-text">
                With gratitude and determination,
                <br />
                <strong>Rahul Repala & CTO</strong>
              </span>
            </div>
          </div>
        </div>

        {/* <section className="founder-section">
          <div className="images-container">
            <img
              src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fd0305b61847e4adbbbe0eaedd687cad6"
              alt="Founder"
              className="image founder-image-rahul"
            />
            <img
              src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fd0305b61847e4adbbbe0eaedd687cad6"
              alt="Dog"
              className="image dog-image"
            />
          </div>
          <div className="founder-text-container">
            <span className="ourFounder-title"></span>
            <span className="ourFounder-sub-dec">
              <span
                style={{ fontWeight: "500", fontSize: "20px", color: "black" }}
              >
                Repala Rahul
              </span>
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              Rahul Repala is the CTO of Zaanvar, a company dedicated to
              transforming the pet care industry through innovative technology.
              With a passion for empowering individuals, Rahul focuses on
              creating opportunities that enable personal and professional
              growth. With a background in technology and a heart full of
              compassion for animals, Ranjith set out to create Zaanvar, a
              one-stop solution for all things pet-related. His mission is to
              ensure that every pet receives the love, care, and attention they
              deserve while making it easier for pet owners to access quality
              services and products.
              <span style={{ display: "block", marginBottom: "10px" }}></span>
              His experience in software development and leadership drives
              Zaanvar’s mission to connect pet businesses and owners through
              tailored, user-friendly solutions. Rahul's commitment to fostering
              a supportive work environment and advancing technology has been
              instrumental in shaping Zaanvar's journey in the pet tech space.
            </span>
            <span className="founder-by-text">
              With gratitude and determination,
              <br />
              <strong>Rahul Repala & CTO</strong>
            </span>
          </div>
        </section> */}
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
