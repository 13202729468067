import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL } from "../../../Utilities/Constant";

import search from "../../../assets/Social_Media_Icons/search.svg";

const SearchSocialMedia = ({ onUserClick }) => {
  const webApi = new WebApimanager();
  const [suggestionData, setSuggestionData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchSuggestionData = async () => {
      try {
        const response = await webApi.get("socialMedia/all");
        setSuggestionData(response.data.data || []);
      } catch (error) {
        console.error("Server error:", error);
        toast.error("Failed to fetch suggestions. Please try again.");
      }
    };

    fetchSuggestionData();
  }, []);

  const filteredSuggestions = suggestionData.filter((suggestion) =>
    suggestion.userId?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{ backgroundColor: "white", height: "84vh" }}>
      <div className="socialmedia-search-section">
        <div className="socialmedia-search-container">
          <input
            type="text"
            placeholder="Search here..."
            className="search-input"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          <img src={search} alt="Search-users" className="search-icon" />
        </div>
      </div>
      <div className="socialmedia-right-text">
        <p>Search Users</p>
      </div>
      <div className="socialmedia-suggested-list">
        {filteredSuggestions.map((suggestion, index) => (
          <div
            key={index}
            className="socialmedia-follow-list"
            onClick={() => onUserClick(suggestion.userId?._id)} // Pass userId
            style={{ cursor: "pointer" }}
          >
            <div className="suggestion-horizental-display">
              <img
                src={
                  suggestion.userId?.profile
                    ? `${BACKEND_URL}${suggestion.userId.profile}`
                    : "https://via.placeholder.com/150"
                }
                alt="profile"
                className="socialmedia-follow-profile"
              />
              <div className="socialmedia-suggested-list-name">
                <p className="suggested-list-primary">
                  {suggestion.userId?.name || "Unknown User"}
                </p>
                <p className="suggested-list-secondary">
                  {suggestion.userId?.email || "No Email Provided"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

SearchSocialMedia.propTypes = {
  onUserClick: PropTypes.func.isRequired,
};

export default SearchSocialMedia;
