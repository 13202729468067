import React, { useState, useEffect } from "react";
import "./LostAndFoundCards.css";
// import img from "../../../assets/images/Mask group.svg";
import saveIcon from "../../../assets/images/Vector.png";
import notsaveIcon from "../../../assets/images/notsave.png";
import { ChatIcon, LikeIcon, LocationIcon } from "../../../assets/SVG";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL, formatDate } from "../../../Utilities/Constant";
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LostAndFoundCards = ({filters,onCardClick,onChatClick,showMyPosts,}) => {
  const webApi = new WebApimanager();
  const [petData, setPetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setMessage("No pets found.");
    }, 10000);
    fetchPetData();

    return () => clearTimeout(timer); 
  }, []);
  const fetchPetData = () => {
    setLoading(true); 
    webApi
      .get("lostAndFound/allPets")
      .then((response) => {
        const responseData = response.data;
        const foundPets = responseData.data.foundPets
        ? responseData.data.foundPets.filter(
            pet => pet.petStatus === "Found" || pet.petStatus === "Reunited"
          )
        : [];
  
      const lostPets = responseData.data.lostPets

        ? responseData.data.lostPets.filter(
            pet => pet.petStatus === "Lost" || pet.petStatus === "Reunited"
          )
        : [];
        const allPets = [...foundPets, ...lostPets];
        setPetData(allPets);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching pet data:", error);
        setError("Failed to fetch pet data. Please try again.");
        setLoading(false);
      });
  };

    const filteredData = petData.filter((pet) => {
    const filterStatus = filters.selectedRadioOption;
    const filterPetType = filters.petType;
    const filterBreedType = filters.breedType;
    const filterPetColor = filters.petColor;
    const filterPetname = filters.petname;
    const filterPetid = filters.petID?.trim();

    const matchesStatus =
      !filters?.selectedRadioOption ||
      filterStatus === "Select" ||
      pet.petStatus === filterStatus;
    const matchesPetType =
      filterPetType === "Select" || pet.breed === filterPetType;

    const matchesPetColor =
      filters.petColor.length === 0 || filters.petColor.includes(pet.color);
    const matchesPetname =
      filterPetname === "Select" || pet.petType === filterPetname;
    const matchesPetId = !filterPetid || pet.petId.includes(filterPetid);

    return (
      matchesStatus &&
      matchesPetType &&
      matchesPetColor &&
      matchesPetname &&
      matchesPetId
    );
  });

  const handleIconClick = async (id, petStatus,currentSavedState) => {
    let url;
      if (currentSavedState === "yes") {
        url = `users/removeSavedPet?petId=${id}&type=${petStatus}`;

      } else {
        url = `users/updateSavedPet?petId=${id}&type=${petStatus}`;

      }

      try {
        const response = currentSavedState === "yes" 
        ? await webApi.delete(url)  
        : await webApi.put(url);   
        console.log(response, "responseresponse");
      
    //   toast.success("Successfully!");
      fetchPetData();

    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
    <ToastContainer/>
    <div style={{ display: "flex" }}>
      <section className="petProfileCard1">
        {filteredData.map((pet) => (
          <div key={pet.id} className="petProfileCard2">
            <img
              src={pet.petImage ? `${BACKEND_URL}${pet.petImage}` : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fpngtree.com%2Fso%2Fdog-profile&psig=AOvVaw1PYCvI1-5_RbzhaYFPRBuv&ust=1735625769356000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCKikwP_rzooDFQAAAAAdAAAAABAE"}
              alt={pet?.name}
              onClick={() => onCardClick(pet)}
              style={{ cursor: "pointer", width: "100%", height: "230px",    objectFit: "cover" }}
            />
            <div>
            <div style={{ margin: "15px" }}>
              <div style={{display: "flex",justifyContent: "space-between", marginTop: "6px",    paddingLeft: "0px"}}>
                <h1>{pet.petName}</h1>
                <div style={{ display: "flex", gap: "15px" ,width: "67.667px",
height: "25px",marginTop:"3px",    justifyContent: "end"
}}> 
                  <div onClick={onChatClick} style={{ cursor: "pointer" }}>
                    <ChatIcon />
                  </div>
                  <div onClick={() => handleIconClick(pet._id,pet.from,pet.saved)} style={{ cursor: "pointer" }}>
                  {pet.saved ==="yes"? (
                      <img src={saveIcon} id="saveicon" alt="Save Icon" />
                    ) : (
                      <img src={notsaveIcon} id="saveicon" alt="Not Save Icon" />
                    )}

                  </div>
                </div>
              </div>
              <div
  style={{
    display: "flex",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  }}
  id="lost-and-found-petId"
>
  <div
    style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flex: "1",
    }}
  >
    Pet ID : {pet.petStatus === "Found" && pet?.postId ? pet.postId : pet.petId} &nbsp;&nbsp;
  </div>
  <div
    style={{
      marginLeft: "6px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexShrink: "0", // Ensures this div does not shrink, preventing it from cutting off
    }}
  >
    | Gender : {pet.petGender}
  </div>
</div>


              <div style={{ display: "flex", gap: "5px" }}>
                <div
                  style={{ marginTop: "2px",marginLeft: "5px",marginRight: "2px",    marginBottom: "13px"
                  }}>
                  <LocationIcon />
                </div>
                <p>{pet.lostAddress ? pet.lostAddress : pet.foundLocation}</p>
              </div>
              <div>
                <div style={{ display: "flex" }}>
                  <p id={pet.petStatus === "Lost" ? "button-lost" : pet.petStatus === "Found" ? "button-found" : "button-reunited"}>
                    {pet.petStatus}
                  </p>

                  {pet.petStatus !== "Found" && pet.petStatus !== "Reunited" && pet.petStatus !== "Deceased" && pet.petStatus !== "Surrendered" && (
                      <p id={pet.petStatus === "Lost" ? "lost-and-found-reward" : "lost-and-found-reward2"}>
                        Reward: ₹ {pet.priceMoney}
                      </p>
                    )}
                </div>
                </div>
                <div id="lost-and-found-line">
                  <p id="lost-and-found-lost-date">
                    {pet.foundDate ? `Found Date: ${formatDate(pet.foundDate)}` : `Date of last seen: ${formatDate(pet.dateOfLastSeen)}`}
                  </p>
                  <p id="lost-and-found-post-date">
                    Post Date : {formatDate(pet.createdDate)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        {filteredData.length === 0 && (
            <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100vh",position:"relative",top:"-15%",left:"38%"}}>  
                {loading ? (
                   <ThreeDots color="#1bc1e9" height={100} width={100} ariaLabel="loading" />
                ) : (
                   <p style={{ fontSize: "18px", color: "#555" }}>{message}</p>
                )}
            </div>
        )}
      </section>
    </div>
    </>
  );
};

export default LostAndFoundCards;
