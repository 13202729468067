import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify"; // To sanitize WordPress content
import "./Blog.css";

const animals = ["DOG", "CAT", "BIRD", "FISH", "RABBIT"];

const LatestPetNews = () => {
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  const BASE_URL = "http://dev-wp-api.zaanvar.com/wp-json/wp/v2";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/posts`);
        setPosts(response.data); // Save posts data
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/categories`);
        setCategories(response.data); // Save categories data
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchMedia = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/media`);
        setMedia(response.data); // Save media data
      } catch (error) {
        console.error("Error fetching media:", error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchPosts(), fetchCategories(), fetchMedia()]);
      setLoading(false);
    };

    fetchData();
  }, []);

  const getFeaturedMedia = (mediaId) => {
    const mediaItem = media.find((item) => item.id === mediaId);
    return mediaItem ? mediaItem.source_url : null;
  };

  return (
    <div>
      <p id="blog-latest-pet-news">
        Latest <span className="blog-latest-pet-news-color">Pet News</span>
      </p>

      <div className="button-container">
        {animals.map((animal, index) => (
          <button key={index} className="animal-button">
            {animal}
          </button>
        ))}
      </div>

      <div className="App">
        <h1>Blog Posts</h1>
        {loading ? (
          <p>Loading posts...</p>
        ) : posts.length > 0 ? (
          posts.map((post) => (
            <div key={post.id} className="post-card">
              <h2>{post.title.rendered}</h2>
              <div
                className="post-excerpt"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.excerpt.rendered),
                }}
              />
              <div
                className="post-content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.content.rendered),
                }}
              />

              <strong>Naveen</strong>
              {post.featured_media && (
                <figure>
                  <div></div>
                  <img
                    src={getFeaturedMedia(post.featured_media)}
                    alt={post.title.rendered}
                  />
                </figure>
              )}
              <p>
                <a href={post.link} target="_blank" rel="noopener noreferrer">
                  Read more...
                </a>
              </p>
            </div>
          ))
        ) : (
          <p>No posts found</p>
        )}
      </div>

      <div>
        <h2>Categories</h2>
        {categories.length > 0 ? (
          categories.map((category) => (
            <div key={category.id}>
              <h3>{category.name}</h3>
            </div>
          ))
        ) : (
          <p>No categories found</p>
        )}
      </div>

      <div>
        <h2>Media</h2>
        {media.length > 0 ? (
          media.map((item) => (
            <div key={item.id}>
              <img
                src={item.source_url}
                alt={item.alt_text || "Media"}
                width="200"
              />
            </div>
          ))
        ) : (
          <p>No media found</p>
        )}
      </div>
    </div>
  );
};

export default LatestPetNews;
