import React, { useState, useRef,useEffect } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";

const ImagePostModal = ({ isOpen, onClose }) => {
  const webApi = new WebApimanager();
  const [imagePoll, setImagePoll] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [option, setOptions] = useState(["", ""]);
  const modalRef = useRef(null);

  const resetState = () => {
    setImagePoll(null);
    setImagePreview(null);
    setOptions(["", ""]);
  };

  const handleQuestionChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePoll(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };
  const handleOptionChange = (index, value) => {
    const updatedOptions = [...option];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...option, ""]);
  };

  const handleSubmit = async () => {
    if ( option.every((opt) => opt.trim())) {
      try {
        console.log(imagePoll,option, "checking inside the submit")
        const formData = new FormData();
        formData.append("type", "Image Poll");
        formData.append("option", JSON.stringify(option));
        formData.append("files", imagePoll);
        const response = await webApi.imagePost(
          "socialMedia/uploadImages",
          formData
        );

        if (response.status === 201) {
          console.log("Poll created successfully:", response.data);
          resetState();
          onClose();
        } else {
          console.error("Failed to create poll:", response.status);
          alert("Failed to create poll. Please try again.");
        }
      } catch (error) {
        console.error("Error occurred while creating poll:", error);
        alert("An error occurred while creating the poll.");
      }
    } else {
      alert("Please ensure all fields are filled.");
    }
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      onClick={handleOutsideClick}
      className={`socialmedia-textPollModal-header ${isOpen ? "open" : ""}`}
    >
      <div
        ref={modalRef}
        className={`socialmedia-textPollModal-body ${isOpen ? "open" : ""}`}
      >
        <div className="socialmedia-textPollModal-button-container">
          <button
            className="nav-button left black"
            onClick={() => {
              resetState();
              onClose();
            }}
          >
            ←
          </button>
          <button
            className="socialmedia-textPollModal-button next"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
        <div className="textPoll-padding">
          <label className="socialmedia-textPollModal-question">
            Ask an Image
          </label>
          <div className="poll-input">
            <input
              type="file"
               accept=".jpg, .jpeg, .png"
               name="imagepoll"
              id="imageInput"
              style={{ display: "none" }}
              onChange={(e) =>handleQuestionChange (e)}
            />
            <label
              htmlFor="imageInput"
              style={{
                display: "block",
                cursor: "pointer",
                width: "100%",
                height: "200px",
                border: "2px dashed #ccc",
                borderRadius: "8px",
                textAlign: "center",
                lineHeight: "200px",
                color: "#777",
                background: imagePreview
                  ? `url(${imagePreview}) center/cover no-repeat`
                  : "#f9f9f9",
              }}
            >
              {imagePreview ? "" : "Click to select an image"}
            </label>
          </div>
          {option.map((option, index) => (
            <div className="poll-option mt-2" key={index}>
              <label className="socialmedia-textPollModal-poll-option">
                Option {index + 1}
              </label>
              <input
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder={`Enter option ${index + 1}`}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginBottom: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
            </div>
          ))}
          <div className="socialmedia-textPollModal-add-Option">
            <button onClick={handleAddOption}>Add Option</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagePostModal;
