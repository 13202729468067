import React, { useState, useEffect } from "react";
import close from "../../../../assets/images/back-chat.png";
import profilepic from "../../../../assets/images/profile-pic2.png";
import menu from "../../../../assets/images/menu1.png";
import msgcorrect from "../../../../assets/images/msgcorrect.png";
import sendimg from "../../../../assets/images/sendimg.png";
import Report from "../../../../assets/images/Report.png";
import deleteicon from "../../../../assets/images/delete.png";
import block from "../../../../assets/images/block.png";
import {
  BackIconBlack,
  CrossIconBlack,
  MenuIcon,
  SendIcon,
  SentIcon,
  ProfileIcon,
} from "../../../../assets/SVG";
import { BACKEND_URL, formatDate } from "../../../../Utilities/Constant";
import { SOCKET_SERVER_URL } from "../../../../Utilities/Constant";
import { useRecoilState } from "recoil";
import { io } from "socket.io-client";
import { getRecoil } from "recoil-nexus";
import { jwtToken, userInfo } from "../../../../Atoms";
import styles from "./ChatPopup.module.css";
import { Form } from "react-router-dom";

const TinderSingleChatPage = ({
  onClose,
  isChatPopupOpen2,
  sendingOwnerDetials,
  sendingOwnerDetials2,
  sendingOwnerDetials3,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [messages, setMessages] = useState({});
  const [socket, setSocket] = useState(null);
  const [conversationsSingle, setConversationsSingle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentUser] = useRecoilState(userInfo);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [message, setMessage] = useState("");
  let jwttoken = getRecoil(jwtToken);

  const userId = currentUser?._id;

  console.log(sendingOwnerDetials3, "cheking userId");

  console.log(isChatPopupOpen2, "checking singlechat");
  console.log(sendingOwnerDetials?.name, "checking owner detials");
  const [conversationId, setConversation] = useState(sendingOwnerDetials2?._id);

  // useEffect(() => {
  //     if (!userId) return;

  //     if (!socket) {
  //         const newSocket = io(SOCKET_SERVER_URL, { query: { userId } });
  //         setSocket(newSocket);

  //         newSocket.on("getOnlineUsers", setOnlineUsers);
  //         newSocket.on("receiveMessage", (receivedMessage) => {
  //             setConversations((prev) =>
  //                 prev.map((conv) =>
  //                     conv._id === receivedMessage.conversationId
  //                         ? { ...conv, lastMessage: receivedMessage }
  //                         : conv
  //                 )
  //             );
  //         });

  //         return () => newSocket.disconnect();
  //     }
  // }, [userId, socket]);

  useEffect(() => {
    if (!userId) return;

    const newSocket = io(SOCKET_SERVER_URL, {
      autoConnect: false,
      query: { userId },
    });
    setSocket(newSocket);

    newSocket.on("getOnlineUsers", setOnlineUsers);
    newSocket.on("receiveMessage", (receivedMessage) => {
      setConversations((prev) =>
        prev.map((conv) =>
          conv?._id === receivedMessage.conversationId
            ? { ...conv, lastMessage: receivedMessage }
            : conv
        )
      );
    });

    return () => {
      newSocket.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    console.log("Conversations updated:");
    conversations.forEach((conversation) => {
      setConversation(conversation.lastMessage.conversationId);

      console.log(
        `Conversation ID: ${conversation.lastMessage.conversationId}`
      );
      console.log("Participants:");
      conversation.participants.forEach((participant) => {
        console.log(`Name: ${participant.name}, Email: ${participant.email}`);
      });
    });
    console.log(`Conversation ID: ${conversationId}`);
  }, [conversations]);
  console.log("Sending message tonn:", sendingOwnerDetials3?._id);

  const sendMessage = async (receiverId, conversationId) => {
    const messageContent = message.trim();
    if (!messageContent) return; // Don't send empty messages

    const payload = {
      sender: userId,
      receiver: sendingOwnerDetials?._id,
      content: message,
      conversationId,
      senderPetId: sendingOwnerDetials2?._id,
      receiverPetId: sendingOwnerDetials3?._id,
    };

    try {
      const response = await fetch(`${SOCKET_SERVER_URL}api/message/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwttoken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok)
        throw new Error(`Failed to send message: ${response.status}`);

      const sentMessage = await response.json();

      // Emit the sent message to the server via socket
      socket.emit("sendMessage", sentMessage);

      // Update conversations state to reflect the sent message
      setConversations((prev) =>
        prev.map((conv) =>
          conv?._id === conversationId
            ? { ...conv, lastMessage: sentMessage }
            : conv
        )
      );

      // Clear the input field after sending
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error.message);
    }
  };

  // useEffect(() => {
  //     if (!userId) return;

  //     // Initialize the socket connection
  //     const newSocket = io(SOCKET_SERVER_URL, { query: { userId } });
  //     setSocket(newSocket);

  //     newSocket.on("getOnlineUsers", (users) => {
  //         setOnlineUsers(users);
  //     });

  //     newSocket.on("receiveMessage", (receivedMessage) => {
  //         setConversations((prev) =>
  //             prev.map((conv) =>
  //                 conv._id === receivedMessage.conversationId
  //                     ? { ...conv, lastMessage: receivedMessage }
  //                     : conv
  //             )
  //         );
  //     });

  //     // Clean up the socket connection on component unmount
  //     return () => {
  //         newSocket.disconnect();
  //     };
  // }, [userId]);

  useEffect(() => {
    if (!userId || !jwttoken || !conversationId) return;

    const fetchConversations = async () => {
      try {
        // Set loading state to true before fetching data
        setLoading(true);

        const response = await fetch(
          `${SOCKET_SERVER_URL}api/message/ByReceiver/${sendingOwnerDetials3.petOwner?._id}?senderPetId=${sendingOwnerDetials2?._id}&receiverPetId=${sendingOwnerDetials3?._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwttoken}`,
            },
          }
        );

        // Handle non-OK responses
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        // Update state with fetched messages
        if (data?.messages?.length > 0) {
          setConversationsSingle(data.messages);
          console.log("Fetched messages:", data.messages);
        } else {
          console.log("No data found");
          setConversationsSingle(null);
        }
      } catch (error) {
        console.error("Error fetching conversations:", error.message);
      } finally {
        // Reset loading state after data is fetched or an error occurs
        setLoading(false);
      }
    };
    fetchConversations();
    // Clean up socket on component unmount or when dependencies change
    return () => {
      socket?.disconnect();
    };
  }, [userId, jwttoken, conversationId]);

  useEffect(() => {
    if (!userId) return;

    const newSocket = io(SOCKET_SERVER_URL, { query: { userId } });
    setSocket(newSocket);

    newSocket.on("getOnlineUsers", (users) => {
      setOnlineUsers(users);
    });

    newSocket.on("receiveMessage", (receivedMessage) => {
      setConversations((prev) =>
        prev.map((conv) =>
          conv?._id === receivedMessage.conversationId
            ? { ...conv, lastMessage: receivedMessage }
            : conv
        )
      );
    });
    return () => {
      newSocket.disconnect();
    };
  }, [userId]);

  // Render logic for displaying data, loading state, and empty results
  if (loading) {
    return <div>Loading...</div>;
  }

  if (conversationsSingle === null) {
    return <div>No data found</div>;
  }
  console.log(sendingOwnerDetials?._id, "czczcz");

  const sortedMessages = [...conversationsSingle].sort(
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  );

  const isUserOnline = (userId) => {
    return onlineUsers.includes(userId);
  };

  return (
    <>
      {isChatPopupOpen2 && (
        <div className={styles.petdetailspopupoverlay}>
          <div className={styles.lostAndFoundChats}>
            {/* Header - Fixed at the Top */}
            <div className={styles.LostAndFoundSingleChathearder}>
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <button
                  className={styles.lostAndFondsingleChatclose}
                  onClick={onClose}
                >
                  <img src={close} alt="Close" className={styles.chatimg} />
                </button>
                <img
                  src={
                    sendingOwnerDetials.profile
                      ? `${BACKEND_URL}${sendingOwnerDetials.profile}`
                      : profilepic
                  }
                  alt="Profile"
                  style={{
                    width: "45px",
                    marginRight: "10px",
                    height: "45px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                <div style={{ marginRight: "auto" }}>
                  <p
                    style={{ marginTop: "-8px", marginBottom: "3px" }}
                    className={styles.SingleChatName}
                  >
                    {sendingOwnerDetials.name}
                  </p>
                  <p style={{ margin: 0 }} className={styles.SingleChatOnline}>
                    {isUserOnline(sendingOwnerDetials?._id)
                      ? "Online"
                      : "Offline"}
                  </p>
                </div>
                <div
                  style={{
                    marginRight: "10px",
                    marginTop: "-5px",
                    position: "relative",
                  }}
                >
                  <img
                    src={menu}
                    alt="Menu"
                    style={{ cursor: "pointer", height: "auto" }}
                    onClick={() => setMenuOpen((prev) => !prev)}
                  />
                  {menuOpen && (
                    <div className={styles.SingleChatChatmenuDropdown}>
                      <ul>
                        <li onClick={() => console.log("Report clicked")}>
                          <img
                            src={Report}
                            style={{
                              position: "absolute",
                              top: "13px",
                              height: "auto",
                            }}
                          />
                          Report
                        </li>
                        <li onClick={() => console.log("Delete clicked")}>
                          <img
                            src={deleteicon}
                            style={{
                              display: "flex",
                              position: "absolute",
                              paddingTop: "5px",
                              height: "auto",
                            }}
                          />
                          Delete
                        </li>
                        <li onClick={() => console.log("Block clicked")}>
                          <img
                            src={block}
                            style={{
                              display: "flex",
                              position: "absolute",
                              paddingTop: "5px",
                              height: "auto",
                            }}
                          />
                          Block
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Chat Body - Scrollable */}
            <div className={styles.lostAndFoundChatPopupBody}>
              <div className={styles.SingleChatChatBody}>
                {Array.isArray(conversationsSingle) &&
                  Object.entries(
                    conversationsSingle
                      .sort(
                        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                      ) // Sort messages by timestamp (oldest first)
                      .reduce((groupedMessages, message) => {
                        const messageDate = new Date(message.timestamp);
                        const today = new Date();
                        const diffTime =
                          today.getTime() - messageDate.getTime();
                        const diffDays = Math.floor(
                          diffTime / (1000 * 60 * 60 * 24)
                        );

                        let groupLabel = "";

                        if (diffDays === 0) groupLabel = "Today";
                        else if (diffDays === 1) groupLabel = "Yesterday";
                        else groupLabel = messageDate.toLocaleDateString();

                        if (!groupedMessages[groupLabel])
                          groupedMessages[groupLabel] = [];
                        groupedMessages[groupLabel].push(message);

                        return groupedMessages;
                      }, {}) // Group messages by day
                  )
                    .reverse()
                    .map(([dateLabel, messages]) => (
                      <div key={dateLabel}>
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "10px 0",
                          }}
                        >
                          {dateLabel}
                        </div>
                        {messages.map((message, index) => {
                          const messageDate = new Date(message.timestamp);
                          const timeString = messageDate.toLocaleTimeString(
                            [],
                            { hour: "2-digit", minute: "2-digit" }
                          ); // Format time

                          const isCurrentUser = message.sender?._id === userId;
                          console.log(`Message ${index}:`, message);
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: isCurrentUser
                                  ? "flex-end"
                                  : "flex-start",
                                marginBottom: "10px",
                              }}
                            >
                              {/* Message content */}
                              {isCurrentUser ? (
                                <>
                                  <div
                                    className={styles.SingleChatChatbodyDIv}
                                    style={{
                                      backgroundColor: isCurrentUser
                                        ? "#e0f7fa"
                                        : "#ffffff",
                                      alignSelf: isCurrentUser
                                        ? "flex-end"
                                        : "flex-start",
                                    }}
                                  >
                                    <p className={styles.SingleChatChatmsgs}>
                                      {message.content}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "0.8rem",
                                        color: "#888",
                                        marginTop: "5px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {timeString}
                                    </p>
                                  </div>

                                  {/* Message from current user */}
                                  <div>
                                    <img
                                      src={msgcorrect}
                                      alt="Sent"
                                      style={{
                                        paddingTop: "12px",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                // Message from other user
                                <>
                                  <div>
                                    <img
                                      src={profilepic}
                                      alt="Receiver"
                                      className={styles.profilePic}
                                    />
                                  </div>
                                  <div className={styles.messageBubble}>
                                    <p className={styles.SingleChatChatmsgs}>
                                      {message.content}
                                    </p>
                                    <span className={styles.messageTime}>
                                      {timeString}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ))}
              </div>
            </div>

            {/* Input - Fixed at the Bottom */}
            <div
              style={{
                position: "relative",
                width: "100%",
                borderRadius: "15px",
                top: "195px",
              }}
            >
              <input
                placeholder="Send Message"
                value={message}
                onChange={(e) => {
                  const newMessage = e.target.value;
                  setMessage(newMessage);
                  console.log(newMessage);
                }}
                style={{
                  width: "87%",
                  padding: "10px",
                  paddingRight: "40px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  borderRadius: "25px",
                }}
              />
              <button
                style={{
                  position: "absolute",
                  right: "54px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => sendMessage(sendingOwnerDetials?._id, userId)}
              >
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TinderSingleChatPage;
