import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import tick from "../../../assets/Social_Media_Icons/tick.svg";

const SharePost = ({ isOpen, onClose, postId, refetchData }) => {
  const webApi = new WebApimanager();
  const [shareData, setShareData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    const fetchSuggestionData = async () => {
      try {
        const response = await webApi.get(`socialMedia/all`);
        const suggestions = response.data.data || [];
        setShareData(suggestions);

        console.log(shareData, "socialMedia/followDetails");
      } catch (error) {
        console.error("Server error:", error);
        toast.error("Failed to fetch suggestions. Please try again.");
      }
    };

    fetchSuggestionData();
  }, [currentUserInfo]);

  const toggleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const sharePost = async () => {
    if (!postId) {
      toast.error("Post not found!");
      return;
    }

    if (selectedUsers.length === 0) {
      toast.error("No users selected!");
      return;
    }

    try {
      await webApi.put(`socialMedia/share/${postId}`, {
        userIds: selectedUsers,
      });

      toast.success("Post shared successfully!");
      onClose();
      setSelectedUsers("");
      if (refetchData) refetchData();
    } catch (error) {
      console.error("Error sharing post:", error);
      toast.error("Failed to share post. Please try again.");
    }
  };

  const filterData = shareData.filter(
    (user) =>
      user.userId._id !== currentUserInfo._id &&
      user.userId.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!isOpen) return null;

  return (
    <>
      <div className="sharepost-classname-overlay">
        <div className="sharepost-classname-modal">
          <ToastContainer />
          <div className="sharePost-header-names">
            <p>Share</p>
            <button className="sharepost-classname-close" onClick={onClose}>
              &times;
            </button>
          </div>
          <div className="sharepost-classname-content">
            <div>
              <input
                type="text"
                placeholder="Search here"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="sharePost-input"
              />
            </div>
            <div className="sharepost-container">
              <div className="sharepost-grid">
                {filterData.length > 0 ? (
                  filterData.map((user) => (
                    <div
                      className={`sharepost-item ${
                        selectedUsers.includes(user.userId._id)
                          ? "selected"
                          : ""
                      }`}
                      key={user.userId._id}
                      onClick={() => toggleUserSelection(user.userId._id)}
                    >
                      <img
                        src={
                          `${BACKEND_URL}${user.userId.profile}` ||
                          "default-profile.jpg"
                        }
                        alt={`${user.userId.name}'s profile`}
                        className="sharepost-profile-picture"
                      />
                      <p className="user-name">{user.userId.name}</p>
                      {selectedUsers.includes(user.userId._id) && (
                        <div className="tick-mark">
                          <img src={tick} alt="tick" />
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-data-message">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F23c38233cf66491c9332b58fc0cced5c"
                      alt="No Data Available"
                      className="no-data-image"
                    />
                    <p>No users available. Please check back later.</p>
                  </div>
                )}
              </div>
            </div>
            <button className="sharepost-classname-button" onClick={sharePost}>
              SEND
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharePost;
