import React, {useState} from 'react';
import styles from './LostAndFoundLandingPage.module.css';
import { petMenu, infoMenu, faq } from '../../Tinder/constants';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import LostAndFoundModalLogin from '../LostAndFoundLandingPage/LostAndFoundModalLogin'

import { userInfo } from '../../../Atoms';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';



const LostAndFoundLandingPage = () => {

  const [faqMenu, setFaqMenu] = useState(faq);
  const navigate = useNavigate();
  const [currentUserInfo, setCurrentUser] = useRecoilState(userInfo);


  const onClickFaqFunction = (id) => {
    const updatedMenu = faqMenu.map(tempFaq => 
        tempFaq.id === id ? { ...tempFaq, visible: !tempFaq.visible } : tempFaq
    );
    setFaqMenu(updatedMenu);
};
const userLoggedIn = !!currentUserInfo?.email;
console.log(userLoggedIn,"checking user login") 
const [isModalOpen, setIsModalOpen] = useState(false);
const handleNavgate=()=>{
    if(userLoggedIn){
        navigate('/lost-and-found/HomePage')
    }else{
            setIsModalOpen(true);
    }
   
}
const handleCloseModal = () => {
    setIsModalOpen(false); 
  };
  const handleLoginRedirect = () => {
    setIsModalOpen(false); 
    navigate('/login', { state: { from: '/lost-and-found/HomePage' } });
  };


  return (
    <>
    <Header/>
        <section className={styles.hero} style={{overflow:"hidden"}}>
            <img src="https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F58369de3420c4539828ca1e68613bef0" alt="Hero background" className={styles.heroBackground} />
            <div className={styles.heroDiv}>
                <h1>Lost & Found</h1>
            </div>
        </section>
        <section className = {styles.aboutDiv}>
            <img src = 'https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F0b6f2d51b7204ca5aa66519e3ddd22b1' alt = 'about' />
            <div>
                <h1>About</h1>
                <p>Pet Lost And Found services assist in reuniting lost pets with their owners through databases, alerts, and community outreach. They facilitate reporting of lost pets and offer resources like online platforms and social media to spread awareness. These services provide support and guidance for pet owners during the stressful experience of a lost pet. Conversely, they aid in the identification and safe return of found pets to their rightful owners, ensuring happy reunions and reducing the number of homeless animals. Such initiatives strengthen community bonds and promote responsible pet ownership.</p>
                <button onClick ={handleNavgate}>Get Started</button>
            </div>
        </section>
        <section className = {styles.reunitedDiv}>
            <h1>Reunited</h1>
            <div className = {styles.reunitedCardsDiv}>
                <div className = {styles.reunitedCard}>
                    <img src = 'https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F75843318e9124f72b1f4d9e00a6b747f' alt = 'reunited Card' />
                    <div>
                        <h1>Snoopy x Shruthi</h1>
                        <p>Lorem Ipsum is simply dummy text of dolar to the printing and typesetting <span className = {styles.readMore}>Read More.</span></p>
                    </div>
                </div>
                <div className = {styles.reunitedCard}>
                    <img src = 'https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F75843318e9124f72b1f4d9e00a6b747f' alt = 'reunited Card' />
                    <div>
                        <h1>Snoopy x Shruthi</h1>
                        <p>Lorem Ipsum is simply dummy text of dolar to the printing and typesetting <span className = {styles.readMore}>Read More.</span></p>
                    </div>
                </div>
                <div className = {styles.reunitedCard}>
                    <img src = 'https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F75843318e9124f72b1f4d9e00a6b747f' alt = 'reunited Card' />
                    <div>
                        <h1>Snoopy x Shruthi</h1>
                        <p>Lorem Ipsum is simply dummy text of dolar to the printing and typesetting <span className = {styles.readMore}>Read More.</span></p>
                    </div>
                </div>
            </div>
        </section>
        <LostAndFoundModalLogin
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleLoginRedirect}
        message="Please log in to continue."
        confirmText="Log in"
      />
       <Footer />
    </>
  );
};

export default LostAndFoundLandingPage;