import React, { useState, useRef, useEffect } from "react";
import styles from "./Tinder.module.css";
import { useNavigate } from "react-router";
import {ChatIcon,EditIcon,LikeIcon,LikedIcon,LocationIcon,MenuIcon,} from "../../assets/SVG";
import OptionsPopup from "./Popups/OptionsPopup/OptionsPopup";
import NotCompletedPopup from "./Popups/NotCompletedPopup/NotCompletedPopup";
import { BACKEND_URL, formatDate } from "../../Utilities/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebApimanager } from "../../WebApiManager";
import ChatPopupHomePage from "./Popups/ChatPopup/ChatPopupHomePage";

const PetProfileCard = ({ petData, type,setIspetInfoPopupOpen,setIsUpdatePopupOpen,setIsChatPopupOpen2, sendPetData,onFindMatches,setSelectedCardData,allPetData,oldPetId, onCardClick,setsendingOwnerDetials,setsendingOwnerDetials2}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const [isNCPopupOpen, setIsNCPopupOpen] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [location, setLocation] = useState(petData.location);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedPetData, setSelectedPetData] = useState({});
  const webApi = new WebApimanager();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPetInfo,setSelectedPetInfo]=useState(null)
  const [fav, setFav] = useState(petData.fav)
    const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);
  
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  useEffect(() => {
  }, [oldPetId]);
  const isButtonDisabled = !petData?.petNeed && !petData?.livePet;
  const handleMenuClick = () => {
    setSelectedPetData(petData);
    setIsOptionsMenuOpen(!isOptionsMenuOpen);
  };


  console.log(petData,"dsdsds") 
  const closeOptionsMenu = () => {
    setIsOptionsMenuOpen(false);
  };

 const handleClickLike = async(newPetId,currentSavedState) => {
    setFav((prev)=>prev==="yes"?"no":"yes")
 const payload={
    favouritepetId:newPetId
 }
 const payload2={
    favouritePetId:newPetId
 }
 let url;
 if (petData.fav === "yes") {
    
   url = `petProfile/deleteFavPet/${oldPetId}`;

 } else {

   url = `petProfile/updateFavPet/${oldPetId}`;

 }

 try {
   const response = petData.fav === "yes" 
   ? await webApi.delete(url,payload2)  
   : await webApi.put(url,payload);   
   console.log(response, "responseresponse");
} catch (err) {
 console.error("Error occurred:", err);
 setError("Something went wrong. Please try again later.");
} finally {
 setLoading(false);
}
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };
  const handleEditLocation = () => {
    setIsEditingLocation(true);
  };

  const handleLocationSubmit = async () => {
    const payload = {
      location: location,
    };
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const response = await webApi.put(
        `petProfile/update/${petData._id}`,
        payload
      );
      console.log(response, "responseresponse");
      toast.success("location Update Successfully!");
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
      toast.error("This is an error message!");
    } finally {
      setLoading(false);
    }
    setIsEditingLocation(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeOptionsMenu();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFindMatchesClick = () => {
    if (onFindMatches) {
      onFindMatches(petData);
      setSelectedPetData(petData);
    }
    console.log(petData, "check pet card");
    navigate("/tinder/matches", { state: { selectedPet: petData, oldpetid:petData._id,selectedPetInfo } });
  };
  useEffect(() => {
    if (Object.keys(selectedPetData).length > 0) {
      setSelectedCardData(selectedPetData);
    }
  }, [selectedPetData]);

  const calculateProfileCompletion = (pet) => {
    const fields = [
      pet.DNACertificate,
      pet.DNATested,
      pet.birthday,
      pet.breed,
      pet.color,
      pet.description,
      pet.hasYourPetBeenTestedForAnyBreedSpecificHealthConditionsOrGeneticDisOrders,
      pet.livePet,
      pet.location,
      pet.pedigreeCertificate,
      pet.pedigreeCertified,
      pet.petAge,
      pet.petGender,
      pet.petImage,
      pet.vaccinated,
      pet.vaccineCertificate,
      pet.weight,
    ];
    const totalFields = fields.length;
    const weightPerField = 100 / totalFields;
    const validFieldsCount = fields.filter((field) => {
      if (Array.isArray(field)) {
        return field.length > 0;
      }
      return field && field !== "";
    }).length;
    let basePercentage = validFieldsCount * weightPerField;
    const penaltyFields = [
      pet.livePet,
      pet.pedigreeCertificate,
      pet.DNACertificate,
    ];
    const penalty =
      penaltyFields.filter((field) => !field || field === "").length * 10;
    let finalPercentage = basePercentage - penalty;
    finalPercentage = Math.max(finalPercentage, 0);
    return finalPercentage.toFixed(2);
  };
  const handleClick = () => {
    if (onCardClick) {
      onCardClick(petData);
    }
    setIspetInfoPopupOpen(true)
  };
  console.log(petData,"checking petowner detials")


  return (
    <>
      <ToastContainer />
      <section className={`${styles.petProfileCard} `}  onClick={(e) => { e.stopPropagation(); if (type === "match") {handleClick();setIspetInfoPopupOpen(true); }}} style={{ cursor: type === "match" ? "pointer" : "", width: type === "match" ? "23%" : "30%", }}>
        {petData.petStatus === "Hide" && (
          <div className={styles.hiddenBand}>
            <p>Hide</p>
          </div>
        )}
     {!(type === "match") ? (
  <div style={{position:"absolute",justifyContent:"end", // Adjust as needed
 right:"-18px",
 top:"-20px",
    display: "flex",


  }}           onClick={() => setIsChatPopupOpen((prev) => !prev)}
  disabled={isButtonDisabled}className={`${  isButtonDisabled ? styles.disabledButton : ""}  , ${petData.petStatus === "Hide" ? styles.hiddenCard : ""}`} >
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
      <g filter="url(#filter0_d_3407_9682)">
        <circle cx="25" cy="25" r="15" fill="#F5790C"/>
      </g>
      <path 
        fill-rule="evenodd" 
        clip-rule="evenodd" 
        d="M20.0005 24.4081C20.0005 22.1659 22.3161 20.3999 25.099 20.3999C27.8819 20.3999 30.1975 22.1659 30.1975 24.4081C30.1975 26.347 28.461 27.9324 26.1978 28.3226L26.183 28.3253L26.1742 28.333C25.9927 28.4919 25.8452 28.6571 25.7561 28.8215C25.6368 29.0419 25.5695 29.3565 25.5338 29.6395L25.5324 29.649C25.518 29.7644 25.5055 29.8636 25.4874 29.9356C25.4781 29.9727 25.4647 30.0143 25.4424 30.051C25.4178 30.0904 25.3769 30.132 25.3129 30.1488C25.2507 30.1646 25.1946 30.1497 25.1528 30.1275C25.1143 30.1076 25.0818 30.0782 25.0554 30.051C25.0043 29.9989 24.9445 29.9211 24.8753 29.8314L23.6749 28.2696C23.6702 28.2637 23.6665 28.2592 23.6637 28.2551C23.6582 28.2537 23.6521 28.2524 23.6443 28.2506C21.5588 27.7639 20.0005 26.2438 20.0005 24.4081Z" 
        fill="white"
      />
      <defs>
        <filter id="filter0_d_3407_9682" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3407_9682"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3407_9682" result="shape"/>
        </filter>
      </defs>
    </svg>
  </div>
) : null}

        <img   src={petData.petImage ? `${BACKEND_URL}${petData.petImage}` : "https://img.freepik.com/premium-vector/no-dog-allowed-sign_36244-493.jpg?semt=ais_hybrid"} alt="Pet Profile" className={`${petData.petStatus === "Hide" ? styles.hiddenCard : ""}`}     
         />
        <div className={styles.petProfileCard2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>{petData.petName}</h1>
            {type === "match" ? (
              <div style={{ display: "flex", gap: "15px" }}>
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsChatPopupOpen2(true);
                    setsendingOwnerDetials(petData.petOwner)
                    setsendingOwnerDetials2(petData)
                  }}
                >
                  <ChatIcon />
                </div>
                <div onClick={(e)=>{ e.stopPropagation();  handleClickLike(petData._id,petData.fav)}}> {fav ==="yes" ? <LikedIcon /> : <LikeIcon />}</div>
              </div>
            ) : (
              <div style={{ cursor: "pointer", position: "relative" }}>
                <div onClick={handleMenuClick}>
                  <MenuIcon />
                </div>
                {isOptionsMenuOpen && selectedPetData && (
                  <div ref={menuRef}>
                    <OptionsPopup  onClose={closeOptionsMenu} petData={selectedPetData} />
                  </div>
                )}
              </div>
            )}
          </div>
          <p  style={{
    marginTop: type === "match" ? "12px" : "5px",
    ...(type === "match" && { marginBottom: "12px" }), // Only add marginBottom if type === "match"
  }}>
            {petData.breed}, {petData.petAge}, {petData.petGender}
          </p>
          <div style={{ display: "flex", gap: type === "match" ? "5px" : "15px" }} >
            {type === "match" && (
              <div style={{ marginTop: "3px" }}>
                <LocationIcon />
              </div>
            )}
            {isEditingLocation ? (
              <input type="text" value={location} onChange={handleLocationChange} onBlur={handleLocationSubmit} autoFocus className={styles.locationInput} />
            ) : (
              <p>{location}</p>
            )}
            {type !== "match" && (
              <div style={{ cursor: "pointer", marginTop: "-4px" }} onClick={handleEditLocation} className={`${ petData.petStatus === "Hide" ? styles.hiddenCard : "" }`} >
                <EditIcon />
              </div>
            )}
          </div>
          {type !== "match" && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }} className={`${  petData.petStatus === "Hide" ? styles.hiddenCard : ""  }`} >
                <p style={{ fontSize: "9px", marginBottom: "2px" }}>
                  Profile Completion
                </p>
                <p style={{ fontSize: "9px", fontWeight: 600, marginBottom: "2px", }}  >
                  {calculateProfileCompletion(petData)}%
                </p>
              </div>
              <div className={styles.progressBar1}>
                <div style={{ backgroundColor: calculateProfileCompletion(petData) === "100.00" ? "#209832" : "#1FBFC2", width: `${calculateProfileCompletion(petData)}%`,  height: "5px", }} ></div>
                <div className={`${styles.StatusPill}`} style={{ width: "60px", backgroundColor: calculateProfileCompletion(petData) === "100.00" ? "#a7f69d " : "#1FBFC21A",
                 //  width: `${calculateProfileCompletion(petData)}%`,
                 overflow: "visible",
                    position: "absolute",
                    top: "-15px",
                    left: "35%",
                    textAlign: "center",
                    padding: "2px 7px",
                    cursor: "pointer"
                  }} onClick={() => {
                    if (petData.petStatus !== "Hide") {
                      setSelectedPetData({});
                      setTimeout(() => {
                        setSelectedPetData(petData);
                      }, 0);
                      setIsNCPopupOpen(true);
                    } else {
                      console.log(
                        "Action disabled because the pet status is hidden."
                      );
                    }
                  }}
                >
                  <p
                    className={`${
                      petData.petStatus === "Hide" ? styles.hiddenCard : ""
                    }`} style={{ color: calculateProfileCompletion(petData) === "100.00" ? "#209832" : "#1FBFC2", fontFamily: "Poppins", fontSize: "6px", fontWeight: "400", margin: "0px" }} >
                     {calculateProfileCompletion(petData) === "100.00" ? "Completed" : "Not Completed"}
               </p>
                </div>
              </div>
              <p style={{ color: "#1FBFC2" }}>
                You Got {petData.matches} Matches
              </p>

              <button onClick={handleFindMatchesClick} disabled={isButtonDisabled}className={`${  isButtonDisabled ? styles.disabledButton : ""}  , ${petData.petStatus === "Hide" ? styles.hiddenCard : ""}`} >
                Find Matches
              </button>
            </>
          )}
        </div>
        <ChatPopupHomePage   isChatPopupOpen={isChatPopupOpen}
          setIsChatPopupOpen={setIsChatPopupOpen}
          selectedPet={petData} 
           />
          
        <NotCompletedPopup isNCPopupOpen={isNCPopupOpen} setIsNCPopupOpen={setIsNCPopupOpen} setIsUpdatePopupOpen={setIsUpdatePopupOpen} setPhotoUrl={setImageUrl} setClipUrl={setVideoUrl} petData={selectedPetData} />
      </section>
    </>
  );
};

export default PetProfileCard;