import React, { useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";
import styles from "./ChatPopup.module.css";
import {
  BackIconBlack,
  CrossIconBlack,
  MenuIcon,
  SendIcon,
  SentIcon,
} from "../../../../assets/SVG";
import { ChatMenu } from "../../constants";
import { WebApimanager } from "../../../../WebApiManager";
import { BACKEND_URL, SOCKET_SERVER_URL } from "../../../../Utilities/Constant";
import { getRecoil } from "recoil-nexus";
import { useRecoilState } from "recoil";
import { jwtToken, userInfo } from "../../../../Atoms";
import ParticipantChatScreen from "./ParticipantChatScreen";
import msgcorrect from "../../../../assets/images/msgcorrect.png";
import profilepic from "../../../../assets/images/profile-pic2.png";

const ChatPopupHomePage = ({
  isChatPopupOpen,
  setIsChatPopupOpen,
  currentUserId,
  selectedPet,
}) => {
  const webApi = new WebApimanager();
  const popupRef = useRef(null);
  const [isChatscreenOpen, setIsChatscreenOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(ChatMenu[0]);
  const [messages, setMessages] = useState(selectedChat.messages || []);
  const [newMessage, setNewMessage] = useState("");
  const [allchat, setAllChat] = useState([]);
  const [loading, setLoading] = useState(false);

  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [currentUser] = useRecoilState(userInfo);
  const [conversationId, setConversationId] = useState(null);
  const [receiverId, setReceverId] = useState(null);
  const [senderPetId, setSenderPetId] = useState(null);
  const userId = currentUser._id;
  let jwttoken = getRecoil(jwtToken);

  //   useEffect(() => {
  //     if (!userId) return;
  //     // Initialize Socket.IO
  //     const newSocket = io(SOCKET_SERVER_URL, {
  //       query: { userId },
  //     });

  //     setSocket(newSocket);

  //     newSocket.on("getOnlineUsers", (users) => {
  //       setOnlineUsers(users);
  //     });

  //     // Fetch Conversations
  //     const fetchConversations = async () => {
  //       try {
  //         const response = await fetch(
  //           ${SOCKET_SERVER_URL}api/message/conversations,
  //           {
  //             method: "GET",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + jwttoken,
  //             },
  //           }
  //         );

  //         if (!response.ok) {
  //           throw new Error(Error: ${response.status});
  //         }

  //         const data = await response.json();
  //         setConversations(data);

  //         console.log(data, "conversiton");
  //         data.forEach((conversation) => {
  //             console.log(conversation.lastMessage.conversationId,"conversiton_id");
  //           });

  //       } catch (error) {
  //         console.error("Error fetching conversations:", error.message);
  //       }
  //     };

  //     fetchConversations();

  //     return () => {
  //       newSocket.disconnect();
  //     };
  //   }, [userId, jwttoken]);

  console.log(selectedPet._id, "chatpetidc");
  useEffect(() => {
    if (!userId) return;

    // Initialize Socket.IO
    const newSocket = io(SOCKET_SERVER_URL, {
      autoConnect: false,
      query: { userId },
    });
    setSocket(newSocket);

    // Listen for online users
    newSocket.on("getOnlineUsers", (users) => {
      // Handle online users
    });

    // Fetch Conversations

    const fetchConversations = async () => {
      try {
        const response = await fetch(
          `${SOCKET_SERVER_URL}api/message/conversationsByPetId/${selectedPet._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwttoken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setConversations(data.conversations || []); // Ensure it's an array
      } catch (error) {
        console.error("Error fetching conversations:", error.message);
      }
    };

    fetchConversations();

    // Clean up the socket connection on unmount
    return () => {
      newSocket.disconnect();
    };
  }, [userId, jwttoken, selectedPet._id]);

  useEffect(() => {
    if (currentUserId) {
      const newSocket = io(SOCKET_SERVER_URL, {
        query: { userId: currentUserId },
      });
      setSocket(newSocket);

      // Listen for incoming messages
      newSocket.on("receiveMessage", (message) => {
        if (message.chatId === selectedChat.chatId) {
          setMessages((prevMessages) => [...prevMessages, message]);
        }
      });

      return () => {
        newSocket.disconnect();
      };
    }
  }, [currentUserId, selectedChat]);

  // Handle closing popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsChatPopupOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsChatPopupOpen]);

  // Send message API call
  const sendMessagebutton = async () => {
    const messageContent = messages[conversationId] || "";
    if (!messageContent.trim()) return; // Prevent empty messages

    const payload = {
      sender: userId,
      receiver: receiverId,
      content: messageContent,
      conversationId,
      senderPetId: selectedPet._id,
      receiverPetId: senderPetId,
    };

    setLoading(true);

    try {
      // Send the message to the server
      const response = await fetch(`${SOCKET_SERVER_URL}api/message/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwttoken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok)
        throw new Error(`Failed to send message: ${response.status}`);

      const sentMessage = await response.json();

      // Emit the message through socket
      socket.emit("sendMessage", sentMessage);

      // Update the messages for the current conversation in the state
      setMessages((prevMessages) => ({
        ...prevMessages,
        [conversationId]: [
          ...(prevMessages[conversationId] || []),
          sentMessage,
        ],
      }));

      // Update the allChat object to reflect the new message in the UI
      setAllChat((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), sentMessage],
      }));

      // Update the conversations list with the latest message
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv._id === conversationId
            ? {
                ...conv,
                lastMessage: sentMessage,
                timestamp: new Date(), // Update timestamp for sorting
              }
            : conv
        )
      );

      // Optionally, update the lastSentByUser state
      //  setLastSentByUser(sentMessage.sender._id);

      // Clear the input field for the conversation
      setMessages((prevMessages) => ({
        ...prevMessages,
        [conversationId]: "",
      }));
    } catch (error) {
      console.error("Error sending message:", error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCheckReciever = (item) => {
    const filterReciever = item?.lastMessage.receiverPetId.filter(
      (rec) => rec._id !== userId
    );

    if (filterReciever.length > 0) {
      setReceverId(filterReciever[0]._id);
    } else {
      // Handle case where no receiver is found (optional)
      console.log("No receiver found");
    }
  };

  console.log(receiverId, "kkkkppp");

  useEffect(() => {
    if (!userId || !jwttoken || conversationId) return;

    const fetchConversations = async () => {
      try {
        // Set loading state to true before fetching data
        //   setLoading(true);
        console.log(conversationId, "spspspsp");

        const response = await fetch(
          //   ${SOCKET_SERVER_URL}api/message/ByReceiver/${data.conversations.sender?._id}?senderPetId=${data.conversations.senderPetId?._id}&receiverPetId=${data.conversations.receiverPetId?._id},
          `${SOCKET_SERVER_URL}api/message/${conversationId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwttoken}`,
            },
          }
        );

        // Handle non-OK responses
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setAllChat(data);
        // Update state with fetched messages
        if (data?.messages?.length > 0) {
          //   setConversationsSingle(data.messages);
          console.log("Fetched messages home:", data.messages);
        } else {
          console.log("No data found");
          //   setConversationsSingle(null);
        }
      } catch (error) {
        console.error("Error fetching conversations:", error.message);
      } finally {
        // Reset loading state after data is fetched or an error occurs
        //   setLoading(false);
      }
    };
    fetchConversations();
    // Clean up socket on component unmount or when dependencies change
    return () => {
      socket?.disconnect();
    };
  }, [userId, jwttoken, conversationId]);

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const handleInputChange = (conversationId, value) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [conversationId]: value,
    }));
  };

  return (
    <>
      {isChatPopupOpen && (
        <div className={styles.Background}>
          <div
            style={{
              backgroundColor: "white",
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
              overflow: "hidden",
              height: "auto",
              fontFamily: "Poppins",
              width: "30%",
              maxWidth: "450px",
              /* bottom: 125px; */
              /* right: 100px; */
              position: "absolute",
            }}
            ref={popupRef}
          >
            {/* Header Section */}
            <div className={styles.header}>
              <p style={{ margin: "0px", marginLeft: "25px" }}>
                Chat | {selectedPet.petName}
              </p>
              <div
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={() => setIsChatPopupOpen(false)}
              >
                <CrossIconBlack />
              </div>
            </div>

            {/* Main Content */}
            <div className={styles.mainContainer}>
              {/* Conditional Rendering: Chat List or Individual Chat Screen */}
              {!isChatscreenOpen ? (
                // Chat List
                <>
                  {conversations.map((item, index) => (
                    <div
                      className={styles.chat}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        console.log(item, "Clicked Item"); // Log the clicked item to debug

                        if (
                          !item.participants ||
                          item.participants.length === 0
                        ) {
                          console.error(
                            "No participants available for this item"
                          );
                          return;
                        }

                        const filteredReceiver = item.participants.filter(
                          (participant) => participant?._id !== userId
                        );

                        if (item._id) {
                          setConversationId(item._id);
                          console.log("Assigned conversationId:", item._id);
                        } else {
                          console.error("No conversation ID found");
                        }

                        if (filteredReceiver.length > 0) {
                          // Log the receiverPetId _id here
                          console.log(
                            "Receiver Pet ID:",
                            item.lastMessage?.receiverPetId?._id
                          );
                          setReceverId(item.lastMessage?.receiverPetId?._id);
                        } else {
                          console.error(
                            "No valid receiver found for this conversation"
                          );
                        }
                        //   setIsChatPopupOpen(false)
                        setIsChatscreenOpen(true);
                        setSelectedChat(item);
                        setConversationId(
                          item.lastMessage?.conversationId || null
                        );
                        setSenderPetId(
                          item.lastMessage?.senderPetId?._id || null
                        );
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {item.participants
                          .filter((participant) => participant._id !== userId) // Exclude current user
                          .map((participant, pIndex) => (
                            <div
                              key={pIndex}
                              style={{ display: "flex", gap: "15px" }}
                            >
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  backgroundColor: "#ccc",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  color: "#fff",
                                  fontWeight: "bold",
                                }}
                              >
                                {participant?.name?.charAt(0) || "N/A"}
                              </div>
                              <p className={styles.name}>
                                {participant?.name || "Unknown Name"} |{" "}
                                {item.lastMessage?.receiverPetId?.petName ||
                                  "Unknown Pet Name"}
                              </p>
                            </div>
                          ))}
                      </div>
                      <p className={styles.time} style={{ color: "#999" }}>
                        {new Date(
                          item.timestamp || item.createdAt
                        ).toLocaleString()}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className={styles.chatPopupContainer}>
                    <div className={styles.lostAndFoundChatPopupBody}>
                      <div className={styles.SingleChatChatBody}>
                        {/* Reverse the order of the messages */}
                        {Array.isArray(allchat.messages) &&
                          Object.entries(
                            allchat.messages
                              .slice()
                              .sort(
                                (a, b) =>
                                  new Date(a.timestamp) - new Date(b.timestamp)
                              ) // Sort messages by timestamp (oldest first)
                              .reduce((groupedMessages, msg) => {
                                const messageDate = new Date(msg.timestamp);
                                const today = new Date();
                                const diffTime =
                                  today.getTime() - messageDate.getTime();
                                const diffDays = Math.floor(
                                  diffTime / (1000 * 60 * 60 * 24)
                                );

                                let groupLabel = "";

                                if (diffDays === 0) groupLabel = "Today";
                                else if (diffDays === 1)
                                  groupLabel = "Yesterday";
                                else
                                  groupLabel = messageDate.toLocaleDateString();

                                if (!groupedMessages[groupLabel])
                                  groupedMessages[groupLabel] = [];
                                groupedMessages[groupLabel].push(msg);

                                return groupedMessages;
                              }, {})
                          )
                            .reverse() // Reverse the order to show "Today" at the bottom
                            .map(([dateLabel, messages]) => (
                              <div key={dateLabel}>
                                {/* Date Label */}
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    margin: "10px 0",
                                  }}
                                >
                                  {dateLabel}
                                </div>

                                {/* Messages */}
                                {messages.map((msg, index) => {
                                  const isCurrentUser =
                                    msg.sender._id === userId; // Check if the message sender is the current user

                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        justifyContent: isCurrentUser
                                          ? "flex-end"
                                          : "flex-start", // Align messages based on sender
                                        marginBottom: "20px", // Space between messages
                                      }}
                                    >
                                      {/* Message from current user */}
                                      {isCurrentUser ? (
                                        <>
                                          <div className={styles.messageBubble}>
                                            <p
                                              className={
                                                styles.SingleChatChatmsgs
                                              }
                                            >
                                              {msg.content}
                                            </p>
                                            <span
                                              className={styles.messageTime}
                                            >
                                              {formatTime(msg.timestamp)}
                                            </span>
                                          </div>
                                          <div>
                                            <img
                                              src={msgcorrect}
                                              alt="sent"
                                              style={{
                                                paddingTop: "12px",
                                                width: "15px",
                                                height: "25px",
                                                marginLeft: "10px",
                                              }}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        // Message from other user
                                        <>
                                          <div>
                                            <img
                                              src={profilepic}
                                              alt=""
                                              className={styles.profilePic}
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                            />
                                          </div>
                                          <div className={styles.messageBubble}>
                                            <p
                                              className={
                                                styles.SingleChatChatmsgs
                                              }
                                            >
                                              {msg.content}
                                            </p>
                                            <span
                                              className={styles.messageTime}
                                            >
                                              {formatTime(msg.timestamp)}
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                      </div>
                    </div>

                    {/* Input - Fixed at the Bottom */}
                    <div style={{ position: "relative", width: "100%" }}>
                      <input
                        placeholder="Send Message"
                        value={messages[conversationId] || ""}
                        onChange={(e) =>
                          handleInputChange(conversationId, e.target.value)
                        }
                        style={{
                          width: "100%",
                          padding: "10px",
                          paddingRight: "40px", // Space for the button
                          border: "1px solid #ccc",
                          fontSize: "14px",
                        }}
                      />
                      <button
                        onClick={sendMessagebutton}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {loading ? (
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div class={styles.ldsspinner}>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        ) : (
                          <SendIcon />
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatPopupHomePage;
