import React, { useState, useRef, useEffect } from 'react';
import styles from './FiltersPopup.module.css';
import { CrossIconBlack } from '../../../../assets/SVG';
import DropDownV1 from '../../../UI/DropDownV1';
import ToggleSwitch from '../../../UI/ToggleSwitch';
import { WebApimanager } from "../../../../WebApiManager";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeimg from "../../../../assets/images/close.png";
import DropDownWithCheckbox from '../../../UI/DropDownWithCheckbox';
import { color } from 'framer-motion';


// DropDownWithCheckbox
const FiltersPopup = ({ isFiltersPopupOpen, setIsFiltersPopupOpen, updateFilters,sendingPetType,handleRemoveFilter2 }) => {
    const popupRef = useRef(null);
  const webApi = new WebApimanager();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(null);
    const [distance, setDistance] = useState(1370);  
    const [size, setSize] = useState('Select Size');
    const [pureBreedOnly, setPureBreedOnly] = useState(false);
    const [pedigreeCertifiedOnly, setPedigreeCertifiedOnly] = useState(false);
    const [dnaCertifiedOnly, setDnaCertifiedOnly] = useState(false);
      const [breedOptions, setBreedOptions] = useState([]);   
    const handleDistanceChange = (e) => {
        setDistance(e.target.value);
        updateFilters({ distance: e.target.value });
    };

    const handleSizeChange = (value) => {
        setSize(value);
        updateFilters({ size: value });
    };

    useEffect(() => {
        console.log('pureBreedOnly changed to:', pureBreedOnly);
        updateFilters({ pureBreedOnly });
      }, [pureBreedOnly]);
    
      const handlePureBreedToggle = () => {
        console.log('Toggling pureBreedOnly'); 
        setPureBreedOnly((prevState) => {
          const newState = !prevState;
          console.log('New state:', newState); 
          return newState;
        });
      };
    const handlePedigreeToggle = () => {
        setPedigreeCertifiedOnly(prevState => !prevState);
        updateFilters({ pedigreeCertifiedOnly: !pedigreeCertifiedOnly });
    };

    const handleDnaToggle = () => {
        setDnaCertifiedOnly(prevState => !prevState);
        updateFilters({ dnaCertifiedOnly: !dnaCertifiedOnly });
    };

    useEffect(() => {
        if (isFiltersPopupOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => document.body.classList.remove('no-scroll');
    }, [isFiltersPopupOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsFiltersPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsFiltersPopupOpen]);


    
  const getDogTypes = async () => {
    try {
      const response = await webApi.get("dogAndCat/dogTypes");
      if (response.status === 200) {
        const breedNames = response.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.error("Error fetching dog types:", error.message);
    }
  };

  const getCatTypes = async () => {
    try {
      const response = await webApi.get("dogAndCat/catTypes");
      if (response.status === 200) {
        const breedNames = response.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
        
      }
    } catch (error) {
      console.error("Error fetching cat types:", error.message);
    }
  };
   useEffect(() => {
      if (sendingPetType === "Dog") {
        getDogTypes();
      } else if (sendingPetType === "Cat") {
        getCatTypes();
      } else {
        setBreedOptions([]);
      }
    }, [sendingPetType]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const reorderedBreedOptions = () => {    
        const filteredOptions = breedOptions.filter((option) => option !== size);
        const reorderedOptions = size !== "Select Size" ? [size, ...filteredOptions] : filteredOptions;
    
        return [ ...reorderedOptions];
    };
    const handleSizeChange2 = (value) => {
   
        setSelectedOptions((prev) => {
            const updatedOptions = prev.includes(value)
                ? prev.filter((option) => option !== value)
                : [...prev, value]; 
    
            updateFilters({ selectedBreeds: updatedOptions });
            return updatedOptions;
        });
    };
    const handleRemoveOption = (option) => {
        setSelectedOptions((prev) => {
          const updatedOptions = prev.filter((selected) => selected !== option);
          updateFilters({ selectedBreeds: updatedOptions }); // Update filters after removal
          handleRemoveFilter2(option);  // This removes the filter in TinderMatches
          return updatedOptions;
        });
      };
      
    
    return (
        <>
        
            {isFiltersPopupOpen && (
                <div className={styles.Background}>
                    <div className={styles.popupContainer} ref={popupRef}>
                        <div className={styles.header}>
                            <p></p>
                            <p style={{ margin: '0px' }}>Filters</p>
                            <div style={{ marginRight: '25px', cursor: 'pointer' }} onClick={() => {
                                setIsFiltersPopupOpen(false)
                            }}><CrossIconBlack style={{color:"green"}}/></div>
                        </div>
                        <div className={styles.mainContainer}>
                            <p>Pets within a distance of {distance} Miles</p>
                            <input
                                type='range'
                                min='0'
                                max='5000'
                                value={distance}
                                onChange={handleDistanceChange}
                                style={{ backgroundColor: '#FFFFFF', border: 'none' }}
                            />
<DropDownWithCheckbox
  mandatory={true}
  heading={"Select Breed Name"}
  listItems={reorderedBreedOptions().map(option => ({ id: option, name: option }))}
  selectedIds={selectedOptions} 
  setSelectedIds={(ids) => {
    setSelectedOptions(ids);
    updateFilters({ selectedBreeds: ids });
  }}
  handleSelectedPropertyOption={(data) => {
    handleSizeChange2(data.id); 
  }}
  clearAll={() => {
    setSelectedOptions([]);
    updateFilters({ selectedBreeds: [] }); 
  }}
  addAll={() => {
    const allBreedIds = reorderedBreedOptions().map(option => option); 
    setSelectedOptions(allBreedIds);
    updateFilters({ selectedBreeds: allBreedIds }); 
  }}
  placeholder="Select Breed"
  Customdropdown={{ width: "100%" }}
  style={{ color: 'black' }}
/>


            {selectedOptions.length > 0 && (
<div>
    <p style={{marginTop:"10px"}}>Selected Breeds:</p> 
    <div style={{
        display: "flex",
        flexWrap: "wrap",  
        gap: "10px", 
     
    }}>
        <ul style={{ listStyleType: "none", padding: 0, display: "flex", flexWrap: "wrap" }}>
            {selectedOptions.map((option, index) => {
                let backgroundColor = "#F1F1F1";
                let borderColor = "transparent";
                let justifyContent = "flex-start";

                return (
                    <li key={index} style={{
                        display: "inline-flex", 
    alignItems: "center",
    marginBottom: "5px",
    justifyContent: justifyContent, 
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap", 
    border: `2px solid ${borderColor}`, 
    padding: "5px", 
    borderRadius: "4px", 
    marginLeft: "5px",
    backgroundColor: backgroundColor, 
                    }}>
                        <span style={{
                            marginRight: "10px",
                            color: "#727271",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap", 
                            maxWidth: "calc(100% - 0px)", 
                        }}>
                            {option}
                        </span>
                        <button
                            onClick={() => handleRemoveOption(option)}
                            style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            <img src={closeimg} alt="Remove" />
                        </button>
                    </li>
                );
            })}
        </ul>
    </div>
</div>

)}


                            <DropDownV1
                                question={'Size'}
                                options={['Select Size', 'Small', 'Large', 'Medium']}
                                width='100%'
                                value={size}
                                onChange={handleSizeChange}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                                <p>Pure Breed Pets Only</p>
                                <ToggleSwitch
  checked={pureBreedOnly}  // Ensure this is connected to the correct state
  onChange={handlePureBreedToggle}
/>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                                <p>Pedigree Certified Only</p>
                                <ToggleSwitch checked={pedigreeCertifiedOnly} onChange={handlePedigreeToggle} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                                <p>DNA Certified Only</p>
                                <ToggleSwitch checked={dnaCertifiedOnly} onChange={handleDnaToggle} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FiltersPopup;
