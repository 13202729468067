import React from "react";
import styles from "./Footer.module.css";
import whatsapp from "../../assets/Blog_Icons/blogwhatsapp.svg";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/78f832d72f9f367efc0ff0882e19b65026091938beafa18c8686f7b4126d4b25?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        alt="Logo"
        className={styles.footerLogo}
      />
      <nav className={styles.footerNav}>
        <p className={styles.footerCopyright}>
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Privacy Policy
          </a>{" "}
          | © 2024 zaanvar.com | All Rights Reserved | mrchams PLT @2024
        </p>
      </nav>
      <div className={styles.socialLinks}>
        <a
          href="https://whatsapp.com/channel/0029Vavid5j0lwglQMeyO51u"
          aria-label="whatsApp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsapp} alt="whatsApp" className={styles.socialIcon} />
        </a>
        <a
          href="https://www.linkedin.com/company/zaanvar/"
          aria-label="Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba7884bde25bf0dfd146a8050c79764f13b338aefa372c70618b7711094f0e8a?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt=""
            className={styles.socialIcon}
          />
        </a>
        <a
          href=""
          aria-label="Twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/995aa139b3143c5f3bf50c2fb4d2127534320761d0fdb0e24f2fee9bd742d450?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt=""
            className={styles.socialIcon}
          />
        </a>
        <a
          href="https://www.instagram.com/zaanvar_?igsh=MTFnZDQxaDZndmh6bA%3D%3D&utm_source=qr"
          aria-label="Instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f834d2a593e4f9bb766085ed949e9f5b939d223910580cb67714670e4ca4990?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt="Instagram Icon"
            className={styles.socialIcon}
          />
        </a>

        <a
          href="https://www.facebook.com/profile.php?id=61567070092046"
          aria-label="LinkedIn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6020af5b08200c2bae4af08d24b75a0d7ea8e6902580faeebe07c879fdf7333?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
            alt=""
            className={styles.socialIcon}
          />
        </a>
      </div>
    </div>
    {/* <div className={styles.footerDivider} /> */}
  </footer>
);

export default Footer;
