import React, { useEffect, useState } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL } from "../../../Utilities/Constant";
import ParticularUserPosts from "./ParticularUserPosts";
import ParticularUserQandA from "./ParticularUserQandA";

import ParticularFollower from "./ParticularFollower";
import ParticularFollowing from "./ParticularFollowing";
import ParticularUserImagePoll from "./ParticularUserImagePoll";

const ParticularUserProfile = ({ userId }) => {
  const webApi = new WebApimanager();
  const [userProfile, setUserProfile] = useState(null);
  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);
  const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false);

  useEffect(() => {
    console.log(userId, "particular user ID");

    const fetchParticularUser = async () => {
      try {
        const response = await webApi.get(`socialMedia/all`);
        console.log("Response:", response.data.data || []);
        const allUsers = response.data.data || [];

        // Match the user based on `userId`
        const matchedUser = allUsers.find(
          (user) => user.userId._id === userId._id
        );

        if (matchedUser) {
          setUserProfile(matchedUser);
        } else {
          console.log("No user found with the provided userId");
        }
      } catch (error) {
        console.log("Server error:", error);
      }
    };

    fetchParticularUser();
  }, [userId]);

  const formatNumber = (num) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "K";
    }
    return num.toString();
  };

  const [activeTab, setActiveTab] = useState("post");

  const renderSocialMediaPages = () => {
    switch (activeTab) {
      case "post":
        return <ParticularUserPosts userId={userId._id} />;
      case "QandA":
        return <ParticularUserQandA userId={userId._id} />;
      case "poll":
        return <ParticularUserImagePoll userId={userId._id} />;
      default:
        return <ParticularUserPosts />;
    }
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <div className="socialmadia-profile">
          <div className="socialmedia-profile-left-side">
            <div className="socialmedia-profile-image-home">
              <img
                src={`${BACKEND_URL}${
                  userProfile.userId?.profile || "/default-profile.png"
                }`}
                alt="Profile"
              />
            </div>
            <div className="socialmedia-profile-text">
              <div className="socialmedia-profile-name">
                {userProfile.userId?.name || "User Name"}
              </div>
              <div className="socialmedia-profile-description">
                Hello! I’m a proud pawrent of
              </div>
            </div>
          </div>

          <div className="socialmedia-profile-right-side">
            <div className="top-buttons">
              <button className="socialmedia-button">Follow</button>
              <button className="socialmedia-button">Share</button>
            </div>
            <div className="bottom-buttons">
              <p className="socialmedia-account-details">
                {formatNumber(userProfile.posts?.length || 0)} Posts
              </p>
              <p
                className="socialmedia-account-details"
                onClick={() => {
                  console.log("Opening Followers Modal");
                  setIsFollowersModalOpen(true);
                }}
              >
                {formatNumber(userProfile.followers?.length || 0)} Followers
              </p>
              <p
                className="socialmedia-account-details"
                onClick={() => setIsFollowingModalOpen(true)}
              >
                {formatNumber(userProfile.following?.length || 0)} Following
              </p>
            </div>
          </div>
        </div>
        <div className="socialmedia-menu-container">
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "post" ? "active" : ""
            }`}
            onClick={() => setActiveTab("post")}
          >
            POSTS
          </div>
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "QandA" ? "active" : ""
            }`}
            onClick={() => setActiveTab("QandA")}
          >
            Q/A
          </div>
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "poll" ? "active" : ""
            }`}
            onClick={() => setActiveTab("poll")}
          >
            POLL
          </div>
        </div>
        <div>{renderSocialMediaPages()}</div>
      </div>

      <ParticularFollower
        isOpen={isFollowersModalOpen}
        followers={userProfile?.followers || []}
        onClose={() => setIsFollowersModalOpen(false)}
        userId={userId._id}
      />

      <ParticularFollowing
        isOpen={isFollowingModalOpen}
        following={userProfile?.following || []}
        onClose={() => setIsFollowingModalOpen(false)}
        userId={userId._id}
      />
    </div>
  );
};

export default ParticularUserProfile;
