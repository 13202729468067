import React, { useEffect, useRef, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css'; 

const DropDownWithCheckbox = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (fromButtonClick) => {
        if (fromButtonClick && !isOpen) {
            setIsOpen(true);
        } else {
            setIsOpen(!isOpen);
        }
    };

    const filteredList = props.listItems.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearch = (event) => setSearchTerm(event.target.value);

    const handleSelectAll = () => {
        if (props.selectedIds.length === filteredList.length) {
            props.clearAll();
        } else {
            props.addAll();
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <label className="form-label" style={{     color: "black",fontSize: "12px", fontWeight: "500" }}>
                {props.heading}
                {/* <span style={{ color: 'red' }}>*</span> */}
            </label>
            <div ref={dropdownRef} className="dropdown" style={{ width: '100%', position: 'relative' }}>
                <button 
                    className="btn btn-outline-secondary dropdown-toggle" 
                    type="button" 
                    onClick={() => toggleDropdown(true)}
                    style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center',borderRadius:"0px",border:"1px solid #D9D9D9",backgroundColor:"rgba(243, 243, 243, 0.5)",padding:"0.7rem 0.75rem",marginBottom:"14px"  }}c
                >
                    <div>
                        {props.selectedIds.length > 0 ? `${props.selectedIds.length} selected` : 'Select'}
                    </div>
                    <span style={{ marginLeft: 'auto' }} className="caret"></span>
                </button>
                <div className={`dropdown-menu${isOpen ? ' show' : ''}`} style={{ width: '100%', maxHeight: '40vh', overflowY: 'auto', position: 'absolute', zIndex: 1000 }}>
                    <div style={{ zIndex: 2, position: 'sticky', top: "-9px", width: '100%', height: '17px', background: 'white' }}></div>
                    <div className="px-2 mb-2" style={{ position: 'sticky', top: '8px', zIndex: 1, backgroundColor: 'white' }}>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Search..." 
                            value={searchTerm} 
                            onChange={handleSearch} 
                        />
                    </div>
                    <div className="dropdown-item" onClick={handleSelectAll}>
                        <input type="checkbox" checked={props.selectedIds.length === filteredList.length} readOnly /> Select All
                    </div>
                    {/* Render selected items first */}
                    {props.selectedIds.map((selectedId) => {
                        const selectedItem = filteredList.find(item => item.id === selectedId);
                        if (selectedItem) {
                            return (
                                <div 
                                    key={selectedItem.id} 
                                    className={`dropdown-item${props.selectedIds.includes(selectedItem.id) ? ' active' : ''}`} 
                                    onClick={() => props.handleSelectedPropertyOption(selectedItem, props.type, props.selectedIds, props.setSelectedIds)} 
                                    id={`tooltip-${selectedItem.id}`}
                                >
                                    <input type="checkbox" checked={props.selectedIds.includes(selectedItem.id)} readOnly /> {selectedItem.name}
                                </div>
                            );
                        }
                        return null;
                    })}
                    {filteredList.map((option) => {
                        if (!props.selectedIds.includes(option.id)) {
                            return (
                                <div 
                                    key={option.id} 
                                    className={`dropdown-item${props.selectedIds.includes(option.id) ? ' active' : ''}`} 
                                    onClick={() => props.handleSelectedPropertyOption(option, props.type, props.selectedIds, props.setSelectedIds)} 
                                    id={`tooltip-${option.id}`}
                                    style={{whiteSpace: "nowrap", 
                                        overflow: "hidden",   
                                        textOverflow: "ellipsis", 
                                        maxWidth: "100%"}}
                                >
                                    <input type="checkbox" checked={props.selectedIds.includes(option.id)} readOnly /> {option.name}
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default DropDownWithCheckbox;
