import React, { useEffect, useState } from "react";
import "../../SocialMedia.css";
import { BACKEND_URL } from "../../../../Utilities/Constant";
import { WebApimanager } from "../../../../WebApiManager";
import { toast, ToastContainer } from "react-toastify"; // Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { useRecoilState } from "recoil";
import { userInfo } from "../../../../Atoms";

const Following = ({ isOpen, onClose, Followers }) => {
  const [followingList, setFollowingList] = useState([]);
  const webApi = new WebApimanager();

  const [currrenUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    const currectUserID = currrenUserInfo._id;
    const fetchData = async () => {
      try {
        const response = await webApi.get(
          `socialMedia/postsByUserId/${currectUserID}`
        );

        console.log(response, "follow");

        const following = response.data.profile?.following || [];
        setFollowingList(following);

        console.log(following, "particular follower");
      } catch (error) {
        console.error("Error fetching followers data:", error);
      }
    };

    fetchData();
  }, []);
  const removeFollower = async (userId) => {
    if (!userId) return alert("User not found!");

    try {
      const response = await webApi.delete(`socialMedia/unFollow/${userId}`);
      if (response.status === 200) {
        setFollowingList((prevList) =>
          prevList.filter((user) => user.user._id !== userId)
        );

        toast.success("Successfully unfollowed!");
      }
    } catch (error) {
      console.error("Error unfollowing user:", error);
      toast.error("Failed to unfollow user. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          style={{ marginTop: "40px" }}
        />
      </div>
      <div className="following-modal-overlay">
        <div className="following-modal-container">
          <div className="following-modal-header">
            <h2>Following</h2>
            <button onClick={onClose} className="following-modal-close-button">
              ×
            </button>
          </div>
          <div className="following-modal-content">
            {followingList.length > 0 ? (
              <ul className="following-user-list">
                {followingList.map((user) => (
                  <li key={user._id} className="following-user-item">
                    <div className="following-user-item-img-name">
                      <img
                        src={
                          user.user.profile
                            ? `${BACKEND_URL}${user.user.profile}`
                            : "/default-profile.png"
                        }
                        alt={user.name}
                        className="following-user-profile-image"
                      />
                      <span className="following-user-name">
                        {user.user.name}
                      </span>
                    </div>
                    <button
                      className="Following-btn"
                      onClick={() => removeFollower(user.user._id)}
                    >
                      Following
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="following-no-users">No users found.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Following;
