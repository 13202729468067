import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import { WebApimanager } from "../../../WebApiManager";

const Categories = () => {
  const webApi = new WebApimanager();
  const { type } = useParams(); // Get the category type (news, blog, etc.)

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await webApi.getwordpressData(
          `sitemap/category/${type}`
        ); // Use the type in the API call
        setCategories(response.data);
        console.log(response);
      } catch (error) {
        setError("Error fetching categories.");
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories(); // Call the async function
  }, [type]); // Dependency array with `type` ensures data is refetched when `type` changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>{type.charAt(0).toUpperCase() + type.slice(1)} Categories</h1>{" "}
      <div>{JSON.stringify(categories)}</div> {/* Display the category type */}
      <ul>
        {categories && categories.length > 0 ? (
          categories.map((category, index) => (
            <li key={index}>
              <h3>{category.name}</h3>
              <p>{category.description}</p>
              {/* If you need to add links to category pages, you can do it here */}
              <a href={`/category/${type}/${category.slug}`}>View More</a>
            </li>
          ))
        ) : (
          <li>No categories available</li>
        )}
      </ul>
    </div>
  );
};

export default Categories;
