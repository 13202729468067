import React, { useState } from 'react'
import styles from "./CustomFileUpload.module.css"

const CustomFileUpload = ({fileName, setFileName}) => {

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file ? file: ''); 
  };
  return (
    <div className={styles.fileUploadContainer}>
      <input
        type="file"
        id="docFile"
        className={styles.fileUploadInput}
        onChange={handleFileChange}
      />
      <label htmlFor="docFile" className={styles.fileUploadCustomLabel}>
        <span className={styles.fileUploadChoose}>Choose File</span>
        <span className={styles.fileUploadFilename}>{!fileName?"No Choosen File":fileName.name}</span>
      </label>
    </div>
  )
}

export default CustomFileUpload