import React, { useState,useEffect } from 'react'

import styles from "./DairyEventForms.module.css"
import CustomizedSingleRadioButton from '../UI/CustomizedSingleRadioButton';
import { ImCancelCircle } from "react-icons/im";

const AddDietaryNeedsForm = ({formData, setFormData,errors,setErrors}) => {
    const [reminderNotification, setReminderNotification] = useState('');
    
    const [reminderOptions, setReminderOptions] = useState({
        daily: "no",
        dayByDay: "no",
        weekly: "no",
        monthly: "no",
        yearly: "no",
        halfYearly: "no", 
    });
    
    const handleReminderChange = (option) => {
        console.log(option,"option")
        setReminderOptions((prevState) => {
        const updatedState = Object.keys(prevState).reduce((acc, key) => {
            acc[key] = key === option ? "yes" : "no";
            return acc;
        }, {});
        return updatedState;
        });
    };

  const handleReminderNotificationChange = (value) => {
    console.log('Reminder Notification selected:', value);
    setReminderNotification(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", 
    }));
  };
  useEffect(()=>{
        const selectedReminderOption = Object.keys(reminderOptions).find(
          (key) => reminderOptions[key] === "yes"
        );
        setFormData((prev)=>({...prev,"remindNotification":reminderNotification,"remindOption":selectedReminderOption}))
      },[reminderNotification,reminderOptions])
  

  
  return (
    <>
      <div className={styles.DateDurationMainContainer} style={{marginTop:"0px"}}>
            <div className={styles.DateContainer}>
              <label>Enter Food Preference</label>
              <input type="text" name="foodPreference" value={formData.foodPreference} onChange={handleInputChange}/>
              {errors.foodPreference && <span className={styles.errorText}>{errors.foodPreference}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Allergies</label>
              <input type="text" name="allergies" value={formData.allergies} onChange={handleInputChange}/>
              {errors.allergies && <span className={styles.errorText}>{errors.allergies}</span>}
            </div>
          </div>
          <div className={styles.DateDurationMainContainer} style={{marginTop:"0px"}}>
            <div className={styles.DateContainer}>
              <label>Protien Intake</label>
              <input type="text" name="protienIntake" value={formData.protienIntake} onChange={handleInputChange}/>
              {errors.protienIntake && <span className={styles.errorText}>{errors.protienIntake}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Carbohydrates</label>
              <input type="text" name="carbohydrate" value={formData.carbohydrate} onChange={handleInputChange}/>
              {errors.carbohydrate && <span className={styles.errorText}>{errors.carbohydrate}</span>}
            </div>
          </div>
          <div className={styles.DateDurationMainContainer} style={{marginTop:"0px"}}>
            <div className={styles.DateContainer}>
              <label>Fats</label>
              <input type="text" name="fats" value={formData.fats} onChange={handleInputChange}/>
              {errors.fats && <span className={styles.errorText}>{errors.fats}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Vitamins & Minerals</label>
              <input type="text" name="vitAndMin" value={formData.vitAndMin} onChange={handleInputChange}/>
              {errors.vitAndMin && <span className={styles.errorText}>{errors.vitAndMin}</span>}
            </div>
          </div>
          <div className={styles.DateDurationMainContainer} style={{marginTop:"0px"}}>
            <div className={styles.DateContainer}>
              <label>Fiber</label>
              <input type="text" name="fiber" value={formData.fiber} onChange={handleInputChange}/>
              {errors.fiber && <span className={styles.errorText}>{errors.fiber}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Supplements</label>
              <input type="text" name="supplements" value={formData.supplements} onChange={handleInputChange}/>
              {errors.supplements && <span className={styles.errorText}>{errors.supplements}</span>}
            </div>
          </div>
          
          

          
          <div className={styles.SelectFieldContainer}>
        <label>Description</label>
        <textarea
          name="description"
          rows={4}
          onChange={handleInputChange}
        ></textarea>
        {errors.description && <span className={styles.errorText}>{errors.description}</span>}
      </div>
          
      <div className={styles.DateDurationMainContainer}>
        <div className={styles.DateContainer}>
          <label>Reminder Notification</label>
          <div className={styles.checkboxMainContainer}>
            {["yes", "no"].map((value) => (
              <div className={styles.checkboxContainer} key={value}>
                <CustomizedSingleRadioButton
                  label={value === "yes" ? "Yes" : "No"}
                  name="reminderNotification"
                  value={value}
                  selectedValue={reminderNotification}
                  onChange={handleReminderNotificationChange}
                />
              </div>
            ))}
            {errors.remindNotification && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindNotification}</span>}
          </div>
        </div>
      </div>

      {reminderNotification === "yes" && <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Date</label>
              <input type="date" name="toRemindDate" value={formData.toRemindDate} onChange={handleInputChange}/>
              {errors.toRemindDate && <span className={styles.errorText}>{errors.toRemindDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Duration</label>
              <input type="time" name="toRemindTime" value={formData.toRemindTime} onChange={handleInputChange}/>
              {errors.toRemindTime && <span className={styles.errorText}>{errors.toRemindTime}</span>}
            </div>
      </div>}


      <div className={styles.DateDurationMainContainer}>
        <div className={styles.MdReminderContainer}>
          <label>Remind Me Every</label>
          <div className={styles.checkboxMainContainer}>
            {[
              { label: "Daily", key: "daily" },
              { label: "Day By Day", key: "dayByDay" },
              { label: "Weekly", key: "weekly" },
              { label: "Monthly", key: "monthly" },
              { label: "Yearly", key: "yearly" },
              { label: "Half Yearly", key: "halfYearly" },
            ].map((option) => (
              <div className={styles.checkboxContainer} key={option.key}>
                <CustomizedSingleRadioButton
                  label={option.label}
                  name="reminderOptions"
                  value={option.key}
                  selectedValue={Object.keys(reminderOptions).find(
                    (key) => reminderOptions[key] === "yes"
                  )}
                  onChange={() => handleReminderChange(option.key)}
                />
              </div>
            ))}
            {errors.remindOption && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindOption}</span>}
          </div>
        </div>
      </div>
    </>
  )
}


export default AddDietaryNeedsForm