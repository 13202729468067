import React, { useState } from 'react';
import styles from './ui.module.css';

const DropDownv1 = ({ question, options, width, value, onChange ,custommarginbottom}) => {
    const [isExpanded, setIsExpanded] = useState(false); 

    const handleOptionSelect = (option) => {
        if (onChange) {
            onChange(option); 
        }
    };

    const handleMoreClick = () => {
        setIsExpanded(!isExpanded); 
    };

    const visibleOptions = isExpanded ? options : options.slice(0, 3);
    const remainingOptions = options.length - 3;

    return (
        <div style={{ width: "100%" }}>
            <div style={{ borderBottom: "1px solid #F0F1F6", width: "94%", padding: "30px 35px 0px 50px" }}>
                <div className={styles.checkboxDiv} style={{ width: width }}>
                    <p className={styles.lostfoundSelectLost} style={{marginBottom:custommarginbottom?.marginBottom}}>{question}</p>
                    <div>
                        {visibleOptions.map((option) => {
                            const isSelected = value === option;
                            return (
                                <div
                                    key={option}
                                    style={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    <div className={styles.radioButton} style={{ marginRight: '10px', paddingLeft: "0.5px" }}>
                                        {isSelected && <div className={styles.radioButtonSelected}></div>}
                                    </div>
                                    <p
                                        style={{
                                            marginRight: options.length > 2 ? '30px' : '65px',
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            marginBottom: '0px',
                                            color: "#61677F"
                                        }}
                                    >
                                        {option}
                                    </p>
                                </div>
                            );
                        })}
                        {remainingOptions > 0 && !isExpanded && (
                            <div
                                style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    marginTop: '10px'
                                }}
                                onClick={handleMoreClick}
                            >
                                +{remainingOptions} More
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropDownv1;


