import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { FaHeart } from "react-icons/fa6";

const ParticularUserPosts = ({ userId }) => {
  const webApi = new WebApimanager();
  const [fileArrays, setFileArrays] = useState([]);
  const [activeIndices, setActiveIndices] = useState([]);
  const [likesData, setLikesData] = useState([]);

  useEffect(() => {
    console.log(userId, "Particular User ID Particular_User_Posts");

    const imageresponse = async () => {
      try {
        if (userId) {
          const response = await webApi.get(
            `socialMedia/postsByUserId/${userId}?type=Image`
          );
          const posts = response.data.posts || [];

          console.log(response, "post imagre");

          const fileData = posts.map((post) => post.file || []);

          const likes = posts.map((post) => post.likedBy || []);
          setLikesData(likes);

          setFileArrays(fileData);
          setActiveIndices(fileData.map(() => 0));
        } else {
          console.log("User ID is not available");
        }
      } catch (error) {
        console.error(error);
      }
    };

    imageresponse();
  }, [userId]);

  const handlePrev = (index) => {
    setActiveIndices((prevIndices) =>
      prevIndices.map((activeIndex, i) =>
        i === index
          ? activeIndex === 0
            ? fileArrays[index].length - 1
            : activeIndex - 1
          : activeIndex
      )
    );
  };

  const handleNext = (index) => {
    setActiveIndices((prevIndices) =>
      prevIndices.map((activeIndex, i) =>
        i === index
          ? activeIndex === fileArrays[index].length - 1
            ? 0
            : activeIndex + 1
          : activeIndex
      )
    );
  };

  // Helper to group arrays into chunks of 3
  const groupIntoRows = (data, size) => {
    const grouped = [];
    for (let i = 0; i < data.length; i += size) {
      grouped.push(data.slice(i, i + size));
    }
    return grouped;
  };

  const groupedFileArrays = groupIntoRows(fileArrays, 3);
  const groupedLikesData = groupIntoRows(likesData, 3);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      {groupedFileArrays.map((row, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            display: "flex",
            // justifyContent: "center",
            gap: "20px",
            marginBottom: "20px",
            margin: "0 auto",
          }}
        >
          {row.map((fileArray, carouselIndex) => (
            <div
              key={carouselIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // border: "1px solid #ccc",
                borderRadius: "10px",
                marginBottom: "20px",

                // padding: "15px",
                // boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "300px",
                  height: "300px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {fileArray.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display:
                          index === activeIndices[rowIndex * 3 + carouselIndex]
                            ? "block"
                            : "none",
                        borderRadius: "5px",
                        overflow: "hidden",
                      }}
                      className="hover-container" // Container to hold image and text
                    >
                      <img
                        src={`${BACKEND_URL}/${image}`}
                        alt={`Row ${rowIndex + 1} - Post ${
                          carouselIndex + 1
                        } - Image ${index + 1}`}
                        className="hover-image" // Image class for hover effect
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <div className="middle">
                        <div className="text">
                          <p>
                            {groupedLikesData[rowIndex][carouselIndex]
                              ?.length || 0}{" "}
                            <FaHeart />
                          </p>
                        </div>{" "}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  onClick={() => handlePrev(rowIndex * 3 + carouselIndex)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10px",
                    transform: "translateY(-50%)",
                    // backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {"<"}
                </button>
                <button
                  onClick={() => handleNext(rowIndex * 3 + carouselIndex)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    // backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ParticularUserPosts;
