import React, { useEffect, useState } from "react";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import axios from "axios"; // For making POST requests
import "@syncfusion/ej2-react-calendars/styles/material.css"; // DatePicker styles
import "@syncfusion/ej2-base/styles/material.css";

import MainForm from "./MainForm";
import CustomRadioButton from "../UI/CustomRadioButton";
import { FiPlus } from "react-icons/fi";
import { WebApimanager } from "../../WebApiManager";
function AdvancedScheduler() {
  let webApi = new WebApimanager()
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [events, setEvents] = useState([
    {
      Id: 1,
      Subject: "Meeting",
      StartTime: new Date(2023, 11, 9, 10, 0),
      EndTime: new Date(2023, 11, 9, 11, 30),
      IsAllDay: false,
    },
    {
      Id: 2,
      Subject: "Conference",
      StartTime: new Date(2023, 11, 10, 12, 0),
      EndTime: new Date(2023, 11, 10, 13, 0),
      IsAllDay: false,
    },
  ]);
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [selectedRadioOption, setSelectedRadioOption] = useState({
    pet: "",  
    notification: "Default",  
  });
  const [allPetData, setAllPetData] = useState([])
  const [allPetNames, setAllPetNames] = useState([])
  const eventSettings = {
    dataSource: events,
    allowDragAndDrop: true,
    allowResizing: true,
  };

  const handleDateSelect = (e) => {
    setSelectedDate(e.value);
    // setIsModalOpen(true); // Open modal when date is selected
    setShowCreateForm(true)
  };

  const handleAddEvent = () => {
    if (newEventTitle && selectedDate) {
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          Id: prevEvents.length + 1,
          Subject: newEventTitle,
          StartTime: selectedDate,
          EndTime: new Date(selectedDate.getTime() + 3600000), // Add 1 hour
          IsAllDay: true,
        },
      ]);
      setIsModalOpen(false); // Close modal
      setNewEventTitle(""); // Reset title input
    }
  };

  const onPopupOpen = (args) => {
    // Check if the popup is for adding a new event
    if (args.type === "QuickInfo") {
      args.cancel = true; // Prevent default quick popup
      setSelectedDate(args.data.StartTime); // Set the selected date
      // setIsModalOpen(true); // Open the custom modal
      setShowCreateForm(true)
    }
  };

  const handleForm =  () =>{
    setShowCreateForm(true)
  }
  const handleRadioChange = (question, option) => {
    setSelectedRadioOption((prev) => ({
      ...prev,
      [question]: option, 
    }));
  };

  const fetchPetProfile = async () =>{
    try {
      const response = await webApi.get("petProfile/ByPetOwner")
      if(response.status === 200){
        setAllPetData(response.data.data.pets)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    fetchPetProfile()
  },[])

  useEffect(()=>{
    const petNames = []
    if(allPetData.length){
      allPetData.forEach((petData)=>petNames.push(petData.petName))
    }
    setAllPetNames(petNames)
    console.log(petNames,"petNames")
  },[allPetData])

  return (
    <>
    <div className="scheduler-container" style={{ marginTop: "50px" }}>
      <div style={{ display: "flex" }}>
        {/* Left-side calendar */}
        <div
          className="left-calendar"
          style={{
            width: "20%",
            padding: "10px",
            borderRight: "1px solid #e0e0e0",
            boxShadow: "1px 0px 5px rgba(0,0,0,0.1)",
            position:"relative"
          }}
        >
          <h4 style={{ textAlign: "left", marginBottom: "20px" }}>
            Calendar
          </h4>
          <button style={{position:"absolute",right:"10px",top:"12px",padding:"6px 10px",backgroundColor:"#e0e0e0",color:"black",display:"flex",alignItems:"center"}} onClick={handleForm}><FiPlus /> Create</button>
          <CalendarComponent
            value={selectedDate}
            change={handleDateSelect}
            min={new Date(2023, 0, 1)}
            max={new Date(2023, 11, 31)}
            isMultiSelection={false}
          />
          <CustomRadioButton
              question={"Select Your Pet"}
              options={allPetNames}
              value={selectedRadioOption.pet}
              onChange={(option) => handleRadioChange("pet", option)}
            />
          
          {/* <CustomRadioButton
              question={"Notification"}
              options={["Default","off Noifications"]}
              value={selectedRadioOption.notification}
              onChange={(option) => handleRadioChange("notification", option)}
            /> */}
        </div>

        {/* Scheduler */}
        <div style={{ width: "80%", paddingLeft: "20px" }}>
          <ScheduleComponent
            height="650px"
            selectedDate={selectedDate}
            eventSettings={eventSettings}
            popupOpen={onPopupOpen}
          >
            <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
          </ScheduleComponent>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <h4>Add Event Title</h4>
          <input
            type="text"
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
            placeholder="Enter event title"
            style={{
              marginBottom: "10px",
              padding: "10px",
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={handleAddEvent}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Add Event
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
    {showCreateForm && <MainForm setShowCreateForm={setShowCreateForm} selectedDate={selectedDate}/>}
    
    </>
  );
}

export default AdvancedScheduler;
