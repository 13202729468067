import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import "./Blog.css";
import LatestPetNews from "./LatestPetNews";
import axios from "axios";
import DOMPurify from "dompurify";
import { WebApimanager } from "../../WebApiManager";
import Footer from "../Footer/Footer";
import BlogComponent from "./BlogComponent";
import { Helmet } from "react-helmet-async";
const Blog_Main = () => {
  return (
    <>
      <Helmet>
        <title>Zaanvar Blog | Latest Pet Care Tips & Insights</title>
        <meta
          name="description"
          content="Stay updated with the latest pet care tips, insights, and stories from Zaanvar. Your go-to blog for all things pet-related."
        />
        <meta name="keywords" content="about us, company, details" />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content="Zaanvar Blog | Latest Pet Care Tips & Insights"
        />
        <meta
          property="og:description"
          content="Stay updated with the latest pet care tips, insights, and stories from Zaanvar. Your go-to blog for all things pet-related."
        />
        <meta property="og:url" content={window.location} />
        <meta
          property="og:image"
          content="https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2Fc405659c4f6b4e679f4d30c0196aacac"
        />
      </Helmet>
      <div>
        <Header />
      </div>
      <div
        style={{
          marginTop: "70px",
          backgroundColor: "#F6F9FE",
          padding: "35px",
        }}
      >
        <div className="blog-Discover-Pet-News">
          <p id="blog-main-title">
            Discover Pet {""}
            <span className="blog-main-sub-title">News, Stories & Blogs</span>
          </p>
        </div>
        <div>
          <BlogComponent />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blog_Main;
