import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { WebApimanager } from "../../../WebApiManager";

const ParticularFollower = ({ isOpen, onClose, userId }) => {
  const [followersList, setFollowersList] = useState([]);
  const webApi = new WebApimanager();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await webApi.get(
          `socialMedia/postsByUserId/${userId}`
        );

        console.log(response, "eeeeee");

        const followers = response.data.profile?.followers || [];
        setFollowersList(followers);

        console.log(followers, "particular follower");
      } catch (error) {
        console.error("Error fetching followers data:", error);
      }
    };

    fetchData();
  }, [userId]);

  if (!isOpen) return null;

  return (
    <div className="following-modal-overlay">
      <div className="following-modal-container">
        <div className="following-modal-header">
          <h2>Followers</h2>
          <button onClick={onClose} className="following-modal-close-button">
            ×
          </button>
        </div>
        <div className="following-modal-content">
          {followersList.length > 0 ? (
            <ul className="following-user-list">
              {followersList.map((user, index) => (
                <li key={index} className="following-user-item">
                  <div className="following-user-info">
                    <img
                      src={
                        user.user.profile
                          ? `${BACKEND_URL}${user.user.profile}`
                          : "/default-profile.png"
                      }
                      alt={user.name || "User Profile"}
                      className="following-user-profile-image"
                    />
                    <span className="">{user.user.name || "Unnamed User"}</span>
                  </div>
                  <button className="following-user-action-button">
                    {user.followStatus || "Following"}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="following-no-users">No users found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticularFollower;
