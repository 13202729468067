
import React, { useEffect, useState } from "react";
import { WebApimanager } from "../../WebApiManager";
import XMLViewer from "react-xml-viewer";

const Wordpress = () => {
  const webApi = new WebApimanager();
  const [blogData, setBlogData] = useState("");
  const [error, setError] = useState(null);
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    const fetchSitemapData = async () => {
      try {
        const response = await webApi.get(`sitemap`);
        let sitemapData = response?.data || "";

        setBlogData(sitemapData);
      } catch (err) {
        console.error("Server error:", err);
        setError("Failed to fetch XML data.");
      }
    };

    fetchSitemapData();
  }, []);

  
  const handleUrlClick = async (url) => {
    try {
      const baseurl = url.substring(url.lastIndexOf("/") + 1);

      // Extract the relevant part of the URL dynamically
      const extractedUrl = url.split("https://zaanvar.com/")[1] || baseurl;
      const apiUrl = `sitemap/${extractedUrl}`;
  
      // Make the API request
      const response = await webApi.get(apiUrl);
      const responseData = response?.data || "";
  
      // Replace 'dev-wp-api.zaanvar.com' with 'zaanvar.com'
      const updatedData = responseData.replace(/dev-wp-api\.zaanvar\.com/g, "zaanvar.com");
  
      // Update the fetched data state
      setFetchedData(updatedData);
    } catch (err) {
      console.error("Error fetching clicked URL data:", err);
      setError("Failed to fetch data for the clicked URL.");
    }
  };
  
  // Custom XML rendering logic
  const renderXmlWithClickableLinks = (xml) => {
    if (typeof xml !== "string") return xml;

    // Replace all <loc>...</loc> tags with clickable links
    return xml.replace(
      /<loc>(.*?)<\/loc>/g,
      (_, url) =>
        `<loc><a href="#" style="color: #569cd6;" onclick="window.handleUrlClick('${url}')">${url}</a></loc>`
    );
  };

  useEffect(() => {
    // Attach the click handler to the global scope for dynamic XML links
    window.handleUrlClick = (url) => {
      handleUrlClick(url);
    };

    return () => {
      delete window.handleUrlClick;
    };
  }, []);

  const xmlTheme = {
    attributeKeyColor: "#007acc",
    attributeValueColor: "#d19a66",
    tagColor: "#569cd6",
    textColor: "#dcdcdc",
    separatorColor: "#d4d4d4",
    backgroundColor: "#1e1e1e",
  };

  return (
    <div style={{ padding: "20px", backgroundColor: xmlTheme.backgroundColor }}>
      {error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : fetchedData ? (
        <XMLViewer xml={fetchedData} theme={xmlTheme} />
      ) : blogData ? (
        // <div
        //   dangerouslySetInnerHTML={{
        //     __html: renderXmlWithClickableLinks(blogData),
        //   }}
        // />
        <XMLViewer xml={blogData} theme={xmlTheme} />
      ) : (
        <p style={{ color: "#dcdcdc" }}>Loading XML data...</p>
      )}
    </div>
  );
};

export default Wordpress;

