import React, { useEffect, useState } from "react";
import styles from "./DairyEventForms.module.css";
import CustomizedSingleRadioButton from "../UI/CustomizedSingleRadioButton";

const GroomingForm = ({ formData, setFormData, errors, setErrors }) => {
    const [reminderNotification, setReminderNotification] = useState("");
    const [reminderOptions, setReminderOptions] = useState({
        monthly: "no",
        yearly: "no",
        halfYearly: "no",
      });

      const handleReminderChange = (option) => {
        setReminderOptions((prevState) => {
          const updatedState = Object.keys(prevState).reduce((acc, key) => {
            acc[key] = key === option ? "yes" : "no";
            return acc;
          }, {});
          return updatedState;
        });
      };

     useEffect(() => {
          const selectedReminderOption = Object.keys(reminderOptions).find(
            (key) => reminderOptions[key] === "yes"
          );
          setFormData((prev) => ({
            ...prev,
            remindNotification: reminderNotification,
            remindOption: selectedReminderOption,
          }));
        }, [reminderNotification, reminderOptions]);
    const handleReminderNotificationChange = (value) => {
        setReminderNotification(value);

        };
        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setFormData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: "",
            }));
          };
  return (
    <>
      

      

      <div className={styles.SelectFieldContainer} style={{marginTop:"30px"}}>
            <label>Description</label>
            <textarea name="description" id="" rows={4} value={formData.description} onChange={handleInputChange}></textarea>
            {errors.description && <span style={{position:"absolute",bottom:"0px",color:"red",fontSize:"14px",}}>{errors.description}</span>}
      </div>

      <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Date</label>
              <input type="date" name="startDate" value={formData.startDate} onChange={handleInputChange}/>
              {errors.startDate && <span className={styles.errorText}>{errors.startDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Duration</label>
              <input type="time" name="startTime" value={formData.startTime} onChange={handleInputChange}/>
              {errors.startTime && <span className={styles.errorText}>{errors.startTime}</span>}
            </div>
      </div>

      <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Reminder Notification</label>
              <div className={styles.checkboxMainContainer}>
              {["yes", "no"].map((value) => (
              <div className={styles.checkboxContainer} key={value}>
                <CustomizedSingleRadioButton
                  label={value === "yes" ? "Yes" : "No"}
                  name="reminderNotification"
                  value={value}
                  selectedValue={reminderNotification}
                  onChange={handleReminderNotificationChange}
                />
              </div>
            ))}
            {errors.remindNotification && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindNotification}</span>}
              </div>
            </div>

            
          </div>
          {reminderNotification === "yes" && <div className={styles.DateDurationMainContainer}>
            <div className={styles.DateContainer}>
              <label>Date</label>
              <input type="date" name="toRemindDate" value={formData.toRemindDate} onChange={handleInputChange}/>
              {errors.toRemindDate && <span className={styles.errorText}>{errors.toRemindDate}</span>}
            </div>
            <div className={styles.DateContainer}>
              <label>Duration</label>
              <input type="time" name="toRemindTime" value={formData.toRemindTime} onChange={handleInputChange}/>
              {errors.toRemindTime && <span className={styles.errorText}>{errors.toRemindTime}</span>}
            </div>
          </div>}
          <div className={styles.DateDurationMainContainer}>
            <div className={styles.MdReminderContainer}>
              <label>Remind Me EveryDay</label>
              <div className={styles.checkboxMainContainer}>
                {[
                    { label: "Monthly", key: "monthly" },
                    { label: "Yearly", key: "yearly" },
                    { label: "Half Yearly", key: "halfYearly" },
                ].map((option) => (
                    <div className={styles.checkboxContainer} key={option.key}>
                    <CustomizedSingleRadioButton
                        label={option.label}
                        name="reminderOptions"
                        value={option.key} 
                        selectedValue={Object.keys(reminderOptions).find(
                        (key) => reminderOptions[key] === "yes"
                        )} 
                        onChange={() => handleReminderChange(option.key)}
                    />
                    </div>
                ))}
                {errors.remindOption && <span style={{position:"absolute",top:"100%",color:"red",fontSize:"14px",}}>{errors.remindOption}</span>}
                </div>
            </div>

            
          </div>
    </>
  )
}

export default GroomingForm