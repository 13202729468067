import React, { useState,useEffect } from "react";
import close from '../../../assets/images/clsoe-popup.png';
import img from "../../../assets/images/petdetailsimg.png";
import { ChatIcon, LikeIcon, LocationIcon,CrossIcon } from "../../../assets/SVG";
import { BACKEND_URL, formatDate } from "../../../Utilities/Constant";






const MyPostPetDetails = ({ pet, isOpen, onClose,onChangeStatus ,petStatus,onSelect,setallDetails}) => {
  
  if (!isOpen) return null;
 

  const handleStatusChange = () => { 
    onChangeStatus();  
  };
  const handleEdit=()=>{
    onSelect();
  }
  if (pet?.petStatus === "Lost") {
    return (
      <>
        <div className="lost-and-found-post-petdetails-overlay">
          <div className="lost-and-found-post-petdetails-content">
            <div className="crossButton" onClick={onClose}>
              <CrossIcon />
            </div>
            <div className="lost-and-found-mypost-petDetails-hearder">
              <div>
                <img src={
                pet.petImage 
                  ? `${BACKEND_URL}${pet.petImage}` 
                  : img
              } alt=""  style={{width:"100%",height:"269.5px"}}/>
              </div>

              <div>
                <div className="lost-and-found-mypetdetails">
                  <p className="lost-and-found-my-petdetails-petname">{pet.breed}</p>
                  <div className="lost-and-found-my-petdetails-petId-petGender">
                    Pet ID : {pet.petId} | Gender : {pet.petGender}
                  </div>
                  <p className="lost-and-found-my-petdetails-Location" style={{ display: "flex" }}>
                    <div style={{ marginTop: "4px",marginRight:"5px" }}>
                      <LocationIcon />
                    </div>
                    {pet.addressToReturn}
                  </p>
                  <p className="lost-and-found-my-petdetails-pet-lostorFound">{pet.petStatus}</p>
                  <div style={{ display: "flex", gap: "0px" }} className="lost-and-found-my-petdetails-pet-lostorFounddate">
                    <p className="lost-and-found-my-petdetails-pet-lostorFounddate"> {pet.foundDate ? `Found Date: ${formatDate(pet.foundDate)}` : `Last Seen Date: ${formatDate(pet.dateOfLastSeen)}`}                  </p>
                    <p style={{ paddingLeft: "60px" }} className="lost-and-found-my-petdetails-pet-lostorFounddate">
                      Post Date:{formatDate(pet.createdDate)}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "2px", marginTop: "15.5px" }}>
                  <button
                    style={{ flex: "", width: "50%", height: "50px",    marginTop: "16px"                    }}
                    className="my-post-petdatiles-post"
                    onClick={handleEdit}
                  >
                    Edit
                  </button>
                  <button
                    style={{ flex: "", width: "50%", height: "50px" ,marginTop: "16px" }}
                    className="my-post-petdatiles-post2"
                    onClick={handleStatusChange}
                  >
                    Change Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }else if(pet?.petStatus === "Found"){
    return (
    <>
    <div className="lost-and-found-post-petdetails-overlay">
      <div className="lost-and-found-post-petdetails-content">
        <div className="crossButton" onClick={onClose}>
          <CrossIcon />
        </div>
        <div className="lost-and-found-mypost-petDetails-hearder">
          <div>
            <img src={
            pet.petImage 
              ? `${BACKEND_URL}${pet.petImage}` 
              : img
          } alt=""  style={{width:"100%",height:"269.5px"}}/>
          </div>

          <div>
            <div className="lost-and-found-mypetdetails">
              <p className="lost-and-found-my-petdetails-petname">{pet.breed}</p>
              <div className="lost-and-found-my-petdetails-petId-petGender">
                Pet ID : {pet.petId} | Gender : {pet.petGender}
              </div>
              <p className="lost-and-found-my-petdetails-Location" style={{ display: "flex" }}>
                <div style={{ marginTop: "4px",marginRight:"5px" }}>
                  <LocationIcon />
                </div>
                {pet.foundLocation}
              </p>
              <p className="lost-and-found-my-petdetails-pet-lostorFound">{pet.petStatus}</p>
              <div style={{ display: "flex", gap: "0px" }} className="lost-and-found-my-petdetails-pet-lostorFounddate">
                <p className="lost-and-found-my-petdetails-pet-lostorFounddate"> {pet.foundDate ? `Found Date: ${formatDate(pet.foundDate)}` : `Last Seen Date: ${formatDate(pet.dateOfLastSeen)}`}                  </p>
                <p style={{ paddingLeft: "60px" }} className="lost-and-found-my-petdetails-pet-lostorFounddate">
                  Post Date: {formatDate(pet.createdDate)}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", gap: "2px", marginTop: "15.5px" }}>
              <button
                style={{ flex: "", width: "50%", height: "50px",marginTop: "16px"  }}
                className="my-post-petdatiles-post"
                onClick={handleEdit}
              >
                Edit
              </button>
              <button
                style={{ flex: "", width: "50%", height: "50px",marginTop: "16px"  }}
                className="my-post-petdatiles-post2"
                onClick={handleStatusChange}
              >
                Change Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
    )
  } else {
    return       <div className="lost-and-found-post-petdetails-overlay">
    <div className="lost-and-found-post-petdetails-content2">
      <div className="crossButton" onClick={onClose}>
        <CrossIcon />
      </div>
      <div className="lost-and-found-mypost-petDetails-hearder">
        <div>
          <img src={
                pet.petImage 
                  ? `${BACKEND_URL}${pet.petImage}` 
                  : img
              } alt="" style={{width:"100%",height:"303px"}}/>
        </div>

        <div>
          <div className="lost-and-found-mypetdetails">
            <p className="lost-and-found-my-petdetails-petname">{pet.name}</p>
            <div className="lost-and-found-my-petdetails-petId-petGender">
              Pet ID : {pet.petId} | Gender : {pet.petGender}
            </div>
            <p className="lost-and-found-my-petdetails-Location" style={{ display: "flex" }}>
              <div style={{ marginTop: "4px",marginRight:"5px" }}>
                <LocationIcon />
              </div>
              {pet.foundLocation?pet.foundLocation:pet.lostAddress}
            </p>
            <p className="petdetials-Reunited">{pet.petStatus}</p>
            <div style={{ display: "flex", gap: "0px" }} className="lost-and-found-my-petdetails-pet-lostorFounddate">
              <p className="lost-and-found-my-petdetails-pet-lostorFounddate">Reunited Date: {formatDate(pet.createdDate)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  }
};

export default MyPostPetDetails;
