import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { io } from "socket.io-client";
import { getRecoil } from "recoil-nexus";
import { jwtToken, userInfo } from "../../../../Atoms";
import { SOCKET_SERVER_URL } from "../../../../Utilities/Constant";
import styles from "./ChatPopup.module.css";
import profilepic from "../../../../assets/images/profile-pic2.png";
import {
  BackIconBlack,
  CrossIconBlack,
  MenuIcon,
  SendIcon,
  SentIcon,
  ProfileIcon,
} from "../../../../assets/SVG";
import msgcorrect from "../../../../assets/images/msgcorrect.png";
import { OrbitProgress } from "react-loading-indicators";

const ParticipantChatScreen = ({
  chat,
  onClose,
  conversationId,
  receiverId,
  senderPetId,
  selectedPet,
  sendingOwnerDetials2,
  isChatscreenOpen,
  sendMessage,
}) => {
  console.log(senderPetId, "senderPetIda");
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [conversations, setConversations] = useState(
    Array.isArray(chat) ? chat : []
  );
  const [messages, setMessages] = useState({});
  const [currentUser] = useRecoilState(userInfo);
  const [allchat, setAllChat] = useState([]);
  const [lastSentByUser, setLastSentByUser] = useState(null);

  console.log(selectedPet, "77777");

  const userId = currentUser._id;
  let jwttoken = getRecoil(jwtToken);
  console.log(userId, "777777777777");

  const handleInputChange = (conversationId, value) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [conversationId]: value,
    }));
  };

  useEffect(() => {
    if (!userId) return;

    // Initialize Socket.IO
    const newSocket = io(SOCKET_SERVER_URL, { query: { userId } });
    setSocket(newSocket);

    newSocket.on("getOnlineUsers", (users) => {
      setOnlineUsers(users);
    });

    newSocket.on("receiveMessage", (receivedMessage) => {
      console.log("Received message:", receivedMessage);

      setConversations((prev) =>
        prev.map((conv) =>
          conv._id === receivedMessage.conversationId
            ? { ...conv, lastMessage: receivedMessage }
            : conv
        )
      );
    });

    // Cleanup on unmount
    return () => {
      newSocket.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    setConversations(Array.isArray(chat) ? chat : []);
  }, [chat]);

  //   if (!userId) return;

  //   const newSocket = io(SOCKET_SERVER_URL, { query: { userId } });
  //   setSocket(newSocket);

  //   newSocket.on("getOnlineUsers", (users) => {
  //     setOnlineUsers(users);
  //   });

  //   newSocket.on("receiveMessage", (receivedMessage) => {
  //     setConversations((prev) =>
  //       prev.map((conv) =>
  //         conv._id === receivedMessage.conversationId
  //           ? { ...conv, lastMessage: receivedMessage }
  //           : conv
  //       )
  //     );
  //   });

  //   return () => {
  //     newSocket.disconnect();
  //   };
  // }, [userId]);
  console.log(sendingOwnerDetials2.petOwner, "sendingpetid");

  useEffect(() => {
    if (!userId || !conversationId) return; // Avoid unnecessary calls
    const abortController = new AbortController();
    console.log(conversationId, "popo");
    const fetchConversations = async () => {
      try {
        const response = await fetch(
          //   ${SOCKET_SERVER_URL}api/message/ByReceiver/${sendingOwnerDetials2.petOwner._id}?senderPetId=${selectedPet._id}&receiverPetId=${sendingOwnerDetials2._id},
          `${SOCKET_SERVER_URL}api/message/${conversationId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwttoken}`,
            },
            signal: abortController.signal,
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        setAllChat(data);
        console.log("ssss", data);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching conversations:", error.message);
        }
      }
    };

    fetchConversations();

    return () => {
      abortController.abort();
    };
  }, [userId, jwttoken, conversationId]);

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  console.log(selectedPet._id, "selectedPet._id(1)");

  // const sendMessage = async () => {
  //     const messageContent = messages[conversationId] || "";
  //     if (!messageContent.trim()) return; // Prevent empty messages

  //     const payload = {
  //         sender: userId,
  //         receiver: receiverId,
  //         content: messageContent,
  //         conversationId,
  //         senderPetId: selectedPet._id,
  //         receiverPetId: senderPetId,
  //     };
  //     setLoading(true);

  //     try {
  //         const response = await fetch(${SOCKET_SERVER_URL}api/message/send, {
  //             method: "POST",
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: Bearer ${jwttoken},
  //             },
  //             body: JSON.stringify(payload),
  //         });

  //         if (!response.ok) throw new Error(Failed to send message: ${response.status});

  //         const sentMessage = await response.json();

  //         // Add the new message to allchat.messages immediately

  //         setAllChat((prev) => ({
  //             ...prev,
  //             messages: [...(prev.messages || []), sentMessage],
  //         }));

  //         // Emit the message to the socket
  //         socket.emit("sendMessage", sentMessage);
  //         setLastSentByUser(sentMessage.sender._id);

  //         // Clear the input field
  //         setMessages((prevMessages) => ({
  //             ...prevMessages,
  //             [conversationId]: "",
  //         }));
  //     } catch (error) {
  //         console.error("Error sending message:", error.message);
  //     }finally {
  //         setLoading(false); // Hide loader
  //       }
  // };

  const sendMessagebutton = async () => {
    const messageContent = messages[conversationId] || "";
    if (!messageContent.trim()) return; // Prevent empty messages

    const payload = {
      sender: userId,
      receiver: receiverId,
      content: messageContent,
      conversationId,
      senderPetId: selectedPet._id,
      receiverPetId: senderPetId,
    };

    setLoading(true);

    try {
      // Send the message to the server
      const response = await fetch(`${SOCKET_SERVER_URL}api/message/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwttoken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok)
        throw new Error(`Failed to send message: ${response.status}`);

      const sentMessage = await response.json();

      // Emit the message through socket
      socket.emit("sendMessage", sentMessage);

      // Update the messages for the current conversation in the state
      setMessages((prevMessages) => ({
        ...prevMessages,
        [conversationId]: [
          ...(prevMessages[conversationId] || []),
          sentMessage,
        ],
      }));

      // Update the allChat object to reflect the new message in the UI
      setAllChat((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), sentMessage],
      }));

      // Update the conversations list with the latest message
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv._id === conversationId
            ? {
                ...conv,
                lastMessage: sentMessage,
                timestamp: new Date(), // Update timestamp for sorting
              }
            : conv
        )
      );

      // Optionally, update the lastSentByUser state
      setLastSentByUser(sentMessage.sender._id);

      // Clear the input field for the conversation
      setMessages((prevMessages) => ({
        ...prevMessages,
        [conversationId]: "",
      }));
    } catch (error) {
      console.error("Error sending message:", error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  console.log(allchat, "cscscs");
  allchat.messages?.forEach((message, index) => {
    console.log(`Receiver ID for message ${index + 1}:, message.receiver?._id`);
  });

  const [otherUserMessageIds, setOtherUserMessageIds] = useState([]);

  // Update the array whenever allchat.messages changes
  useEffect(() => {
    if (Array.isArray(allchat.messages)) {
      const ids = allchat.messages
        .filter((msg) => msg.sender._id !== userId) // Filter for messages from other users
        .map((msg) => msg._id); // Extract message IDs
      setOtherUserMessageIds(ids);
    }
  }, [allchat.messages, userId]);

  console.log(otherUserMessageIds, "popopopopo");

  useEffect(() => {
    if (otherUserMessageIds.length > 0) {
      const updateIsRead = async () => {
        try {
          const response = await fetch(
            `${SOCKET_SERVER_URL}api/message/updateIsRead`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwttoken}`, // Add JWT token if needed
              },
              body: JSON.stringify({ messageIds: otherUserMessageIds }),
            }
          );

          if (!response.ok) {
            throw new Error(`Failed to update messages: ${response.status}`);
          }

          const result = await response.json();
          console.log("Messages updated successfully:", result);
        } catch (error) {
          console.error("Error updating messages:", error.message);
        }
      };

      updateIsRead();
    }
  }, [otherUserMessageIds, jwtToken]);

  useEffect(() => {
    console.log(allchat, "meassages");
  }, [allchat]);
  console.log(userId, "iii");

  return (
    <>
      {isChatscreenOpen && (
        <div className={styles.Background}>
          <div className={styles.popupContainer}>
            {/* Header Section */}
            <div className={styles.header}>
              <p style={{ margin: "0px", marginLeft: "25px" }}>
                Messages for {selectedPet.petName}
              </p>
              <div
                style={{ marginRight: "25px", cursor: "pointer" }}
                onClick={() => {
                  onClose(); // Call the onClose function passed from the parent
                }}
              >
                <CrossIconBlack />
              </div>
            </div>

            {/* Main Content */}
            <div className={styles.mainContainer2}></div>
            <div className={styles.chatPopupContainer}>
              <div className={styles.lostAndFoundChatPopupBody}>
                <div className={styles.SingleChatChatBody}>
                  {/* Reverse the order of the messages */}
                  {Array.isArray(allchat.messages) &&
                    Object.entries(
                      allchat.messages
                        .slice()
                        .sort(
                          (a, b) =>
                            new Date(a.timestamp) - new Date(b.timestamp)
                        ) // Sort messages by timestamp (oldest first)
                        .reduce((groupedMessages, msg) => {
                          const messageDate = new Date(msg.timestamp);
                          const today = new Date();
                          const diffTime =
                            today.getTime() - messageDate.getTime();
                          const diffDays = Math.floor(
                            diffTime / (1000 * 60 * 60 * 24)
                          );

                          let groupLabel = "";

                          if (diffDays === 0) groupLabel = "Today";
                          else if (diffDays === 1) groupLabel = "Yesterday";
                          else groupLabel = messageDate.toLocaleDateString();

                          if (!groupedMessages[groupLabel])
                            groupedMessages[groupLabel] = [];
                          groupedMessages[groupLabel].push(msg);

                          return groupedMessages;
                        }, {})
                    )
                      .reverse() // Reverse the order to show "Today" at the bottom
                      .map(([dateLabel, messages]) => (
                        <div key={dateLabel}>
                          {/* Date Label */}
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              margin: "10px 0",
                            }}
                          >
                            {dateLabel}
                          </div>

                          {/* Messages */}
                          {messages.map((msg, index) => {
                            const isCurrentUser = msg.sender._id === userId; // Check if the message sender is the current user

                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: isCurrentUser
                                    ? "flex-end"
                                    : "flex-start", // Align messages based on sender
                                  marginBottom: "20px", // Space between messages
                                }}
                              >
                                {/* Message from current user */}
                                {isCurrentUser ? (
                                  <>
                                    <div className={styles.messageBubble}>
                                      <p className={styles.SingleChatChatmsgs}>
                                        {msg.content}
                                      </p>
                                      <span className={styles.messageTime}>
                                        {formatTime(msg.timestamp)}
                                      </span>
                                    </div>
                                    <div>
                                      <img
                                        src={msgcorrect}
                                        alt="sent"
                                        style={{ paddingTop: "12px" }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  // Message from other user
                                  <>
                                    <div>
                                      <img
                                        src={profilepic}
                                        alt=""
                                        className={styles.profilePic}
                                      />
                                    </div>
                                    <div className={styles.messageBubble}>
                                      <p className={styles.SingleChatChatmsgs}>
                                        {msg.content}
                                      </p>
                                      <span className={styles.messageTime}>
                                        {formatTime(msg.timestamp)}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ))}
                </div>
              </div>

              {/* Input - Fixed at the Bottom */}
              <div style={{ position: "relative", width: "100%" }}>
                <input
                  placeholder="Send Message"
                  value={messages[conversationId] || ""}
                  onChange={(e) =>
                    handleInputChange(conversationId, e.target.value)
                  }
                  style={{
                    width: "100%",
                    padding: "10px",
                    paddingRight: "40px", // Space for the button
                    border: "1px solid #ccc",
                    fontSize: "14px",
                  }}
                />
                <button
                  onClick={sendMessagebutton}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div class={styles.ldsspinner}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    <SendIcon />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantChatScreen;
