import React, { useEffect, useState } from "react";
import "../../SocialMedia.css";
import { BACKEND_URL } from "../../../../Utilities/Constant";
import { WebApimanager } from "../../../../WebApiManager";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../../Atoms";

const Followers = ({ isOpen, onClose }) => {
  const [followingList, setFollowingList] = useState([]);
  const webApi = new WebApimanager();

  const [currrenUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    const currectUserID = currrenUserInfo._id;
    const fetchData = async () => {
      try {
        const response = await webApi.get(
          `socialMedia/postsByUserId/${currectUserID}`
        );

        console.log(response, "follow");

        const followers = response.data.profile?.followers || [];
        setFollowingList(followers);

        console.log(followers, "particular follower");
      } catch (error) {
        console.error("Error fetching followers data:", error);
      }
    };

    fetchData();
  }, []);

  const handleRemoveUser = async (userId) => {
    try {
      const response = await webApi.delete(
        `socialMedia/removeFollower/${userId}`
      );
      if (response.status === 200) {
        setFollowingList((prevList) =>
          prevList.filter((user) => user.user._id !== userId)
        );

        toast.success("Follower removed successfully!");
      }
    } catch (error) {
      console.error(
        "Error removing follower:",
        error.response?.data || error.message
      );
      toast.error("Error removing follower. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          style={{ marginTop: "40px" }}
        />
      </div>
      <div className="following-modal-overlay">
        <div className="following-modal-container">
          <div className="following-modal-header">
            <h2>Followers</h2>
            <button onClick={onClose} className="following-modal-close-button">
              ×
            </button>
          </div>
          <div className="following-modal-content">
            {followingList.length > 0 ? (
              <ul className="following-user-list">
                {followingList.map((user) => (
                  <li key={user._id} className="following-user-item">
                    <div className="following-user-item-img-name">
                      <img
                        src={
                          user.user.profile
                            ? `${BACKEND_URL}${user.user.profile}`
                            : "/default-profile.png"
                        }
                        alt={user.name}
                        className="following-user-profile-image"
                      />
                      <span className="following-user-name">
                        {user.user.name}
                      </span>
                    </div>
                    <button
                      className="remove-btn"
                      onClick={() => handleRemoveUser(user.user._id)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="following-no-users">No users found.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Followers;
