import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ServiceFeatures.module.css";
import FeatureColumn from "./FeatureColumn";

const ServiceFeatures = () => {
  const servicesData = {
    Features: {
      subServices: [
        // {
        //   name: "E-Report",
        //   onclick: "E-Report",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
        {
          name: "Pet Diary",
          onclick: "PetDiary",
          image:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        },
        // {
        //   name: "Location",
        //   onclick: "Location",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
        // {
        //   name: "Ride",
        //   onclick: "Ride",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
      ],
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    },
    Services: {
      subServices: [
        // {
        //   name: "Grooming",
        //   onclick: "Grooming",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
        // {
        //   name: "Day Care",
        //   onclick: "Day Care",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F986009c7892848a4b2b84db6714d644e",
        // },
        {
          name: "Tinder",
          onclick: "Tinder",
          image:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        },
        // {
        //   name: "Mating",
        //   onclick: "Mating",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fd30525376d8a4499aaa266bfdbf050e7",
        // },
        // {
        //   name: "Pet Sitter / Walker",
        //   onclick: "Pet Sitter / Walker",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Ff9d9c9231c1142bf9a4974918816235a",
        // },
        // {
        //   name: "Events",
        //   onclick: "Events",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F545f643b867746058f9c106da3fb990e",
        // },
        // {
        //   name: "Training",
        //   onclick: "Training",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F393f012c811441708970a151533a6664",
        // },
        // {
        //   name: "Insurance",
        //   onclick: "Insurance",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
        // {
        //   name: "Cremation",
        //   onclick: "Cremation",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2F8938e63ad5db4a4596712806f1402202",
        // },
        // {
        //   name: "Lost And Found",
        //   onclick: "lost-and-found",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets%2F5a43bde1430945df91be58586dabe0db%2Fe66b6d15d3a74c96b1ada92688f6555c",
        // },
      ],
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    },
    "Social Services": {
      subServices: [
        // {
        //   name: "Blood Bank",
        //   onclick: "Blood Bank",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
        {
          name: "Lost And Found",
          onclick: "lost-and-found",
          image:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        },
        // {
        //   name: "Adoption",
        //   onclick: "Adoption",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
        {
          name: "Pet Breeds",
          onclick: "Pet Breeds",
          image:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        },
        // {
        //   name: "Pet & Animal NGOs",
        //   onclick: "Pet & Animal NGOs",
        //   image:
        //     "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        // },
      ],
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    },

    "Social Media": {
      subServices: [
        {
          name: "Tailgram",
          onclick: "social-media",
          image:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        },
      ],
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    },
    BLOG: {
      subServices: [
        {
          name: "Blog",
          onclick: "blog",
          image:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
        },
      ],
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    },
  };

  const navigate = useNavigate();

  const [selectedService, setSelectedService] = useState("Features");
  const [currentImage, setCurrentImage] = useState(
    servicesData.Features.subServices[0].image
  );

  const handleServiceChange = (service) => {
    setSelectedService(service);
    setCurrentImage(servicesData[service].subServices[0].image);
  };

  const handleSubServiceHover = (image) => {
    setCurrentImage(image);
  };

  const handleSubServiceLeave = () => {
    setCurrentImage(servicesData[selectedService].subServices[0].image);
  };

  return (
    <section className={styles.servicecontainer}>
      <div className={styles.content}>
        <div className={styles.featureSelector}>
          {Object.keys(servicesData).map((service, index) => (
            <div
              key={index}
              className={`${styles.mainService} ${
                selectedService === service ? styles.selectedService : ""
              }`}
              onClick={() => handleServiceChange(service)}
            >
              {service}
            </div>
          ))}
        </div>

        <div className={styles.subServices}>
          <FeatureColumn
            features={servicesData[selectedService].subServices}
            onHover={handleSubServiceHover}
            onLeave={handleSubServiceLeave}
          />
        </div>
        <div>
          <img
            src={currentImage}
            alt={`${selectedService} illustration`}
            className={styles.featureImage}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceFeatures;
