import React, { useState } from "react";
import styles from "./ui.module.css";

const CustomRadioButton = ({ question, options, width, value, onChange,CustomRadio2,CustomRadioButtonchage,customline,custommarginbottom }) => {
  const [showAllOptions, setShowAllOptions] = useState(false);

  const handleOptionSelect = (option) => {
    if (onChange) {
      onChange(option); 
    }
  };

  const visibleOptions = showAllOptions ? options : options.slice(0, 3);
  const remainingCount = options.length - 3;

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          borderBottom:CustomRadioButtonchage?.borderBottom|| "1px solid #F0F1F6",
          width: "94%",
        //   padding: "30px 35px 0px 50px",
        padding:CustomRadio2?.padding||"30px 35px 0px 50px",
        }}
      >
        <div
          className={styles.checkboxDiv}
          style={{
            width: CustomRadio2?.width||width,
            maxHeight: "150px", 
            overflowY: showAllOptions && "auto", 
          }}
        >
          <p className={styles.lostfoundSelectLost} style={{
            lineHeight:customline?.lineHeight||"30px",paddingBottom:customline?.paddingBottom,marginBottom:custommarginbottom?.marginBottom
          }}>{question}</p>
          <div>
            {visibleOptions.map((option) => {
              const isSelected = value === option; 

              return (
                <div
                  key={option}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                  onClick={() => handleOptionSelect(option)} 
                >
                  <div
                    className={styles.radioButton}
                    style={{
                      marginRight: "10px",
                      paddingLeft: "0.5px",
                      position: "relative", 
                    }}
                  >
                    {isSelected && (
                      <div
                        className={styles.radioButtonSelected}
                        style={{
                          position: "absolute",
                          width: "7px",
                          height: "7px",
                          backgroundColor: "#1FBFC2",
                          borderRadius: "50%",
                          top:"2px"
                        }}
                      ></div>
                    )}
                  </div>
                  <p
                    style={{
                      marginRight:
                        options.length > 2 ? "30px" : "65px",
                      fontSize: "12px",
                      fontWeight: 500,
                      marginBottom: "0px",
                      color: "#61677F",
                    }}
                  >
                    {option}
                  </p>
                </div>
              );
            })}
            {options.length > 3 && (
              <button
                onClick={() => setShowAllOptions(!showAllOptions)}
                style={{
                  background: "none",
                  border: "none",
                  color: "red",
                  cursor: "pointer",
                  marginTop: "10px",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                {showAllOptions
                  ? "Show less"
                  : `Show ${remainingCount} more`}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default CustomRadioButton;
