import React, { useState, useRef } from "react";
import "../SocialMedia.css";
import { WebApimanager } from "../../../WebApiManager";

const TextPollModal = ({ isOpen, onClose }) => {
  const webApi = new WebApimanager();
  const [question, setQuestion] = useState("");
  const [option, setOptions] = useState(["", ""]);
  const modalRef = useRef(null);

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...option];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...option, ""]);
  };

  const resetState = () => {
    setQuestion("");
    setOptions(["", ""]);
  };

  const handleSubmit = async () => {
    if (question.trim() && option.every((opt) => opt.trim())) {
        try {
            const formData = new FormData();
            formData.append("question", question.trim());
            formData.append("type", "Text Poll");
            // formData.append("option",option);
            formData.append("option", JSON.stringify(option))
            formData.forEach((value, key) => {
                console.log(`Key: ${key}, Value: ${value}`);
            });
          
            const response = await webApi.imagePost(
                "socialMedia/uploadImages",
                formData
            );

            if (response.status === 201) {
                console.log("Poll created successfully:", response.data);
                resetState();
                onClose();
            } else {
                console.error("Failed to create poll:", response.status);
                alert("Failed to create poll. Please try again.");
            }
        } catch (error) {
            console.error("Error occurred while creating poll:", error);
            alert("An error occurred while creating the poll.");
        }
    } else {
        alert("Please ensure all fields are filled.");
    }
};

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className={`socialmedia-textPollModal-header ${isOpen ? "open" : ""}`}
      onClick={handleOutsideClick}
    >
      <div
        className={`socialmedia-textPollModal-body ${isOpen ? "open" : ""}`}
        ref={modalRef}
      >
        <div className="socialmedia-textPollModal-button-container">
          <button className="nav-button left black" onClick={onClose}>
            ←
          </button>
          <button
            className="socialmedia-textPollModal-button next"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
        <div className="textPoll-padding">
          <label className="socialmedia-textPollModal-question">
            Ask a Question
          </label>
          <div className="poll-input">
            <input
              type="text"
              value={question}
              onChange={handleQuestionChange}
              placeholder="Enter your question here"
              style={{
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                marginTop: "15px",
                width: "100%",
              }}
            />
          </div>
          {option.map((option, index) => (
            <div className="poll-option" key={index}>
              <label className="socialmedia-textPollModal-poll-option">
                Option {index + 1}
              </label>
              <input
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder={`Enter option ${index + 1}`}
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginBottom: "10px",
                  width: "100%",
                  marginTop: "10px",
                }}
              />
            </div>
          ))}
          <div className="socialmedia-textPollModal-add-Option">
            <button onClick={handleAddOption}>Add Option</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextPollModal;
