import React, { useEffect, useState } from "react";
import "./BlogComponent.css"; // Importing the CSS for styling
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const BlogComponent = () => {
  const [blogData, setBlogData] = useState([]);
  const [latestBlog, setLatestBlog] = useState(null);
  const [groupedBlogs, setGroupedBlogs] = useState({
    blogCategories: [],
    newsCategories: [],
    storyCategories: [],
  });
  const navigate = useNavigate();

  // States for tracking selected tags for each category
  const [selectedTagBlog, setSelectedTagBlog] = useState(null);
  const [selectedTagNews, setSelectedTagNews] = useState(null);
  const [selectedTagStory, setSelectedTagStory] = useState(null);

  const webApi = new WebApimanager();
  const defaultImage =
    "https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg?auto=compress&cs=tinysrgb&w=600";

  // Fetch blog data and group them into categories
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await webApi.getwordpressData(`blogs`);
        const blog = Array.isArray(response?.data) ? response.data : [];

        // Sort blogs by date in descending order
        const sortedBlogs = blog.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        setBlogData(sortedBlogs);
        const latest = sortedBlogs[0] || null;
        setLatestBlog(latest);

        // Group blogs by category keywords
        const blogCategories = blog.filter((item) =>
          item.categories.some((cat) => cat.toLowerCase().includes("blog"))
        );

        const newsCategories = blog.filter((item) =>
          item.categories.some((cat) => cat.toLowerCase().includes("news"))
        );

        const storyCategories = blog.filter((item) =>
          item.categories.some((cat) => cat.toLowerCase().includes("stories"))
        );

        setGroupedBlogs({
          blogCategories,
          newsCategories,
          storyCategories,
        });
      } catch (error) {
        console.error("Server error:", error);
      }
    };

    fetchBlogData();
  }, []);

  // Get unique tags for each category
  const allTagsBlog = groupedBlogs.blogCategories.flatMap((blog) => blog.tags);
  const uniqueTagsBlog = [...new Set(allTagsBlog)];

  const allTagsNews = groupedBlogs.newsCategories.flatMap((blog) => blog.tags);
  const uniqueTagsNews = [...new Set(allTagsNews)];

  const allTagsStory = groupedBlogs.storyCategories.flatMap(
    (blog) => blog.tags
  );
  const uniqueTagsStory = [...new Set(allTagsStory)];

  // Filter blogs based on the selected tag for each category
  const filteredBlogs = selectedTagBlog
    ? groupedBlogs.blogCategories.filter((blog) =>
        blog.tags.includes(selectedTagBlog)
      )
    : groupedBlogs.blogCategories;

  const filteredNews = selectedTagNews
    ? groupedBlogs.newsCategories.filter((blog) =>
        blog.tags.includes(selectedTagNews)
      )
    : groupedBlogs.newsCategories;

  const filteredStories = selectedTagStory
    ? groupedBlogs.storyCategories.filter((blog) =>
        blog.tags.includes(selectedTagStory)
      )
    : groupedBlogs.storyCategories;
  const getFirst30Words = (content) => {
    const words = content.split(" ");
    return words.slice(0, 40).join(" ") + (words.length > 40 ? "..." : "");
  };

  return (
    <div className="blog-container">


{latestBlog ? (
  <div className="container">
    <div className="row ">
      {/* Left Side: Image */}
      <div className="col-lg-6 col-12">
      <div className="blog-featured-image-container">
  <img
    className="blog-featured-image"
    src={latestBlog.featuredImage || defaultImage}
    alt={latestBlog.title}
    onClick={() => navigate(`/${latestBlog.slug}`)}
  />
</div>

      </div>

      {/* Right Side: Content */}
      <div className="col-lg-6 col-12 d-flex flex-column justify-content-between">
        <div className="d-flex mb-3 blog-tages-categories-name">
          <p
            className="me-3"
            style={{ borderRight: "1px solid #ccc", paddingRight: "10px" }}
          >
            {latestBlog.tags[0]}
          </p>
          <p
            className="me-3"
            style={{ borderRight: "1px solid #ccc", paddingRight: "10px" }}
          >
            {latestBlog.categories[0]}
          </p>
          <p>
            {new Date(latestBlog.date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}
          </p>
        </div>

        <h2
          className="blog-main-post-title mb-3"
          dangerouslySetInnerHTML={{
            __html: latestBlog.title,
          }}
        ></h2>
        <p
          className="blog-main-post-sub-dec mb-3"
          dangerouslySetInnerHTML={{
            __html: getFirst30Words(latestBlog.content),
          }}
        ></p>

        <button className="blog-main-post-main-button mt-auto">
          Read More
        </button>
      </div>
    </div>
  </div>
) : (
  <p>No blog data available.</p>
)}


   

      {/* <div className="my-5" style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <div className="row">
          {blogData.length > 0 &&
            blogData.slice(0, 3).map((post) => (
              <div className="col-md-4 col-sm-6" key={post.id}>
                <div
                  className="h-100 bg-white"
                  style={{ boxShadow: "0px 0px 10px 0px #0000000D" }}
                >
                  <Link
                    to={`/${post.slug}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      src={post.featuredImage || defaultImage}
                      alt={post.title.rendered}
                      className="card-img-top"
                      style={{ height: "200px", objectFit: "fill" }}
                    />
                    <div
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      <span
                        className="main-name-blog-component"
                        dangerouslySetInnerHTML={{
                          __html:
                            post.title.split(" ").slice(0, 3).join(" ") +
                            (post.title.split(" ").length > 3 ? "..." : ""),
                        }}
                      ></span>
                      <div className="d-flex justify-content-start">
                        <p className="mb-0 me-3 border-end pe-3">
                          {post.categories[0]}
                        </p>
                        <p className="mb-0">
                          <p style={{ paddingLeft: "10px" }}>
                            {new Date(post.date).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                          </p>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div> */}

      {/* Blog Post */}

      {/* <p className="latest-Name-style">
        Latest <span>Pet Blog</span>
      </p>
      <div className="tags-container">
        {uniqueTagsBlog.map((tag, index) => (
          <button
            key={index}
            className={`tag-button ${selectedTagBlog === tag ? "active" : ""}`}
            onClick={() => setSelectedTagBlog(tag)}
          >
            {tag}
          </button>
        ))}

        <button className="tag-button" onClick={() => setSelectedTagBlog(null)}>
          Show All
        </button>
      </div>
      <div className="blog-cards-grid-BlogCategories">
        {filteredBlogs.map((blog) => (
          <div
            className="blog-card-new-BlogCategories"
            key={blog.id}
            onClick={() => navigate(`/${blog.slug}`)}
          >
            <div className="blog-image-new-BlogCategories">
              <img src={blog.featuredImage || defaultImage} alt={blog.title} />
            </div>
            <div className="blog-content-new-BlogCategories">
              <span
                className="blog-content-new-title-BlogCategories"
                dangerouslySetInnerHTML={{
                  __html:
                    blog.title.split(" ").slice(0, 5).join(" ") +
                    (blog.title.split(" ").length > 5 ? "..." : ""),
                }}
              ></span>
              <div className="blog-Category-date-container-new">
                <p
                  style={{
                    borderRight: "1px solid #ccc",
                    paddingRight: "10px",
                  }}
                >
                  {blog.categories[0]}
                </p>
                <p>
                  {new Date(blog.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div> */}

      <p className="latest-Name-style">
        Latest <span>Pet News</span>
      </p>
      <div className="tags-container">
        {uniqueTagsNews.map((tag, index) => (
          <button
            key={index}
            className={`tag-button ${selectedTagNews === tag ? "active" : ""}`}
            onClick={() => setSelectedTagNews(tag)}
          >
            {tag}
          </button>
        ))}

        <button className="tag-button" onClick={() => setSelectedTagNews(null)}>
          Show All
        </button>
      </div>
      <div className="blog-cards-grid-BlogCategories">
        {filteredNews.map((blog) => (
          <div
            className="blog-card-new-BlogCategories"
            key={blog.id}
            onClick={() => navigate(`/${blog.slug}`)}
          >
            <div className="blog-image-new-BlogCategories">
              <img src={blog.featuredImage || defaultImage} alt={blog.title} />
            </div>
            <div className="blog-content-new-BlogCategories">
              <span
                className="blog-content-new-title-BlogCategories"
                dangerouslySetInnerHTML={{
                  __html:
                    blog.title.split(" ").slice(0, 3).join(" ") +
                    (blog.title.split(" ").length > 3 ? "..." : ""),
                }}
              ></span>
              <div className="blog-Category-date-container-new">
                <p
                  style={{
                    borderRight: "1px solid #ccc",
                    paddingRight: "10px",
                  }}
                >
                  {blog.categories[0]}
                </p>
                <p style={{ paddingLeft: "10px" }}>
                  {new Date(blog.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <p className="latest-Name-style">
        Latest <span>Pet Story</span>
      </p>
      <div className="tags-container">
        {uniqueTagsStory.map((tag, index) => (
          <button
            key={index}
            className={`tag-button ${selectedTagStory === tag ? "active" : ""}`}
            onClick={() => setSelectedTagStory(tag)}
          >
            {tag}
          </button>
        ))}

        <button
          className="tag-button"
          onClick={() => setSelectedTagStory(null)}
        >
          Show All
        </button>
      </div>
      <div className="blog-cards-grid-BlogCategories">
        {filteredStories.map((blog) => (
          <div
            className="blog-card-new-BlogCategories"
            key={blog.id}
            onClick={() => navigate(`/${blog.slug}`)}
          >
            <div className="blog-image-new-BlogCategories">
              <img src={blog.featuredImage || defaultImage} alt={blog.title} />
            </div>
            <div className="blog-content-new-BlogCategories">
              <span
                className="blog-content-new-title-BlogCategories"
                dangerouslySetInnerHTML={{
                  __html:
                    blog.title.split(" ").slice(0, 5).join(" ") +
                    (blog.title.split(" ").length > 5 ? "..." : ""),
                }}
              ></span>
              <div className="blog-Category-date-container-new">
                <p
                  style={{
                    borderRight: "1px solid #ccc",
                    paddingRight: "10px",
                  }}
                  className="blog-Category-date-container-new-BlogCategories-"
                >
                  {blog.categories[0]}
                </p>
                <p
                  style={{ paddingLeft: "10px" }}
                  className="blog-Category-date-container-new-BlogCategories-date"
                >
                  {new Date(blog.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default BlogComponent;
