import React, { useEffect, useState } from "react";
import back1 from "../../../assets/images/backb1.png";
import write1 from "../../../assets/images/write.png";
import { WebApimanager } from "../../../WebApiManager";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_URL, formatDate } from "../../../Utilities/Constant";


const EditPetDetails = ({ pet, back }) => {
  const webApi = new WebApimanager();
  const [error, setError] = useState(null);
  const formatDateForInput = (dateString) => {
    if (!dateString) return ""; // Handle null or undefined
    const date = new Date(dateString);
    if (isNaN(date)) return ""; // Handle invalid dates
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  };
  
  
  const [dateOfLastSeen, setdateOfLastSeen] = useState( formatDateForInput(pet?.dateOfLastSeen) );
  console.log(dateOfLastSeen,"ppppaaa")
  const [priceMoney, setpriceMoney] = useState(pet?.priceMoney);
  const [lostAddress, setlostAddress] = useState(pet?.lostAddress);
  const [foundDate, setFoundDate] = useState("");
  const [foundLocation, setFoundLocation] = useState("");
  const [formData, setFormData] = useState({ petImage:null, 
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleBack = () => {
    back();
  };
  const handleLostSubmit = async (e) => {
    e.preventDefault();

    const payload = {
        dateOfLastSeen,
      priceMoney,
      lostAddress,
    };
    const payloadimg = {
        petImage: formData.petImage,
      };
  

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    try {
      const response = await webApi.put(
        `lostAndFound/lostPetupdate/${pet?._id}`,
        payload
      );
      if (formData.petImage !== null && formData.petImage !== undefined) {
        const imageUploadResponse = await webApi.imagePut(
          `lostAndFound/updatePetImageInLost/${pet?._id}`,
          payloadimg
        );
    }
      else if (response.data.status === "success") {
        toast.success("Pet Lost Edit Successfully");
        window.location.reload();

        setdateOfLastSeen("");
        setpriceMoney("");
        setlostAddress("");
      } else {
        // Handle error response
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setApiProcessing({ loader: false, message: "" });
      window.location.reload();

    }
  };

  const handleFoundSubmit = async (e) => {
    e.preventDefault();
    const payload = {
     
      foundDate,
      foundLocation,
    };

    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    try {
      const response = await webApi.put(
        `lostAndFound/foundPetupdate/${pet._id}`,
        payload
      );

      if (response.data.status === "success") {
        toast.success("Pet Found Edit Successfully");
        window.location.reload();

        setFoundDate({
          foundDate: null,
          foundLocation: null,
        });
      } else {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };
   
  

      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setFormData({ ...formData, petImage: file, fileName: file.name });
        }
      };

  return (
    <>
    <ToastContainer/>
      {pet?.petStatus === "Lost" && (
        <div className="lost-and-found-post-popup-overlay">
          <div className="lost-and-found-post-edit-popup-content">
            <div className="lost-and-found-popup-hearder">
              <button
                className="lost-and-found-post-changes-close"
                onClick={handleBack}
              >
                <img src={back1} alt="Back" />
              </button>
            </div>
            <div className="lost-and-found-popup-edit-body">
              <div>
                <label className="edit-pet-details-lable1">Lost Date</label>
                <div style={{ width: "100%", position: "relative" }}>
                  <input
                    type="date"
                    value={dateOfLastSeen}
                    onChange={(e) => setdateOfLastSeen(e.target.value)}
                    style={{
                      width: "100%",
                      paddingRight: "40px",
                      height: "40px",
                      boxSizing: "border-box",
                    }}
                    placeholder="Date of Last seen"
                    className="edit-chnges-page"
                  />
                  <img
                    src={write1}
                    alt="write"
                    style={{
                      position: "absolute",
                      right: "calc(2% + 10px)",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  className="edit-pet-details-lable1"
                  style={{ marginTop: "10px" }}
                >
                  Reward
                </label>
                <div style={{ width: "100%", position: "relative" }}>
                  <input
                    type="text"
                    value={priceMoney}
                    onChange={(e) => setpriceMoney(e.target.value)}
                    style={{
                      width: "100%",
                      paddingRight: "40px",
                      height: "40px",
                      boxSizing: "border-box",
                    }}
                    placeholder="Enter your price"
                    className="edit-chnges-page"
                  />
                  <img
                    src={write1}
                    alt="write"
                    style={{
                      position: "absolute",
                      right: "calc(2% + 10px)",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </div>
              </div>

              <div>
                <label
                  className="edit-pet-details-lable1"
                  style={{ marginTop: "10px" }}
                >
                  Location
                </label>
                <div style={{ width: "100%", position: "relative" }}>
                  <input
                    type="text"
                    value={lostAddress}
                    onChange={(e) => setlostAddress(e.target.value)}
                    style={{
                      width: "100%",
                      paddingRight: "40px",
                      height: "40px",
                      boxSizing: "border-box",
                    }}
                    placeholder="Lost Address"
                    className="edit-chnges-page"
                  />
                  <img
                    src={write1}
                    alt="write"
                    style={{
                      position: "absolute",
                      right: "calc(2% + 10px)",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </div>
              </div>




              <div>
  <label
                  className="edit-pet-details-lable1"
                  style={{ marginTop: "10px" }}
  >
    Image
  </label>
  <div style={{ width: "100%", position: "relative",textAlign: "left",border:"1px solid #D9D9D9",background: "rgba(243, 243, 243, 0.50)"
 }}>
    {/* Hidden file input */}
    <input
      id="file-upload"
      type="file"
      accept="image/*"
      style={{
        display: "none",
      }}
    
    onChange={handleFileChange}

    />
    {/* Custom button and file name display */}
    <label
      htmlFor="file-upload"
      style={{
        display: "inline-block",
        padding: "10px 20px",
        backgroundColor: "#727271", // Button background color
      color:"#FFFFFF",
      border:"1px solid #D9D9D9",
              cursor: "pointer",
        fontSize: "14px",
      }}
    >
      Choose File
    </label>
    <span
      id="file-name"
      style={{
        marginLeft: "10px",
        fontSize: "14px",
        color: "#727271",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        maxWidth: "60%", // Adjust the width as needed
        verticalAlign: "middle",
      }}
    >
        {formData.fileName || pet?.petImage || "no file"}
        </span>

    <img
                    src={write1}
                    alt="write"
                    style={{
                      position: "absolute",
                      right: "calc(2% + 10px)",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none",
                      width: "15px",
                      height: "15px",
                    }}
                  />
  </div>
</div>


              <div className="lost-and-found-button-container">
                <button
                  className="edit-changes-button"
                  onClick={handleLostSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {pet?.petStatus === "Found" && (
        <div className="lost-and-found-post-popup-overlay">
          <div className="lost-and-found-post-edit-found-popup-content">
            <div className="lost-and-found-popup-hearder">
              <button
                className="lost-and-found-post-changes-close"
                onClick={handleBack}
              >
                <img src={back1} alt="Back" />
              </button>
            </div>
            <div className="lost-and-found-popup-edit-body">
              <div>
                <label className="edit-pet-details-lable1">Found Date</label>
                <div style={{ width: "100%", position: "relative" }}>
                  <input
                    type="date"
                    value={foundDate}
                    onChange={(e) => {
                        const val= e.target.value         
                        if(!isNaN(val) && val.length>=0 && val.length<=3){
                        setFoundDate(e)}
                    }}
                    
                    style={{
                      width: "100%",
                      paddingRight: "40px",
                      height: "40px",
                      boxSizing: "border-box",appearance: 'none', WebkitAppearance: 'none', MozAppearance: 'none'
                    }}
                    placeholder=" 02/10/2024"
                    className="edit-chnges-page"
                  />
                  <img
                    src={write1}
                    alt="write"
                    style={{
                      position: "absolute",
                      right: "calc(2% + 10px)",
                      top: "50%",
                      transform: "translateY(-50%)",
                      height: "20px",
                      pointerEvents: "none",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </div>

                <div>
                  <label
                    className="edit-pet-details-lable1"
                    style={{ marginTop: "10px" }}
                  >
                    Location
                  </label>
                  <div style={{ width: "100%", position: "relative" }}>
                    <input
                      type="text"
                      value={foundLocation}
                      onChange={(e) => setFoundLocation(e.target.value)}
                      style={{
                        width: "100%",
                        paddingRight: "40px",
                        height: "40px",
                        boxSizing: "border-box",
                      }}
                      placeholder="Ameerpet, Hyderabad"
                      className="edit-chnges-page"
                    />
                    <img
                      src={write1}
                      alt="write"
                      style={{
                        position: "absolute",
                        right: "calc(2% + 10px)",
                        top: "50%",
                        transform: "translateY(-50%)",
                        height: "20px",
                        pointerEvents: "none",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </div>
                </div>

                <div class="lost-and-found-button-container">
                  <button
                    class="edit-changes-button"
                    onClick={handleFoundSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPetDetails;
