import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SocialMedia.css";
import SocialMediaImageView from "./SocialMediaImageView";
import { WebApimanager } from "../../WebApiManager";
import { BACKEND_URL } from "../../Utilities/Constant";
import { useRecoilState } from "recoil";
import { userInfo } from "../../Atoms";
import like from "../../assets/Social_Media_Icons/like.svg";
import comment from "../../assets/Social_Media_Icons/Vector.png";
import share from "../../assets/Social_Media_Icons/share.svg";
import NotificationSocialMedia from "./SocialMediaPages/NotificationSocialMedia";
import SocicalMediaSuggestions from "./SocicalMediaSuggestions";
import HoverProfile from "./SocialMediaPages/HoverProfile/HoverProfile";
import ParticularUserProfile from "./Profile_Viwe_User/ParticularUserProfile";
import SharePost from "./SocialMediaPages/SharePost";
import SearchSocialMedia from "./SocialMediaPages/SearchSocialMedia";

const SocialMedia = () => {
  const webApi = new WebApimanager();
  const [currentUserInfo] = useRecoilState(userInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [fileArrays, setFileArrays] = useState([]);
  const [activeIndices, setActiveIndices] = useState([]);
  const [pageTurn, setPageTurn] = useState(true);
  const [particularUserId, setParticularUserId] = useState(null);
  const [isSharePostModalOpen, setIsSharePostModalOpen] = useState(false);
  const [sharePostID, setSharePostID] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleUserClick = (userId) => {
    setSelectedUserId(userId); // Set the clicked user's ID
    setPageTurn(false); // Switch view to the user profile
  };

  const handleBack = () => {
    setSelectedUserId(null); // Reset the selected user
    setPageTurn(true); // Switch back to the social media feed
  };
  const fetchSocialMediaData = async () => {
    const userId = currentUserInfo._id;
    try {
      const response = await webApi.get("socialMedia/fetchAllData");
      setSocialMediaData(response.data.posts);
      console.log();

      const posts = response.data.posts || [];

      console.log(response, "social media post");

      const fileData = posts.map((post) => post.file || []);
      setFileArrays(fileData);
      console.log(socialMediaData, "fileArrays");

      const initialSelection = {};
      response.data.posts.forEach((post) => {
        const selectedOption = post.option.find((opt) =>
          opt.selectedUsers?.includes(userId)
        );
        if (selectedOption) {
          initialSelection[post._id] = selectedOption._id;
        }
      });
      setSelectedOptions(initialSelection);
    } catch (error) {
      console.error("Error fetching social media data:", error);
    }
  };

  const handlePrev = (postId) => {
    setActiveIndices((prev) => ({
      ...prev,
      [postId]:
        (prev[postId] > 0 ? prev[postId] : fileArrays[postId].length) - 1,
    }));
  };

  const handleNext = (postId) => {
    setActiveIndices((prev) => ({
      ...prev,
      [postId]: (prev[postId] + 1) % fileArrays[postId].length,
    }));
  };

  useEffect(() => {
    fetchSocialMediaData();
  }, []);

  const handleImageModal = (
    postId,
    userId,
    comments,
    file,
    followingStatus,
    timeStamp
  ) => {
    console.log("hgfhdfhdfgbsdgvsdgvfggggggggggggg");

    setSelectedImage(file[0]);
    setIsModalOpen(true);
    setSelectedPost({
      postId,
      userId,
      comments,
      file,
      followingStatus,
      timeStamp,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const likeAndUnLike = async (postId) => {
    if (!postId) return alert("Post not found!");
    try {
      await webApi.put(`socialMedia/likeOrUnlike/${postId}`);
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error toggling like/unlike:", error);
      alert("Failed to toggle like/unlike. Please try again.");
    }
  };

  const addFollower = async (userId) => {
    if (!userId) return alert("User not found!");
    try {
      await webApi.put(`socialMedia/addFollower/${userId}`);
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const removeFollower = async (userId) => {
    if (!userId) return alert("User not found!");
    try {
      await webApi.delete(`socialMedia/unFollow/${userId}`);
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error unfollowing user:", error);
      alert("Failed to unfollow user. Please try again.");
    }
  };

  const addOption = async (postId, optionId) => {
    try {
      await webApi.put(
        `socialMedia/updateSelectedUser?postId=${postId}&optionId=${optionId}`
      );
      setSelectedOptions((prev) => ({
        ...prev,
        [postId]: optionId,
      }));

      alert("Your vote has been recorded!");
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error submitting vote:", error);
      alert("Failed to submit your vote. Please try again.");
    }
  };

  const formatInstagramTime = (timeStamp) => {
    const currentTime = new Date();
    const postTime = new Date(timeStamp);
    const timeDifference = currentTime - postTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60) return "Just now";
    if (minutes < 60) return `${minutes}m ago`;
    if (hours < 24) return `${hours}h ago`;
    return `${days}d ago`;
  };

  const formatNumber = (num) => {
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "K";
    return num.toString();
  };

  const handleProfileClick = (userId) => {
    console.log(`User profile clicked: ${userId}`);
    setParticularUserId(userId);
    setPageTurn(false);
  };

  const generateWhatsAppLink = (post) => {
    const postURL = `${BACKEND_URL}post/${post._id}`;
    const message = `Check out this amazing post on OurApp: ${postURL}`;
    return `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  };

  const handleShareClick = (postId) => {
    const post = socialMediaData.find((p) => p._id === postId);
    const shareLink = generateWhatsAppLink(post);
    window.open(shareLink, "_blank");
  };

  return (
    <div style={{ backgroundColor: "#F6F9FE" }}>
      {pageTurn ? (
        <div className="socialMedia-flex-container">
          <div className="left-box" style={{ marginBottom: "0px" }}>
            {socialMediaData.map((post, index) => (
              <div key={index} className="socialmedia-box">
                <div className="socialmediaheader">
                  <div
                    className="socialmedia-profile"
                    onClick={() => handleProfileClick(post.userId)}
                  >
                    <HoverProfile user={post.userId} />
                    <div className="socialmedia-profile-info">
                      <span className="socialmedia-profile-name">
                        {post.userId?.name || "Anonymous"}
                      </span>
                      <span className="socialmedia-profile-date">
                        {formatInstagramTime(post.timeStamp)}
                      </span>
                    </div>
                  </div>
                  <button
                    className="socialmedia-follow-btn"
                    onClick={() => {
                      if (post.followingStatus === "You are following") {
                        removeFollower(post.userId._id);
                      } else {
                        addFollower(post.userId._id);
                      }
                    }}
                  >
                    {post.followingStatus === "You are following"
                      ? "Unfollow"
                      : "Follow"}
                  </button>
                </div>

                <div
                  className="socialmedia-dec"
                  style={{
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                    marginBottom: "15px",
                    marginTop: "10px",
                  }}
                >
                  <span>{post.caption || post.question || ""}</span>
                </div>

                <div>
                  {post.type === "Image Poll" ? (
                    <div className="image-poll-container">
                      {post.file && post.file.length > 0 ? (
                        <div className="poll-image-container">
                          <img
                            src={`${BACKEND_URL}${post.file[0]}`}
                            alt="Poll Image"
                            className="poll-image"
                          />
                        </div>
                      ) : (
                        <p>No image available for this poll.</p>
                      )}
                      <div className="poll-options-container mt-3">
                        {post.option && post.option.length > 0 ? (
                          post.option.map((opt, idx) => {
                            const totalVotes = post.option.reduce(
                              (acc, curr) =>
                                acc + (curr.selectedUsers?.length || 0),
                              0
                            );
                            const percentage =
                              totalVotes > 0
                                ? Math.round(
                                    ((opt.selectedUsers?.length || 0) /
                                      totalVotes) *
                                      100
                                  )
                                : 0;

                            const isActive = opt.selectedUsers?.some(
                              (user) => user.user === currentUserInfo?._id
                            );

                            return (
                              <div key={idx} className="poll-option">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                  onClick={() => addOption(post._id, opt._id)}
                                >
                                  {isActive ? "✔️" : "🔘"}

                                  <label
                                    htmlFor={`option-${idx}`}
                                    style={{ flex: 1 }}
                                  >
                                    {opt.option}
                                  </label>
                                  <span>{`${percentage}%`}</span>
                                </div>
                                <div
                                  style={{
                                    height: "10px",
                                    backgroundColor: "#e0e0e0",
                                    borderRadius: "5px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: `${percentage}%`,
                                      height: "100%",
                                      backgroundColor: "#1FBFC2",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No options available for this poll.</p>
                        )}
                      </div>
                    </div>
                  ) : post.type === "Text Poll" ? (
                    <div className="poll-container">
                      {post.option && post.option.length > 0 ? (
                        post.option.map((opt, idx) => {
                          const totalVotes = post.option.reduce(
                            (acc, curr) =>
                              acc + (curr.selectedUsers?.length || 0),
                            0
                          );
                          const percentage =
                            totalVotes > 0
                              ? Math.round(
                                  ((opt.selectedUsers?.length || 0) /
                                    totalVotes) *
                                    100
                                )
                              : 0;

                          const isActive = opt.selectedUsers?.some(
                            (user) => user.user === currentUserInfo?._id
                          );
                          return (
                            <div key={idx} className="poll-option">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                                onClick={() => addOption(post._id, opt._id)}
                              >
                                {isActive ? "✔️" : "🔘"}

                                <label
                                  htmlFor={`option-${idx}`}
                                  style={{ flex: 1 }}
                                >
                                  {opt.option}
                                </label>
                                <span>{`${percentage}%`}</span>
                              </div>
                              <div
                                style={{
                                  height: "10px",
                                  backgroundColor: "#e0e0e0",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                }}
                              >
                                <div
                                  style={{
                                    width: `${percentage}%`,
                                    height: "100%",
                                    backgroundColor: "#1FBFC2",
                                  }}
                                ></div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No options available for this poll.</p>
                      )}
                    </div>
                  ) : post.file && post.file.length > 0 ? (
                    <div
                      id={`carousel-${post._id}`}
                      className="carousel-home slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {post.file.map((image, index) => (
                          <div
                            key={index}
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            }`}
                          >
                            <img
                              src={`${BACKEND_URL}${image}`}
                              className="socialmedia-img"
                              alt={`Slide ${index + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p>No images available for this post.</p>
                  )}
                </div>

                <div className="socialmedia-bottom-icons">
                  <div className="socialmedia-left-icons">
                    <div
                      className="icon-with-text"
                      onClick={() => likeAndUnLike(post._id)}
                    >
                      <div className="heart-icon-container">
                        <i
                          className={`fas fa-heart ${
                            post.likeStatus === "Yes" ? "active" : ""
                          }`}
                          style={{
                            color: post.likeStatus === "Yes" ? "red" : "gray",
                            fontSize: "25px",
                          }}
                        ></i>
                        {post.likeStatus === "Yes" && (
                          <span className="heart-animation"></span>
                        )}
                      </div>
                      <p>{formatNumber(post.likedBy?.length || 0)}</p>
                    </div>

                    <div
                      className="icon-with-text"
                      onClick={() =>
                        handleImageModal(
                          post._id,
                          post.userId,
                          post.comments,
                          post.file,
                          post.followingStatus,
                          post.timeStamp
                        )
                      }
                    >
                      <img
                        src={comment}
                        alt="comment"
                        className="fas fa-heart"
                        style={{
                          color: post.isLiked ? "red" : "gray",
                          width: "25px",
                          height: "auto",
                        }}
                      />
                      <p>{formatNumber(post.comments?.length || 0)}</p>
                    </div>
                    <div
                      className="icon-with-text"
                      onClick={() => handleShareClick(post._id)}
                    >
                      <img
                        src={share}
                        alt="share"
                        className="fas fa-heart"
                        style={{ width: "22px", height: "auto" }}
                        onClick={() => setIsSharePostModalOpen(true)}
                      />
                      <p>{formatNumber(post.shares?.length || 0)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="right-box">
            <SocicalMediaSuggestions />
          </div>
        </div>
      ) : (
        <ParticularUserProfile userId={particularUserId} />
      )}

      <SocialMediaImageView
        isModalOpen={isModalOpen}
        selectedImage={selectedImage}
        closeModal={handleCloseModal}
        refetchData={fetchSocialMediaData}
        postData={selectedPost}
        onPrev={handlePrev}
        onNext={handleNext}
      />

      <SharePost
        isOpen={isSharePostModalOpen}
        onClose={() => setIsSharePostModalOpen(false)}
        postId={sharePostID}
        refetchData={fetchSocialMediaData}
      />
    </div>
  );
};

export default SocialMedia;
