import React, { useState } from "react";
import Logo from "../../assets/images/ZAANVAR_FINAL LOGO.png";
import { Outlet, NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import Hero from "../Hero/Hero";
// import ECommerce from "../../assets/images/E-Commerce.jpg";
// import PetAnimalMarketing from "../../assets/images/Pet & Animal Marketing.jpg";
// import Clinics from "../../assets/images/Clinics.jpeg";
// import Adoption from "../../assets/images/Adoption.jpg";
// import Grooming from "../../assets/images/Grooming.jpeg";
// import PetAnimalNGOs  from "../../assets/images/Pet & Animal NGOs.jpg";
// import Events from "../../assets/images/Events.jpg";
// import PetBreeds from "../../assets/images/Pet Breeds.jpg";
// import Location from "../../assets/images/Location.png";
// import EReport from "../../assets/images/E-Report.png";
// import Ride from "../../assets/images/Ride.jpeg";
// import PetDiary from "../../assets/images/Pet Diary.jpeg";
// import FoundMissing from "../../assets/images/Found & Missing.jpeg";
// import NutritionDiet from "../../assets/images/Nutrition Diet.jpg";
// import PetSitterWalker from "../../assets/images/Pet Sitter  Walker.jpeg";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Helmet } from "react-helmet-async";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const handleServicesClick = () => {
    setShowDropdown(showDropdown);
  };

  return (
    <>
      <Helmet>
        <title>
          Zaanvar | Enhance Pet Care with Our Comprehensive Services
        </title>
        <meta
          name="description"
          content="Welcome to our homepage. Zaanvar is your trusted partner, combining innovation and compassion to simplify pet ownership."
        />
        <meta name="keywords" content="home, dynamic, pet, website" />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content="Zaanvar | Enhance Pet Care with Our Comprehensive Services"
        />
        <meta
          property="og:description"
          content="Welcome to our homepage. Zaanvar is your trusted partner, combining innovation and compassion to simplify pet ownership."
        />
        <meta property="og:url" content={window.location} />
        <meta
          property="og:image"
          content="https://cdn.builder.io/api/v1/image/assets/TEMP/36cfcad6b36251f6cfd91438fce91cf0e6ab5c0d9f5bedc73498f634519828cd?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Zaanvar" />
        <meta property="fb:app_id" content="536334085954411" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Zaanvar | Transforming Pet Care, One Paw at a Time"
        />
        <meta
          name="twitter:description"
          content="Revolutionizing pet care with services like a pet blood bank, lost & found, cremation, and matchmaking – your one-stop solution for every pet need!"
        />
        <meta
          name="twitter:image"
          content="https://cdn.builder.io/api/v1/image/assets/TEMP/36cfcad6b36251f6cfd91438fce91cf0e6ab5c0d9f5bedc73498f634519828cd?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <Hero />
      <Footer />
    </>
  );
};

export default Navbar;
