import React, { useState } from "react";
import "./ContactUs.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { WebApimanager } from "../../WebApiManager";
import { Helmet } from "react-helmet-async";
import location from "../../assets/landingPageIcons/glob.svg";
import love from "../../assets/landingPageIcons/love.svg";
import contact from "../../assets/landingPageIcons/contact.svg";
import linkedin from "../../assets/landingPageIcons/linkedin.svg";
import faceBook from "../../assets/landingPageIcons/facebook.svg";
import xmark from "../../assets/landingPageIcons/xMark.svg";
import insta from "../../assets/landingPageIcons/instagram.svg";
import contactBottom from "../../assets/landing-page-contact/contact.png"

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    message: "",
  });

  const webApi = new WebApimanager();

  const handleChange = ({ target: { name, value } }) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await webApi.post("contactUs/create", formData);
      if (response.status === 201) {
        toast.success("Your message has been sent successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          message: "",
        });
      } else {
        toast.error("Failed to send your message. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    }
  };

  

  return (
    <>
      <Helmet>
        <title>Zaanvar  ContactUs |Contact Us - Get in Touch</title>
        <meta
          name="description"
          content="Reach out to us for any inquiries or support."
        />
        <meta name="keywords" content="contact, support, inquiries" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content="Zaanvar  ContactUs |Contact Us - Get in Touch" />
        <meta
          property="og:description"
          content="Reach out to us for any inquiries or support."
        />
        <meta property="og:url" content={window.location} />
        <meta property="og:image" content="https://cdn.builder.io/api/v1/image/assets/TEMP/36cfcad6b36251f6cfd91438fce91cf0e6ab5c0d9f5bedc73498f634519828cd?apiKey=3e99c58a56f84e4cb0d84873c390b13e&" />
      </Helmet>
      <Header />
      <ToastContainer />
      <div style={{ marginTop: "76px" }}>
        <div className="imageTextContainerContact">
          <p className="contact-text">Contact Us</p>
        </div>

        <div>
          <div
            style={{
              paddingTop: "45px",
              paddingBottom: "45px",
              margin: "auto",
              width: "85%",
            }}
          >
            <span className="Reach-us-title"> Reach us</span>
            <div class="card-container-horizontal">
              <div class="card">
                <div class="card-icon">
                  <img src={location} alt="Location" />
                </div>
                <h3 class="card-title">Registered Address</h3>
                <p class="card-description">
                  306 3rd Floor, Plot 1/1721 Madhapur, <br />
                  Hyderabad, Telangana
                </p>
              </div>
              <div class="card">
                <div class="card-icon">
                  {" "}
                  <img src={contact} alt="Contact" />
                </div>
                <h3 class="card-title">Contact Information</h3>
                <p class="card-description">
                  +91 8801931789 <br /> support@zaanvar.com
                </p>
              </div>
              <div class="card">
                <div class="card-icon">
                  <img src={love} alt="Love" />
                </div>
                <h3 class="card-title">Engage With Us</h3>
                <div className="card-button-socialmedia-contact">
                <a href="https://www.linkedin.com/company/zaanvar/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="LinkedIn" />
                </a>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={xmark} alt="Twitter" />
                </a>
                <a href="https://www.instagram.com/zaanvar_?igsh=MTFnZDQxaDZndmh6bA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <img src={insta} alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61567070092046" target="_blank" rel="noopener noreferrer">
                  <img src={faceBook} alt="Facebook" />
                </a>
                </div>
              </div>
            </div>
            </div>
            </div>
      
            <div className="container-fluid-contact hero-section-contact">
  {/* Background Image */}
  <img
    className="background-image"
    src={contactBottom}
    alt="Contact"
    fetchPriority="high"
  />
  <div className="row justify-content-center align-items-center">
    {/* Form Container */}
    <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center">
      <div className="form-container-contact">
        <form onSubmit={handleSubmit}>
          <span className="GetinTouch-contact">Get in Touch</span>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                {/* <label htmlFor="firstName">First Name</label> */}
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                {/* <label htmlFor="lastName">Last Name</label> */}
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                {/* <label htmlFor="contactNumber">Contact Number</label> */}
                <input
                  type="tel"
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="Enter your contact number"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                {/* <label htmlFor="email">Email</label> */}
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            {/* <label htmlFor="message">Message</label> */}
            <textarea
              id="message"
              name="message"
              rows="3"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className=" submit-btn-contact">
          SUBMIT
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

       
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;