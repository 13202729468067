// import React from 'react'
// import { useParams } from 'react-router-dom';
// export const Sitemap = () => {

//   return (
//     <div>Sitemap</div>
//   )
// }

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import XMLViewer from "react-xml-viewer";
import { WebApimanager } from '../../WebApiManager';
import BlogDetail from '../Blog/BlogDetail/BlogDetail';
import Blog_Main from '../Blog/Blog_Main';
export const Sitemap = () => {
    const webApi = new WebApimanager();
  const [data, setData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const location = useLocation(); // This hook gives the current URL location

  // You can access the full URL using location.pathname
  const currentURL = location.pathname;
  const url= currentURL.substring(currentURL.lastIndexOf("/") + 1)
console.log(currentURL,"checking sitemapType")
console.log(url,"checking sitemapType url")
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await webApi.get(
  //           url === "sitemap_index.xml" ? "sitemap" : `sitemap/${url}`
  //         );
  //         let sitemapData = response?.data || "";
  //       setData(sitemapData); 
  //     } catch (err) {
  //       setError(err.message); 
  //     } finally {
  //       setLoading(false); 
  //     }
  //   };

  //   fetchData(); 

  // }, [url]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = "";
  
        // Determine the endpoint based on the URL content
        if (url.includes(".xml")) {
          endpoint  = `${url}`;
          // endpoint = url === "sitemap_index.xml" || "sitemap.xml" ? "sitemap" : `sitemap/${url}`;
          const response = await webApi.getwordpressData(endpoint);
          let sitemapData = response?.data || "";
  
          setData(sitemapData); 
        } else if (url.includes("category")) {
          // Handle URL with "category"
          endpoint = `sitemap/category${url}`;
          const response = await webApi.get(endpoint);
          let categoryData = response?.data.data[0] || "";
  
          setData(categoryData); 
          console.log("Category Data:", categoryData);
        } else if (url.includes("tag")) {
          // Handle URL with "tag"
          endpoint = `sitemap/tag${url}`;
          const response = await webApi.get(endpoint);
          let tagData = response?.data.data || "";
  
          setData(tagData); 
          console.log("Tag Data:", tagData);
        } else {
          // Default to posts
          endpoint = `sitemap/posts${currentURL}`;
          const response = await webApi.get(endpoint);
          let postData = response?.data.data[0] || "";
  
          setData(postData); 
          console.log("Post Data:", postData);
        }
      } catch (err) {
        console.error("Error Fetching Data:", err.message); // Log error
        setError(err.message); // Handle errors
      } finally {
        setLoading(false); // Set loading to false
      }
    };
  
    fetchData();
  }, [url]);
  
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* {currentURL.includes===".xml" ? */}
      
      <XMLViewer xml={data}/>
      {/* <pre>{data}</pre> */}
      {/* :
      <BlogDetail data={data} />
} */}

    </div>
  );
};


