import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "../../Header/Header";
import { BiArrowBack } from "react-icons/bi";
import whatsApp from "../../../assets/Blog_Icons/whatsapp.svg";
import linkedin from "../../../assets/Blog_Icons/linkedin.svg";
import facebook from "../../../assets/Blog_Icons/facebook.svg";
import Twitter from "../../../assets/Blog_Icons/twitter.svg";
import { WebApimanager } from "../../../WebApiManager";
import { Helmet } from "react-helmet";
import Footer from "../../Footer/Footer";
const BlogDetail = (data) => {
  const webApi = new WebApimanager();
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [localTime, setLocalTime] = useState("");
  const [date, setDate] = useState("");
  const [metaData, setMetaData] = useState({});
  const currentURL = location.pathname;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await webApi.getwordpressData(
          `sitemap/posts${currentURL}`
        );
        const postData = response.data.data;
        console.log(postData, "checking data");
        setPost(postData);
        setDate(postData.date);
        console.log(postData.yoast_head_json, "checking json data");
        console.log(post?.metaContent, "checking meta content data");

        // console.log(yoastData.image,"checking the yosat image")
        const yoastData = postData.yoast_head_json;
        // const title = yoastData.title || "Default Title";
        // const description = yoastData.description || "Default Description";
        // const imageUrl = yoastData.image || "default-image-url.jpg";
        // const url = window.location;
        console.log("yoastData.title", yoastData.title);
        console.log(yoastData.og_description, "yoastData.og_description");
        const title = yoastData.title || "Default Title";
        const description = yoastData.og_description || "Default Description";
        const imageUrl =
          (yoastData.og_image && yoastData.og_image[0]?.url) ||
          "default-image-url.jpg";
        const url = window.location.href;
        console.log(yoastData.image, "checking the yosat image");
        setMetaData({
          title,
          description,
          imageUrl,
          url,
        });
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };
    console.log(post?.metaContent, "checking meta content data");
    const fetchRecentPosts = async () => {
      try {
        const response = await webApi.getwordpressData("sitemap/postsperpage");
        const postsData = response.data || [];

        // Fetch featured images for recent posts
        const postsWithImages = await Promise.all(
          postsData.map(async (post) => {
            if (post.featured_media) {
              const mediaResponse = await webApi.getwordpressData(
                `sitemap/featuredmedia/${post.featured_media}`
              );
              return {
                ...post,
                featuredImage: mediaResponse.data.data.source_url,
              };
            }
            return post;
          })
        );

        setRecentPosts(postsWithImages);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    fetchPost();
    fetchRecentPosts();
  }, [slug]);
  useEffect(() => {
    if (data) {
      setPost(data);
    }
  }, [data]);
  const handleBack = () => {
    navigate(-1);
  };

  const applyGlobalStyles = (body) => `
  <div>
    <style>
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        background: #F6F9FE;
        width: "80%"
      }
      h1, h2, h3, h4, h5, h6 {
        margin: 20px 0;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.20000000298023224px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
      p {
        margin: 10px 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.20000000298023224px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #727271;
      }
      a {
        color: blue;
        text-decoration: underline;
      }
      ul, ol {
        margin: 10px 0;
        padding-left: 20px;
      }
      img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
      .wp-block-embed__wrapper {
        display: flex;
        justify-content: center
      }
      iframe {
        margin: 0 auto !important;
      }
      blockquote {
        margin: 20px 0;
        padding: 10px 20px;
        background-color: #f9f9f9;
        border-left: 5px solid #ccc;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
      }
      table th, table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      code {
        font-family: monospace;
        background-color: #f4f4f4;
        padding: 2px 4px;
        border-radius: 4px;
      }
      strong {
      font-family: Poppins, sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgb(0, 0, 0);
    }
    </style>
    ${body}
  </div>
`;
  const filteredPosts = recentPosts.filter((post) =>
    post.title.rendered.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleIconClick = (platform) => {
    const fixedDescription =
      "Read this article about pets, click on the below link";
    const { title, url } = metaData;
    const message = `${fixedDescription}\n${title}\n ${url}`;
    let shareUrl = "";
    switch (platform) {
      case "whatsapp":
        // WhatsApp will send the message with the fixed description and URL
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          message
        )}`;
        break;
      case "linkedin":
        // LinkedIn will share the post URL
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          message
        )}`;
        break;
      case "twitter":
        // Twitter will share the fixed message with the post URL
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          message
        )}`;
        break;
      case "facebook":
        // Facebook will share the post URL
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          message
        )}`;
        break;
      default:
        break;
    }
    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };
  useEffect(() => {
    if (date) {
      const updateLocalTime = () => {
        // Parse UTC time string into a Date object
        const utcDate = new Date(`${date}`); // Ensure UTC format by appending 'Z' if not already present

        // Get user's timezone
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(userTimezone, "Checking Timezone");

        // Format time in the user's timezone
        const timeFormatter = new Intl.DateTimeFormat("en-US", {
          timeZone: userTimezone,
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          // second: 'numeric',
          hour12: true, // Set to false for 24-hour format
        });

        const formattedTime = timeFormatter.format(utcDate);
        setLocalTime(formattedTime);
      };

      updateLocalTime();
    }
  }, [date]);
  
  useEffect(() => {
    const processInstagramEmbeds = () => {
      if (window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process();
      }
    };

    const processTwitterEmbeds = () => {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    };

    const loadInstagramScript = () => {
      if (!document.querySelector('script[src="https://www.instagram.com/embed.js"]')) {
        const script = document.createElement("script");
        script.src = "https://www.instagram.com/embed.js";
        script.async = true;
        script.onload = processInstagramEmbeds;
        document.body.appendChild(script);
      } else {
        processInstagramEmbeds();
      }
    };

    const loadTwitterScript = () => {
      if (!document.querySelector('script[src="https://platform.twitter.com/widgets.js"]')) {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        script.charset = "utf-8";
        script.onload = processTwitterEmbeds;
        document.body.appendChild(script);
      } else {
        processTwitterEmbeds();
      }
    };

    loadInstagramScript();
    loadTwitterScript();

    return () => {
      // Check if scripts exist before trying to remove them
      const instagramScript = document.querySelector(
        'script[src="https://www.instagram.com/embed.js"]'
      );
      const twitterScript = document.querySelector(
        'script[src="https://platform.twitter.com/widgets.js"]'
      );

      if (instagramScript && instagramScript.parentNode) {
        instagramScript.parentNode.removeChild(instagramScript);
      }

      if (twitterScript && twitterScript.parentNode) {
        twitterScript.parentNode.removeChild(twitterScript);
      }
    };
  }, [post]);
  

  if (!post) return <div>Loading...</div>;

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={metaData.description} />
        <meta
          name="keywords"
          content="pet blog, pet news, dogs, cats, pet stories"
        />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.imageUrl} />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.imageUrl} />
        <link rel="canonical" href={metaData.url} />
        <title>{metaData.title}</title>
      </Helmet>
      <Header />
      <div style={{ marginTop: "70px" }}>
        <div className="blog-particular-container">
          <div className="blog-particular-left-content">
            <div className="blog-particular-header-top">
              {/* <div className="blog-particular-header-backBtn">
                <BiArrowBack onClick={handleBack} />
              </div> */}
              <div>
                <div>
                  <span
                    className="blog-particular-title"
                    dangerouslySetInnerHTML={{ __html: post?.title }}
                  />
                </div>
              </div>
              <span className="blog-particular-title-date">{localTime}</span>
            </div>

            <hr className="blog-particular-custom-hr" />

            <div className="blog-particular-share-post">
              <span className="blog-particular-share-post-title">
                Share this post
              </span>
              <div className="blog-particular-share-post-button">
                <button onClick={() => handleIconClick("whatsapp")}>
                  <img src={whatsApp} alt="WhatsApp" />
                </button>
                <button onClick={() => handleIconClick("linkedin")}>
                  <img src={linkedin} alt="LinkedIn" />
                </button>
                <button onClick={() => handleIconClick("twitter")}>
                  <img src={Twitter} alt="Twitter" />
                </button>
                <button onClick={() => handleIconClick("facebook")}>
                  <img src={facebook} alt="Facebook" />
                </button>
              </div>
            </div>

            <img
              src={post.featuredImage}
              alt="Featured"
              fetchPriority="high"
              style={{ width: "100%", height: "500px", marginBottom: "20px" }}
            />

            <div
              dangerouslySetInnerHTML={{
                __html: post.content
                  ? applyGlobalStyles(post.content)
                  : "<p>No content available</p>",
              }}
            />
          </div>

          {/* <div className="blog-particular-right-content">
            <div className="blog-search-container">
              <input
                type="text"
                placeholder="Search..."
                className="blog-particular-search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <i
                className="fa fa-search blog-search-icon"
                style={{ marginTop: "10px" }}
              ></i>{" "}
            </div>

            <p className="recent-posts">Recent Posts</p>
            {filteredPosts.map((recentPost) => (
              <div
                key={recentPost.id}
                className="recent-post-item"
                onClick={() => navigate(/blog/${recentPost.slug})}
              >
                {recentPost.featuredImage && (
                  <img
                    src={recentPost.featuredImage}
                    alt={recentPost.title.rendered}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                )}
                <span
                  className="recent-post-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      recentPost.title.rendered
                        .split(" ")
                        .slice(0, 10)
                        .join(" ") +
                      (recentPost.title.rendered.split(" ").length > 10
                        ? "..."
                        : ""),
                  }}
                />
              </div>
            ))}
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetail;
