import React, { useRef, useState, useEffect } from "react";
import styles from "./EditPetPage.module.css";
import { BackIcon } from "../../../assets/SVG";
import { useNavigate } from "react-router";
import DropDownv1 from "../../UI/DropDownV1";
import CustomInputElement from "../../UI/CustomInputElement";
import CustomTextareaComponent from "../../UI/CustomTextAreaComponent";
import CustomFileInput from "../../UI/CustomFileInput";
import { useLocation } from "react-router-dom";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { WebApimanager } from "../../../WebApiManager";
import { EditIconPen, HideIcon, ViewIcon } from "../../../assets/SVG";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../Footer/Footer";

const EditPetPage = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const location = useLocation();
  const { petData } = location.state || {};
  const webApi = new WebApimanager();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [selectedAnswer4, setSelectedAnswer4] = useState("");
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [studFee, setStudFee] = useState("");

  useEffect(() => {
    if (petData) {
      setSelectedAnswer4(petData.petName || "");
      setAge(petData.petAge || "");
      setWeight(petData.weight || "");
      setStudFee(petData.studFee || "");
      setVictoriaDetails(petData.aboutYourPet || "");
      setFileName(petData.pedigreeCertificate || "");
      setFileNameDNATested(petData.DNACertificate || "");
      setFileNameVaccinated(petData.vaccineCertificate || "");
    }
  }, [petData]);

  const [fileName, setFileName] = useState("");
  const [fileValue, setFileValue] = useState(null);
  const [fileValue2, setFileValue2] = useState(null);
  const [fileValue3, setFileValue3] = useState(null);
  const [fileNameDNATested, setFileNameDNATested] = useState(""); 
  const [fileNameVaccinated, setFileNameVaccinated] = useState("");
  const [selectedPetType, setSelectedPetType] = useState(petData.petType || "");
  const [breedOptions, setBreedOptions] = useState([]);
  const [selectedBreed, setSelectedBreed] = useState(petData.breed || "");
  const [selectedpetGender, setSelectedpetGender] = useState(petData.petGender);
  const [selectedsize, setSelectedsize] = useState(petData.size);
  const [selectedpetNeed, setSelectedpetNeed] = useState(petData.petNeed);
  const [selectedImage, setSelectedImage] = useState(null); 
const[pofileimg,setpofileimg]=useState(null)
console.log(pofileimg,"aaa")
const handleImageChange = (e) => {
  const file = e.target.files[0];
  
  setpofileimg(file)
  if (file) {
    setSelectedImage(URL.createObjectURL(file));

      }
};
  
  const [selectedPetGoodWithCats, setSelectedPetGoodWithCats] = useState(
    petData.isYourPetGoodWithCats
  );
  const [selectedPetGoodWithKids, setSelectedPetGoodWithKids] = useState(
    petData.isYourPetGoodWithKids
  ); 
  const [selectedwillingToTravel, setSelectedwillingToTravel] = useState(
    petData.willingToTravel
  );
  const [selectedPetGoodWithDogs, setSelectedPetGoodWithDogs] = useState(
    petData.isYourPetGoodWithDogs
  );
  const [selectedpedigreeCertified, setSelectedpedigreeCertified] = useState(
    petData.pedigreeCertified
  ); 

  const [selectedDNATested, setSelectedDNATested] = useState(petData.DNATested);

  const [selectedvaccinated, setSelectedvaccinated] = useState(
    petData.vaccinated
  );
  const [isLoading, setIsLoading] = useState(false);



  const [showModal, setShowModal] = useState(false);

  const handlepedigreeCertificate = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileValue(file);
    }
  };

  const handleDNATested = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileNameDNATested(file.name);
      setFileValue2(file);
    }
  };
  const handleVaccinated = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileNameVaccinated(file.name);
      setFileValue3(file);
    }
  };
  const handleRadioChange4 = (event) => {
    setSelectedAnswer4(event.target.value);
  };

  const agee = (event) => {
    setAge(event.target.value);
  };
  const weightt = (event) => {
    setWeight(event.target.value);
  };



  const handleStudFeeChange = (event) => {
    const value = event.target.value;

    if (isNaN(value) && value !== "") {
      setError("Please enter a valid number");
      setShowModal(true);
    } else {
      setError("");
      setShowModal(false);
      setStudFee(value);
    }
  };
  const [victoriaDetails, setVictoriaDetails] = useState("");
  const handleVictoriaInfoChange = (inputValue) => {
    setVictoriaDetails(inputValue);
  };


  const getDogTypes = async () => {
    try {
      const response = await webApi.get("dogAndCat/dogTypes");
      if (response.status === 200) {
        const breedNames = response.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.error("Error fetching dog types:", error.message);
    }
  };

  const getCatTypes = async () => {
    try {
      const response = await webApi.get("dogAndCat/catTypes");
      if (response.status === 200) {
        const breedNames = response.data.data.map((breed) => breed.name);
        setBreedOptions(breedNames);
      }
    } catch (error) {
      console.error("Error fetching cat types:", error.message);
    }
  };

  useEffect(() => {
    if (selectedPetType === "Dog") {
      getDogTypes();
    } else if (selectedPetType === "Cat") {
      getCatTypes();
    } else {
      setBreedOptions([]);
    }
  }, [selectedPetType]);

  const handleImagePreview = () => {
    if (fileName) {
      const fullUrl = `https://dev-api.zaanvar.com/api/${fileName}`;
      window.open(fullUrl, "_blank");
    }
  };
  const handleImagePreview2 = () => {
    if (fileNameDNATested) {
      const fullUrl = `https://dev-api.zaanvar.com/api/${fileNameDNATested}`;
      window.open(fullUrl, "_blank");
    } else {
      alert("No file selected!");
    }
  };
  const handleImagePreview3 = () => {
    if (fileNameVaccinated) {
      const fullUrl = `https://dev-api.zaanvar.com/api/${fileNameVaccinated}`;
      window.open(fullUrl, "_blank");
    } else {
      alert("No file selected!");
    }
  };

  

  const handleDropDownChange = (value) => {
    setSelectedpetGender(value);
  };


  const handleDropDownsize = (value) => {
    setSelectedsize(value);
  };

  const handleDropDownpetNeed = (value) => {
    setSelectedpetNeed(value);
  };
  const [selectedPetTrained, setSelectedPetTrained] = useState(
    petData.isYourPetTrained
  );

  const handleDropDownPetTrained = (value) => {
    setSelectedPetTrained(value);
  };

 
  const handleDropDownPetGoodWithKids = (value) => {
    setSelectedPetGoodWithKids(value);
  };
  

  const handleDropDownPetGoodWithCats = (value) => {
    setSelectedPetGoodWithCats(value);
  };

  const handleDropDownPetWithDogs = (value) => {
    setSelectedPetGoodWithDogs(value);
  };


  const handleDropDownwillingToTravel = (value) => {
    setSelectedwillingToTravel(value);
  };
 
  const handleDropDownpedigreeCertified = (value) => {
    setSelectedpedigreeCertified(value);
  };

  const handleDropDownDNATested = (value) => {
    setSelectedDNATested(value);
  };
  
  const handleDropvaccinated = (value) => {
    setSelectedvaccinated(value);
  };


  
  const handlesubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
      const payload = {
      petType: selectedPetType,
      petName: selectedAnswer4,
      breed: selectedBreed,
      petGender: selectedpetGender,
      petAge: age,
      size: selectedsize,
      weight: weight,
      petNeed: selectedpetNeed,
      isYourPetTrained: selectedPetTrained,
      isYourPetGoodWithKids: selectedPetGoodWithKids,
      isYourPetGoodWithCats: selectedPetGoodWithCats,
      isYourPetGoodWithDogs: selectedPetGoodWithDogs,
      studFee: studFee,
      willingToTravel: selectedwillingToTravel,
      aboutYourPet: victoriaDetails,
      pedigreeCertified: selectedpedigreeCertified,
      DNATested: selectedDNATested,
      vaccinated: selectedvaccinated,
    };
    // pofileimg
    // const formData = new FormData();
    // formData.append('petImage', pofileimg);
  
    setApiProcessing({ loader: true, message: "Submitting..." });
  
    try {
      const response = await webApi.put(`petProfile/update/${petData._id}`, payload);
  
      if (response.status === 200) {
        const files = [
          { file: fileValue, fieldName: "pedigreeCertificate", endpoint: "updatePedigreeCertificate" },
          { file: fileValue2, fieldName: "DNACertificate", endpoint: "updateDNACertificate" },
          { file: fileValue3, fieldName: "vaccineCertificate", endpoint: "updatevaccineCertificate" },
          { file: pofileimg, fieldName: "petImage", endpoint: "updatePetImage" },

        ];
          const fileUploads = files.map(async ({ file, fieldName, endpoint }) => {
          if (file) {
            const formData = new FormData();
            formData.append(fieldName, file); 
  
            try {
              await webApi.imagePut(
                `petProfile/${endpoint}/${petData._id}`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
              );
            } catch (err) {
              console.error(`Failed to upload ${fieldName}:`, err);
              toast.error(`Failed to upload ${fieldName}. Please try again.`);
            }
          }
        });
          await Promise.all(fileUploads);
          toast.success("Profile updated successfully!");
       
          navigate("/tinder/home")
            window.location.reload();
      } else {
        toast.error("Failed to update profile. Please try again.");
      }
    } catch (err) {
      console.error("Error occurred during submission:", err);
      setError("Something went wrong. Please try again later.");
      toast.error("Submission failed.");
    } finally {
      setApiProcessing({ loader: false, message: "" });
      setIsLoading(false);
    }
  };
  const handleEditClick = () => {
    setFileName(null);
  };

  const handleEditClick2 = () => {
    setFileNameDNATested(null);
  };
  const handleEditClick3 = () => {
    setFileNameVaccinated(null);
  };

  const handleOpenFileDialog = () => {
    setFileNameDNATested();   
  };

  return (
    <>
      <ToastContainer />
      <section className={styles.TinderHomePage}>
        <div className={styles.matchesHeaderDiv}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div  onClick={() => navigate("/tinder/home")}  style={{ cursor: "pointer" }} >
              <BackIcon />
            </div>
            <p style={{ fontSize: "20px", fontWeight: 600, margin: "0px",  marginLeft: "10px",  }} >
              Edit Your Pet
            </p>
          </div>
        </div>
        <div className={styles.mainDiv}>
          <div className={styles.editDiv}>
            <div className={styles.profilePicDiv}>
              <div style={{ position: "relative" }}>
                <img        src={selectedImage || (petData.petImage ? `${BACKEND_URL}${petData.petImage}` : "https://img.freepik.com/premium-vector/no-dog-allowed-sign_36244-493.jpg?semt=ais_hybrid")}

                     />
                <div className={styles.plusIcon} onClick={() => fileInputRef.current.click()}  > 
                  +
                </div>
                <input type="file"  ref={fileInputRef} style={{ display: "none" }} onChange={handleImageChange}  />
              </div>
            </div>
            <div className={styles.formDiv}>
              <DropDownv1 question="Type of Pet" options={[petData.petType || "Select Type", "Dog", "Cat"]}  placeholder="Select Type" value={selectedPetType}  onChange={(type) => setSelectedPetType(type)} style={{    marginTop: "14px"}}/>
              <div style={{ width: "47%" }}>
                <label  style={{color: "#61677F", fontFamily: "Poppins", fontSize: "20px", fontWeight: "500", marginTop: "0px", }} >
                  Name of Pet
                </label>
                <input type="text" width="48%" value={selectedAnswer4} onChange={handleRadioChange4}  className={styles.editInput} />
              </div>
              <DropDownv1  question="Breed Name" options={breedOptions} placeholder="Select Breed" value={selectedBreed} onChange={(breed) => setSelectedBreed(breed)}  customFlex="1 1 47%"  />

              <DropDownv1  question="Gender" options={[ petData.petGender || "Select Type", "Male", "Female", "Others", ]} onChange={handleDropDownChange} placeholder="Select Type"   customFlex="1 1 46%" />
              <div style={{ width: "48%" }}>
                <label style={{  color: "#61677F", fontFamily: "Poppins", fontSize: "20px", fontWeight: "500", marginTop: "0px", }} >
                  Age
                </label>
                <input type="text" width="48%" value={age} onChange={agee}  className={styles.editInput}></input>
              </div>

              <DropDownv1 question="Size" options={[petData.size || "Select Type","Small","Medium","Large","Extra Large", ]}placeholder="Select Type"onChange={handleDropDownsize}/>
              <div style={{ width: "48%" }}>
                <label style={{  color: "#61677F",  fontFamily: "Poppins",  fontSize: "20px",  fontWeight: "500",  marginTop: "0px",}} >
                  Weight
                </label>
                <input type="text"width="48%"value={weight}onChange={weightt}style={{  marginTop: "14px",  backgroundColor: "rgba(243, 243, 243, 0.5)",  borderColor: "rgb(217, 217, 217)",}}></input>
              </div>
              <DropDownv1 question="Your Pet Need"options={[petData.petNeed || "Select Type", "Friend", "Lover"]}placeholder="Select Type"onChange={handleDropDownpetNeed}/>
              <DropDownv1 question="Is Your Pet Trained?"options={[  petData.isYourPetTrained || "Select Type",  "Yes",  "No",]}placeholder="Select Type"onChange={handleDropDownPetTrained}  customFlex="1 1 46%" />
              <DropDownv1 question="Is Your Pet Good with Kids? "options={[  petData.isYourPetGoodWithKids || "Select Type",  "Yes",  "No",]}placeholder="Select Type"onChange={handleDropDownPetGoodWithKids}/>
              <DropDownv1 question="Is your pet good with Cats?"options={[  petData.isYourPetGoodWithCats || "Select Type",  "Yes",  "No",]}placeholder="Select Type"onChange={handleDropDownPetGoodWithCats}  customFlex="1 1 48%" />
              <DropDownv1 question="Is your pet good with Dogs?"options={[  petData.isYourPetGoodWithDogs || "Select Type",  "Yes",  "No",]}placeholder="Select Type"onChange={handleDropDownPetWithDogs}  customFlex="1 1 47%" />

              <div style={{ width: "48%" }}>
                <label style={{  color: "#61677F",  fontFamily: "Poppins",  fontSize: "20px",  fontWeight: "500",  marginTop: "0px",}}>
                  Stud Fee
                </label>
                <input type="text"width="48%"value={studFee}onChange={handleStudFeeChange} className={styles.editInput}/>
                {showModal && (
                  <div style={{}}>
                    <p style={{ color: "red" }}>{error}</p>
                  </div>
                )}
              </div>

              <DropDownv1 question="Willing to Travel"options={[  petData.willingToTravel || "Select Type",  "Yes",  "No",]}placeholder="Select Type"onChange={handleDropDownwillingToTravel}/>
              <CustomTextareaComponent question="About Your Pet" rows={3}value={victoriaDetails} onInputChange={handleVictoriaInfoChange} customStylesedit={{fontSize:"20px"  ,   color: "rgb(97, 103, 127)"}} fontsize={{fontSize:"18px"}}/>
              <DropDownv1 question="Pedigree Certified"options={[  petData.pedigreeCertified || "Select Type",  "Yes",  "No",]}placeholder="Select Type"onChange={handleDropDownpedigreeCertified}/>           
                <div style={{  width: "47%",  marginBottom: "0px",  marginTop: "21px",}}>
                  <div style={{ display: "flex" }}>
                       <div style={{width: "-webkit-fill-available",position: "relative",textAlign: "left",border: "1px solid #D9D9D9",background: "rgba(243, 243, 243, 0.50)",display: "flex",alignItems: "center", justifyContent: "space-between", paddingRight: "10px",marginTop:"20px" }}>
                              <input id="file-upload1" type="file" accept="image/*" style={{   display: "none", }}onChange={handlepedigreeCertificate}/>
                                 <div style={{ display: "flex", alignItems: "center", gap: "10px",width:"90%" }}>
                                   <label htmlFor="file-upload1" style={{display: "inline-block",padding: "17px 20px",backgroundColor: "#727271",color: "#FFFFFF",border: "1px solid #D9D9D9",cursor: "pointer",fontSize: "12px",}}>
                                      Choose File
                                    </label>
                                    <span id="file-name1" style={{fontSize: "14px",color: "#727271",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",maxWidth: "70%",}}>
                                          {fileName ? fileName : "No file chosen"}
                                    </span>
                                  </div>
                                         {fileName && (
                                   <div style={{ display: "flex", gap: "10px" }}>
                                       <div onClick={handleEditClick} style={{ cursor: "pointer" }}><EditIconPen /></div>
                                        <div onClick={handleImagePreview} style={{ cursor: "pointer" }}><ViewIcon /></div>
                                    </div>
                                )}
                           </div>
                  </div>
                </div>
              <DropDownv1 question="DNA Tested"options={[petData.DNATested || "Select Type", "Yes", "No"]}placeholder="Select Type"onChange={handleDropDownDNATested}/>

                <div style={{  width: "47%",  marginBottom: "0px",  marginTop: "21px",}}>
                  <div style={{ display: "flex" }}>
                                <div style={{width: "-webkit-fill-available",position: "relative",textAlign: "left",border: "1px solid #D9D9D9",background: "rgba(243, 243, 243, 0.50)",display: "flex",alignItems: "center", justifyContent: "space-between", paddingRight: "10px",marginTop:"20px"  }}>
                                   <input id="file-upload2" type="file" accept="image/*" style={{display: "none",}}onChange={handleDNATested}onClick={handleOpenFileDialog}/>
                                          <div style={{ display: "flex", alignItems: "center", gap: "10px" ,width:"90%"}}>
                                             <label htmlFor="file-upload2" style={{   display: "inline-block",   padding: "17px 20px",   backgroundColor: "#727271",    color: "#FFFFFF",   border: "1px solid #D9D9D9",   cursor: "pointer",   fontSize: "12px", }} >
                                                  Choose File
                                            </label>
                                                   <span id="file-name2" style={{fontSize: "14px",color: "#727271",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",maxWidth: "70%", }}>
                                                      {fileNameDNATested ? fileNameDNATested : "No file chosen"}
                                                   </span>
                                            </div>
                                                         {fileNameDNATested && (
                                                          <div style={{ display: "flex", gap: "10px" }}>
                                                            <div onClick={handleEditClick2} style={{ cursor: "pointer" }}><EditIconPen /></div>
                                                            <div onClick={handleImagePreview2} style={{ cursor: "pointer" }}><ViewIcon /></div></div>
                                                    )}
                                </div>
                       </div>
                </div>
              <DropDownv1 question="Vaccinated"options={[petData.vaccinated || "Select Type", "Yes", "No"]}placeholder="Select Type"onChange={handleDropvaccinated}/>
             
                <div style={{  width: "47%",  marginBottom: "0px",  marginTop: "21px",}}>
                  <div style={{ display: "flex" }}>
                                 <div style={{width: "-webkit-fill-available",position: "relative",textAlign: "left",border: "1px solid #D9D9D9",background: "rgba(243, 243, 243, 0.50)",display: "flex",alignItems: "center", justifyContent: "space-between", paddingRight: "10px",marginTop:"20px"    }}>
                                       <input id="file-upload"type="file"accept="image/*"style={{  display: "none",}}onChange={handleVaccinated}/>
                                                  <div style={{ display: "flex", alignItems: "center", gap: "10px",width:"90%" }}>
                                                                <label htmlFor="file-upload" style={{ display: "inline-block", padding: "17px 20px", backgroundColor: "#727271",  color: "#FFFFFF", border: "1px solid #D9D9D9", cursor: "pointer", fontSize: "12px",}}>
                                                                     Choose File
                                                                 </label>
                                                                      <span id="file-name" style={{fontSize: "14px",color: "#727271",whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",maxWidth: "70%",}}>
                                                                             {fileNameVaccinated ? fileNameVaccinated : "No file chosen"}
                                                                      </span>
                                                  </div>
                                                                 {fileNameVaccinated && (
                                                                  <div style={{ display: "flex", gap: "10px" }}>
                                                                       <div onClick={handleEditClick3} style={{ cursor: "pointer" }}><EditIconPen /></div>
                                                                       <div onClick={handleImagePreview3} style={{ cursor: "pointer" }}><ViewIcon /> </div>
                                                                 </div>
                                                            )}
                                                  </div>
                                       </div>
                                </div>
                        </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className={styles.submitButton} onClick={handlesubmit}disabled={isLoading} >
                        {isLoading ? "Loading..." : "Submit"} 
                    </button>
            </div>

          </div>
        </div>
      </section>
      <section>
        <Footer/>
      </section>
    </>
  );
};

export default EditPetPage;
