import React, { useEffect, useState } from "react";
import "../SocialMedia.css";
import SocialMediaPosts from "./SocialMediaPosts";
import SocialMediaQandA from "./SocialMediaQandA";
import SocialMediaPoll from "./SocialMediaPoll";
import { useRecoilState } from "recoil";
import { userInfo } from "../../../Atoms";
import { BACKEND_URL } from "../../../Utilities/Constant";
import { WebApimanager } from "../../../WebApiManager";
import search from "../../../assets/Social_Media_Icons/profileBtn.svg";
import Following from "./Followers_Following/Following";
import Followers from "./Followers_Following/Followers";

const SocialMediaSettings = () => {
  const webApi = new WebApimanager();
  const [images, setImages] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [isFollowingOpen, setIsFollowingOpen] = useState(false);
  const [isFollowersOpen, setIsFollowersOpen] = useState(false);

  const [activeTab, setActiveTab] = useState("post");

  const renderSocialMediaPages = () => {
    switch (activeTab) {
      case "post":
        return <SocialMediaPosts />;
      case "QandA":
        return <SocialMediaQandA />;
      case "poll":
        return <SocialMediaPoll />;
      default:
        return <SocialMediaPosts />;
    }
  };

  const [currentUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    console.log(currentUserInfo, "user info for profile social media posts");

    const imageresponse = async () => {
      try {
        const userId = currentUserInfo?._id;
        if (userId) {
          const response = await webApi.get(
            `socialMedia/postsByUserId/${userId}?type=Image`
          );

          setImages(response.data.posts);
          console.log(response.data.posts, "Extracted images for the ");
          console.log(images, "cdjsbhfvbfv");
        } else {
          console.log("User ID is not available");
        }
      } catch (error) {
        console.log(error);
      }
    };

    imageresponse();
  }, [currentUserInfo]);

  const fetchSocialMediaData = async () => {
    const userId = currentUserInfo?._id;

    try {
      if (userId) {
        const response = await webApi.get(
          `socialMedia/postsByUserId/${userId}`
        );
        setSocialMediaData(response.data.profile);
        console.log(
          "social media particulat use account",
          response.data.profile
        );
      } else {
      }
    } catch (error) {
      console.error("Error fetching social media data:", error);
    }
  };

  useEffect(() => {
    fetchSocialMediaData();
  }, []);

  const formatNumber = (num) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "K";
    }
    return num.toString();
  };

  const [isUploadingProfile, setIsUploadingProfile] = useState(false);

  const handleProfilePicUpload = async (event) => {
    const userId = currentUserInfo?._id;

    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("profile", file);

    setIsUploadingProfile(true);

    try {
      await webApi.imagePut(`users/updateProfile/${userId}`, formData);
      alert("Profile picture updated successfully!");
      fetchSocialMediaData();
    } catch (error) {
      console.error("Error updating profile picture:", error);
    } finally {
      setIsUploadingProfile(false);
    }
  };

  return (
    <>
      <div>
        <div className="socialmadia-profile">
          <div className="socialmedia-profile-left-side">
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={`${BACKEND_URL}${
                  socialMediaData.userId?.profile || "/default-profile.png"
                }`}
                alt="Profile"
                className="socialmedia-profile-image-home"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "2px solid #ccc",
                }}
              />

              <input
                type="file"
                id="profile-upload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleProfilePicUpload}
              />

              <img
                src={search}
                alt="search"
                onClick={() =>
                  document.getElementById("profile-upload").click()
                }
                style={{
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  bottom: "10px",
                  right: "0px",
                  cursor: "pointer",
                  borderRadius: "50%",
                }}
                title="Change Profile Picture"
              />

              {/* {isUploadingProfile && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <span>Uploading...</span>
                </div>
              )} */}
            </div>
            <div className="socialmedia-profile-text">
              <div className="socialmedia-profile-name">
                {images[0]?.userId?.name || "User Name"}
              </div>
              <div className="socialmedia-profile-description">
                Hello! I’m a proud pawrents of
              </div>
            </div>
          </div>

          <div className="socialmedia-profile-right-side">
            <div className="top-buttons">
              {/* <button className="socialmedia-button">Follow</button>
              <button className="socialmedia-button">Share</button> */}
            </div>
            <div className="bottom-buttons">
              <p className="socialmedia-account-details">
                {formatNumber(images.length)} Posts
              </p>
              <p
                className="socialmedia-account-details"
                onClick={() => setIsFollowersOpen(true)}
              >
                {formatNumber(socialMediaData.followers?.length || 0)} followers
              </p>
              <p
                className="socialmedia-account-details"
                onClick={() => setIsFollowingOpen(true)}
              >
                {formatNumber(socialMediaData.following?.length || 0)} following
              </p>
            </div>
          </div>
        </div>

        <div className="socialmedia-menu-container">
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "post" ? "active" : ""
            }`}
            onClick={() => setActiveTab("post")}
          >
            POSTS
          </div>
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "QandA" ? "active" : ""
            }`}
            onClick={() => setActiveTab("QandA")}
          >
            Q/A
          </div>
          <div
            className={`socialmedia-account-item-tabs ${
              activeTab === "poll" ? "active" : ""
            }`}
            onClick={() => setActiveTab("poll")}
          >
            POLL
          </div>
        </div>

        <div>{renderSocialMediaPages()}</div>

        <Following
          isOpen={isFollowingOpen}
          onClose={() => setIsFollowingOpen(false)}
          Followers={socialMediaData[0]?.socialMedia?.following || []}
        />
        <Followers
          isOpen={isFollowersOpen}
          onClose={() => setIsFollowersOpen(false)}
          followers={socialMediaData[0]?.socialMedia?.followers || []}
        />
      </div>
    </>
  );
};

export default SocialMediaSettings;
